import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { getEmailSendToLenderListUrl } from '../../utils/urls';
import { SEND_EMAIL_REQUEST } from './send-email-to-lender-constants';
import { sendEmailToLenderFailure, sendEmailToLenderSuccess } from './send-email-to-lender-action';

const { getRequest } = new HttpHelper();

export function* sendEmailToLender(action) {

  try {
    const response = yield call(getRequest, {
      url: getEmailSendToLenderListUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(sendEmailToLenderSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(sendEmailToLenderFailure());
  }
}

export function* watchsendEmailToLender() {
  yield takeEvery(SEND_EMAIL_REQUEST, sendEmailToLender);
}
