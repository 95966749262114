import {
  SELECT,
  TEXT,
  DATE_RANGE_TYPE,
} from '../../constants/data-table';

export const HEALTH_CARE_LOAN_REQUEST='HEALTH_CARE_LOAN_REQUEST';
export const HEALTH_CARE_LOAN_SUCCESS='HEALTH_CARE_LOAN_SUCCESS';
export const HEALTH_CARE_LOAN_FAILURE='HEALTH_CARE_LOAN_FAILURE';

export const healthCareLoanFilters = [
  {
    name: 'Health Loan Number',
    value: 'loanNumber',
    filterCondition: 'eq',
    placeholder: 'ML-001230',
    type: TEXT,
  },
  {
    name: 'partner Loan Id',
    value: 'partnerLoanId',
    filterCondition: 'eq',
    placeholder: '',
    type: TEXT,
  },
  {
    name: 'partner User Id',
    value: 'partnerUserId',
    filterCondition: 'eq',
    placeholder: '',
    type: TEXT,
  },
  {
    name: 'email',
    value: 'email',
    filterCondition: 'eq',
    placeholder: '',
    type: TEXT,
  },
  {
    name: 'mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '',
    type: TEXT,
  },
  {
    name: 'policy Number',
    value: 'policyNumber',
    filterCondition: 'eq',
    placeholder: '',
    type: TEXT,
  },
  {
    name: 'Status',
    value: 'status',
    placeholder: 'active',
    filterCondition: 'eq',
    type: SELECT,
    options: [
      { name: 'active', value: 'active' },
      { name: 'Initiated', value: 'initiated' },
      { name: 'Initiated', value: 'lead-generated' },
      { name: 'Closed', value: 'closed' },
      { name: 'Cancelled', value: 'cancelled' }
    ],
  },
  {
    name: 'Loan Date',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  }
];
