import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { addPatientLoanUrl } from '../../utils/urls';
import { ADD_PATIENT_LOAN_REQUEST } from './nucleon-profile-constants';
import { addPatientLoanFailure, addPatientLoanSuccess } from './add-patient-loan-action'

const { postRequest } = new HttpHelper();

export function* addpatientLoan(action) {
  try {
    const response = yield call(postRequest, {
      url: addPatientLoanUrl(action.data.id),
      data: action.data.data,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');

      yield put(addPatientLoanFailure());

      action.addloanSuccessCallback();
    } else {
      yield put(addPatientLoanSuccess());

      Toastr.success('Loan Has Been Generated', 'Success');

      window.location.pathname = `/policy-holder/${action.data.id}`;
    }

  } catch (err) {
    throw new Error(err);
  }
}

export function* watchaddpatientLoan() {
  yield takeEvery(ADD_PATIENT_LOAN_REQUEST, addpatientLoan);
}
