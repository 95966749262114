import {
  SENT_UTRS_REQUEST,
  SENT_UTRS_SUCCESS,
  SENT_UTRS_FAILURE,
} from './sent-utrs-constants';

const sentUtrsRequest = (urlParams) => ({
  type: SENT_UTRS_REQUEST,
  urlParams,
});

export const sentUtrsSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: SENT_UTRS_SUCCESS,
});
export const sentUtrsFailure = () => ({
  type: SENT_UTRS_FAILURE,
});

export const sentUtrs = async (urlParams, dispatch) => {
  dispatch(sentUtrsRequest(urlParams));
};
