import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { policyHolderDetailsUrl } from '../../utils/urls';
import { UPLOAD_DOCS_POLICY_HOLDER_REQUEST } from './nucleon-profile-constants';
import { uploadDocsPolicyHolderSuccess, uploadDocsPolicyHolderFailure } from './upload-docs-policy-holder-action';

const { patchRequest } = new HttpHelper();

export function* uploadDocsPolicyHolder({ id, concurrencyStamp, documentIds,callback }) {
  try {
    const url = policyHolderDetailsUrl(id);
    const headerParams = {
      'Content-Type': 'application/json',
      'x-coreplatform-concurrencystamp': concurrencyStamp,
    };

    yield call(patchRequest, {
      data: {
        ...documentIds,
      },
      headers: headerParams,
      url,
    });

    yield put(uploadDocsPolicyHolderSuccess());
    callback();
  } catch (error) {
    yield put(uploadDocsPolicyHolderFailure());
  }
}

export function* watchuploadDocsPolicyHolder() {
  yield takeEvery(UPLOAD_DOCS_POLICY_HOLDER_REQUEST, uploadDocsPolicyHolder);
}
