import { TEXT } from '../../../constants/field-value-key';

export const finbitSummaryDetails = [
  {
    label: "Loan Repayments",
    key: "loanRepayments",
    type: TEXT,
  },
  {
    label: "Last 3 Months Average Balance",
    key: "totalAverageSalary",
    type: TEXT,
  },
  {
    label: "Number of Account Verification",
    key: "totalAccountVerification",
    type: TEXT,
  },
  {
    label: "Number of loan Credit",
    key: "totalLoans",
    type: TEXT,
  },
  {
    label: "Number of Auto Payment Bounce",
    key: "totalBounces",
    type: TEXT,
  },
]