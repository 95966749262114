import React, { useState, useEffect } from 'react';
import './user-basic-info.css';
import { UserSummaryDetails } from './user-basic-info-constants';
import FieldValue from '../../field-key-value';

const UserBasicInfo = ({ profileData }) => {

  const [defaultBankStatment, setDefaultBank] = useState({});

  useEffect(() => {
    setDefaultBank(profileData);
  }, [profileData]);

  return (
    <React.Fragment>
      <div className="personal-bank-details">
        <div className="personal-heading-container">
          <div className="d-flex align-items-baseline">
            <h4 className="personal-common-heading">
                            POLICY INFORMATION
            </h4>
          </div>
        </div>
        <div className="bank-statement-info-wrapper">
          <div className='row'>
            <div className='col-md-6' style={{ left: "-15px" }}>
              <div className="field-value-wrapper">
                <div className="field-name">name</div>
                <div className="field-value">{profileData && profileData.firstName} {profileData && profileData.lastName}</div>
              </div>
            </div>
          </div>
          {UserSummaryDetails.map((item, index) =>
            <FieldValue
              key={index}
              fieldName={item.label}
              fieldValue={defaultBankStatment && defaultBankStatment[item.key]}
              type={item.type}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserBasicInfo;