import {
  HEALTH_CARE_LOAN_INFO_FAILURE,
  HEALTH_CARE_LOAN_INFO_SUCCESS,
  HEALTH_CARE_LOAN_INFO_REQUEST,
} from './health-care-loan-info-constants';

const healthCareLoanInfoRequest = (id) => ({
  id,
  type: HEALTH_CARE_LOAN_INFO_REQUEST,
});

export const healthCareLoanInfoSuccess = (data) => ({
  data,
  type: HEALTH_CARE_LOAN_INFO_SUCCESS,
});

export const healthCareLoanInfoFailure = () => ({
  type: HEALTH_CARE_LOAN_INFO_FAILURE,
});

export const healthCareLoanInfo = async (id, dispatch) => {
  dispatch(healthCareLoanInfoRequest(id));
};
