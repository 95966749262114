import {
  TEXT,
  NUMBER,
  DATE_RANGE_TYPE,
} from '../../constants/data-table';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const SEND_GENERATED_LEADS_REQUEST = 'SEND_GENERATED_LEADS_REQUEST';
export const SEND_GENERATED_LEADS_SUCCESS = 'SEND_GENERATED_LEADS_SUCCESS';
export const SEND_GENERATED_LEADS_FAILURE = 'SEND_GENERATED_LEADS_FAILURE';

export const usersFilters = [
  {
    name: 'Number',
    value: 'number',
    filterCondition: 'eq',
    placeholder: 'Loan Number',
    type: TEXT
  },
  {
    name: 'partner Loan Id',
    value: 'partnerLoanId',
    filterCondition: 'eq',
    placeholder: '',
    type: NUMBER,
  },
  {
    name: 'partner User Id',
    value: 'partnerUserId',
    filterCondition: 'eq',
    placeholder: '',
    type: TEXT,
  },
  {
    name: 'Loan Created on',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  },
];
