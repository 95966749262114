/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Breadcrumb } from 'react-bootstrap';

import { UsersA } from './users-action';

import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import Footer from '../../components/footer';
import SidebarIcon from '../../components/sidebar-icon';
import { usersFilters } from './users-constants';

import { userExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { getCallLogsReports } from './get-daily-reports-action';

import phoneDisabled from '../../../images/icons/phoneDisabled.png';
import phoneEnabled from '../../../images/icons/phoneEnabled.png';
import phonePending from '../../../images/icons/phonePending.png';
import phoneSettings from '../../../images/icons/phoneSettings.png';

const Users = ({ location }) => {
  // Filter State
  const [show, setShow] = useState(false);

  const { isFetching, users, totalRecords } = useSelector((state) => state.users);
  const { data } = useSelector((state) => state.getCallLogs);

  const usersList =
    users &&
    users.map((user, index) => {
      const id = index + 1;

      return {
        ...user,
        id,
      };
    });

  const dispatch = useDispatch();

  const getUsersData = (urlParams) => {
    UsersA(urlParams, dispatch);
    if(urlParams['filters[0][key]'] === "createdAt" || urlParams['filters[0][key]'] === undefined){
      getCallLogsReports(urlParams, dispatch);
    }
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getUsersData(urlParams);
      }
      setDefaultFilter(usersFilters, value, filterOnValue);
    } else {
      getUsersData(urlParams);
    }
  }, []);

  const userNameHandle = (cell, row) => {
    return <Link to={`/users/${row.publicId}`}>{cell || '-'}</Link>;
  };

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.publicId}`}>{cell}</Link>;
  }

  function dateTimeFormatter(cell) {
    return <label>{cell?moment(cell).format('DD-MM-YYYY hh:mm:ss'):'-'}</label>;
  }
  // function valueFormatter(cell) {
  //   if (cell === 'true') {
  //     return <label className="label-status-paid">True</label>;
  //   }
  //   return <label className="status-unpaid">False</label>;
  // }

  function statusFormatter(cell) {
    if (cell === 'verified') {
      return <label className="label-status-paid">{cell}</label>;
    }
    if (cell === 'pending') {
      return <label className="label-partialpaid">{cell}</label>;
    }
    return <label className="status-unpaid">{cell || 'not-uploaded'}</label>;
  }

  function callStatusFormatter(cell) {
    if (cell === 'completed') {
      return <label className="label-status-paid">{cell}</label>;
    }
    else if (cell === 'pending') {
      return <label className="label-partialpaid">{cell}</label>;
    }
    else if (cell === 'updated') {
      return <label className='label-status-initiated'>{cell}</label>
    }
    return <label className="status-unpaid">{cell || 'NULL'}</label>;
  }

  function kycStatusFormatter(cell) {
    if (cell) {
      return <label className="label-status-paid">True</label>;
    }
    return <label className="status-unpaid">False</label>;
  }

  function financialStatusFormatter(cell) {
    if (cell) {
      return <label className="label-status-paid">True</label>;
    }
    return <label className="status-unpaid">False</label>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '30',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userNameHandle,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'employmentType',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Emp. Type',
      width: '70',
    },
    {
      columnClassName: '',
      dataField: 'crifScore',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Crif Score',
      width: '70',
    },
    {
      columnClassName: '',
      dataField: 'panDocumentStatus',
      dataFormat: statusFormatter,
      dataSort: false,
      isKey: false,
      name: 'Pan Status',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'bankStatementDocumentStatus',
      dataFormat: statusFormatter,
      dataSort: false,
      isKey: false,
      name: 'Bankstatement Status',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'callCategory',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'Drop Stage',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'callStatus',
      dataFormat: callStatusFormatter,
      dataSort: false,
      isKey: false,
      name: 'Call Status',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'createdAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Created At',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'isKycCompleted',
      dataFormat: kycStatusFormatter,
      dataSort: true,
      isKey: false,
      name: 'KYC',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'primaryEmail',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'User Email',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'allocatedTo',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Allocated TO',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'gender',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Gender',
      width: '70',
    },
    {
      columnClassName: '',
      dataField: 'isFinancialVerified',
      dataFormat: financialStatusFormatter,
      dataSort: true,
      isKey: false,
      name: 'Fin. Check',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'updatedAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Updated At',
      width: '120',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Users</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Users</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
            {data && data.map(({ status,count },index) => (
              <div className=''>
                {
                  index !== data.length-1 ? (
                    (status === "pending" || status === "rejected" || status === "completed" || status === "updated") &&
                    <div className='col-md-2' >
                      <div className="chip">
                        <div className="chip-body">
                          <h5 className="num">{count}</h5>
                          <h3 className="heading">Application Status {status}</h3>
                          {status === "pending" && <img src={phonePending} alt='placeholder'/>}
                          {status === "rejected" && <img src={phoneDisabled} alt='placeholder'/>}
                          {status === "completed" && <img src={phoneEnabled} alt='placeholder'/>}
                          {status === "updated" && <img src={phoneSettings} alt='placeholder'/>}
                        </div>
                      </div>
                    </div>)
                    :
                    <div className='col-md-2 mt-3' >
                      <div className="chip">
                        <div className="chip-body">
                          <h5 className="num">{count}</h5>
                          <h3 className="heading">Total Users</h3>
                          <p className='para'>No of Total Users in our system</p>
                        </div>
                      </div>
                    </div>}
              </div>
            ))
            }
          </div>
          <div className="loan-container mar0 mt-2">
            <DataTable
              tableData={usersList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getUsersData}
              exportUrl={userExportUrl}
              tableTitle="USER"
              pageFilters={usersFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
      </div>
    </div>
  );
};

export default Users;
