import {
  TEXT,
  DATE_RANGE_TYPE,
  NUMBER_RANGE_TYPE,
  NUMBER,
} from '../../constants/data-table';

export const DEFAULTS_REQUEST = 'DEFAULTS_REQUEST';
export const DEFAULTS_SUCCESS = 'DEFAULTS_SUCCESS';
export const DEFAULTS_FAILURE = 'DEFAULTS_FAILURE';

export const GET_DEFAULT_REPORTS_REQUEST='GET_DEFAULT_REPORTS_REQUEST';
export const GET_DEFAULT_REPORTS_SUCCESS='GET_DEFAULT_REPORTS_SUCCESS';
export const GET_DEFAULT_REPORTS_FAILURE='GET_DEFAULT_REPORTS_FAILURE';

export const defaultFilters = [
  {
    name: 'Loan Number',
    value: 'number',
    filterCondition: 'eq',
    placeholder: 'ML-001230',
    type: TEXT,
  },
  {
    name: 'Name',
    value: 'name',
    filterCondition: 'ilike',
    placeholder: 'Rahul Kumar',
    type: TEXT,
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '9876543263',
    type: NUMBER,
  },
  {
    name: 'EMI Number',
    value: 'emiNumber',
    filterCondition: 'eq',
    placeholder: '4',
    type: NUMBER,
  },
  {
    name: 'EMI Due Date',
    value: 'emiDueDate',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'expectedStartDate',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'expectedEndDate',
  },
  {
    name: 'Value',
    value: 'amount',
    filterCondition: 'eq',
    minPlaceholder: 'min value',
    maxPlaceholder: 'max value',
    minFilterCondition: 'gte',
    maxFilterCondition: 'lte',
    type: NUMBER_RANGE_TYPE,
  },
  {
    name: 'Loan Date',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  }
];
