import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { healthCareLenderList } from '../../../containers/neuclon-profile/partner-list-action';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import { getPartnerListOption } from '../../../containers/profile/common';
import { generateLoanLead } from '../../../containers/neuclon-profile/generate-loan-lead-action';
import TableLoader from '../../Loaders/table-loader';
import './generate-lead.css';
import FormElement from '../../form-elements/index';
import Button from '../../form-elements/button';

import {
  setInitialFormValues,
  formFieldValidator,
  validateForm,
  getFormDataValue,
} from '../../../utils/form-helper';
import { generateLeadConstants } from './generate-lead-constants';

const GenerateLoanLead = ({
  generateLead,
  setGenrateLoan,
  closeGenerateLeadPopup,
  policyHolderId,
  loanId,
}) => {
  const [addGenerateLeadForm, setAddGenerateLeadForm] = useState([]);
  const [partnerData, setPartnerData] = useState([]);

  const dispatch = useDispatch();

  const { success, isloading } = useSelector((state) => state.generateLoanLead);

  useEffect(() => {
    closeGenerateLeadPopup();
  },[success]);

  const updatePartnerList = (gethospitalList) => {
    setPartnerData(getPartnerListOption(gethospitalList));
  }

  useEffect(() => {
    healthCareLenderList(dispatch,updatePartnerList);
    setInitialFormValues(generateLeadConstants, setAddGenerateLeadForm);
  }, []);

  const onChangeHandler = (form, formField, formFieldValue, setForm) => {
    const newForm = JSON.parse(JSON.stringify(form));

    if (newForm && Array.isArray(newForm)) {
      newForm.map((formItemParam) => {
        const formItem = formItemParam

        if (formItem.name === formField) {
          formItem.value = formFieldValue;
          formItem.isTouched = true;
          const [isValid, result] = formFieldValidator(formItem.validation, formItem.value);

          formItem.isValid = isValid;
          formItem.errorMsg = result;
        }
        return false;
      });
      setForm(newForm);
    }
  }

  const handleGenrateLead = () => {
    const [addNewGenerateLeadForm, isValid] = validateForm(addGenerateLeadForm);

    setAddGenerateLeadForm(addNewGenerateLeadForm);
    if (isValid) {
      const newAddLoanObj = getFormDataValue(addGenerateLeadForm);

      generateLoanLead({ ...newAddLoanObj,policyHolderId,policyHolderLoanId: loanId },dispatch);
    }
    // closeGenerateLeadPopup();
  };

  return (
    <Modal
      show={generateLead}
      onHide={() => setGenrateLoan(false)}
      animation={false}
      className="delete-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setGenrateLoan(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        {isloading ? <TableLoader/>: <div className="editaddress-sec">
          <div className="editaddress-header">
            <h2>Generate Loan</h2>
          </div>
          <div className="add-partner-form-container">
            {addGenerateLeadForm.map((formItem, index) => {
              return (
                <div className={`add-partner-form-item`} key={index}>
                  <FormElement
                    inputType={formItem.type}
                    lableName={formItem.label}
                    name={formItem.name}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={formItem.name === 'partnerId' ? partnerData : formItem.options}
                    onChangeHandler={(targetValue) => onChangeHandler(addGenerateLeadForm, formItem.name, targetValue, setAddGenerateLeadForm) }
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={(formItem.isDisabled)}
                    isTouched={formItem.isTouched}
                  />
                </div>
              )
            })}
          </div>
          <div className="add-partner-button-wrapper">
            <Button click={ () => closeGenerateLeadPopup() } isGreyedOut={false} text={'Cancel'} />
            <Button click={handleGenrateLead} isGreyedOut={false} text={'SUBMIT'} isMarginLeft={true} />
          </div>
        </div>}
      </Modal.Body>
    </Modal>
  );
};

export default GenerateLoanLead;
