import {
    USER_SIM_DETAILS_REQUEST,
    USER_SIM_DETAILS_SUCCESS,
    USER_SIM_DETAILS_FAILURE,
} from './profile-constants';


const initialState = {
  error: false,
  isFetching: false,
  userSimData: [],
};

const getUserSimDetails = (state = initialState, action) => { 
  switch (action.type) {
    case USER_SIM_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
        userSimData:[]
      };
    case USER_SIM_DETAILS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        userSimData:[]
      };
    case USER_SIM_DETAILS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        userSimData: action.data,
      };
    default:
      return state;
  }
};

export default getUserSimDetails;
