import {
  GENERATE_LOAN_LEAD_FALIURE,
  GENERATE_LOAN_LEAD_SUCCESS,
  GENERATE_LOAN_LEAD_REQUEST,
} from './nucleon-profile-constants';

const initialState = {
  error: false,
  success: false,
  isloading: false,
};

const generateLoanLead = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_LOAN_LEAD_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case GENERATE_LOAN_LEAD_FALIURE:
      return {
        ...state,
        error: true,
        isloading: false,
      };
    case GENERATE_LOAN_LEAD_SUCCESS:
      return {
        error: false,
        isFetching: false,
        success: true,
      };
    default:
      return state;
  }
};

export default generateLoanLead;