import { NUMBER } from '../../../constants/form-type';

export const crifScoreForm = [
  {
    label: 'Crif Score',
    name: 'crifScore',
    placeholder: '601',
    validation: { isRequired: true, minLength: 3, maxLength: 3, minRange: 500, maxRange: 900 },
    isDisabled: false,
    type: NUMBER,
  },
]