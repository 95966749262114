import React from 'react';

import { Form } from 'react-bootstrap';
import './edit-utr.css';

const EditUtr = ({
  closeEditUserPopup,
  editUserDetails,
  setEditUserDetails,
  handleUpdateUser,
}) => {

  return (
    <div className="editpopup-sec">
      <div className="editpopup-header">
        <h2>Update UTR for {editUserDetails.partnerLoanId}</h2>
      </div>
      <div className='row'>
        <div className='col-md-6' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name">Account Holder Name</div>
            <div className="field-value">{editUserDetails.accountHolderName}</div>
          </div>
        </div>
        <div className='col-md-6' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name">Account Number</div>
            <div className="field-value">{editUserDetails.accountNumber}</div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name">Facility Type</div>
            <div className="field-value">{editUserDetails.facilityType}</div>
          </div>
        </div>
        <div className='col-md-6' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name">Amount</div>
            <div className="field-value">{editUserDetails.amount}</div>
          </div>
        </div>
      </div>
      <div className="editpopup-form-sec mt-4">
        <Form>
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">Partner Loan Id</label>
            <Form.Control
              type="text"
              placeholder="Enter Loan Id"
              name="partnerLoanId"
              value={editUserDetails.partnerLoanId}
              onChange={(e) =>
                setEditUserDetails({
                  ...editUserDetails,
                  partnerLoanId: e.target.value,
                })
              }
            />
          </div>
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">Partner User Id</label>
            <Form.Control
              type="text"
              placeholder="Enter User Id"
              name="partnerUserId"
              value={editUserDetails.partnerUserId}
              onChange={(e) =>
                setEditUserDetails({
                  ...editUserDetails,
                  partnerUserId: e.target.value,
                })
              }
            />
          </div>
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">UTR</label>
            <Form.Control
              type="text"
              placeholder="Enter UTR Details"
              name="utr"
              value={editUserDetails.utr}
              onChange={(e) =>
                setEditUserDetails({
                  ...editUserDetails,
                  utr: e.target.value,
                })
              }
            />
          </div>
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">Disbursement Date</label>
            <Form.Control
              type="date"
              placeholder="Enter"
              name="disbursementDate"
              value={editUserDetails.disbursementDate}
              onChange={(e) =>
                setEditUserDetails({
                  ...editUserDetails,
                  disbursementDate: e.target.value,
                })
              }
            />
          </div>
        </Form>
      </div>
      <div className="editpopup-bottom">
        <button className="cancel-btn" onClick={closeEditUserPopup}>
          Cancel
        </button>
        <button className="black-border-btn" onClick={handleUpdateUser}>
          OK
        </button>
      </div>
    </div>
  );
};

export default EditUtr;
