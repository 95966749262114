import {
  DAILY_REPORT_FAILURE,
  DAILY_REPORT_SUCCESS,
  DAILY_REPORT_REQUEST,
} from './daily-report-constant';

const initialState = {
  dailyReportDetails: [],
  isFetching: false,
  totalRecords: '',
};

const dailyReport = (state = initialState, action) => {
  switch (action.type) {
    case DAILY_REPORT_REQUEST:
      return {
        ...state,
        dailyReportDetails: [],
        isFetching: true,
      };
    case DAILY_REPORT_FAILURE:
      return {
        ...state,
        dailyReportDetails: [],
        isFetching: false,
      };
    case DAILY_REPORT_SUCCESS:
      return {
        ...state,
        dailyReportDetails: [ action.data ],
        isFetching: false,
        totalRecords: action.totalRecords,
      };
    default:
      return state;
  }
};

export default dailyReport;
