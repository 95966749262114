import {
  ADD_POLICY_HOLDER_ADDRESS_FAILURE,
  ADD_POLICY_HOLDER_ADDRESS_SUCCESS,
  ADD_POLICY_HOLDER_ADDRESS_REQUEST,
  EDIT_POLICY_HOLDER_ADDRESS_REQUEST,
} from './nucleon-profile-constants';

const initialState = {
  addPolicyHolderAddressDetails: {},
  pincodeDetails: {},
  error: false,
  isFetching: false,
};

const addPolicyHolderAddress = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POLICY_HOLDER_ADDRESS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case EDIT_POLICY_HOLDER_ADDRESS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_POLICY_HOLDER_ADDRESS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case ADD_POLICY_HOLDER_ADDRESS_SUCCESS:
      return {
        ...state,
        addPolicyHolderAddressDetails: action.data,
        error: false,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default addPolicyHolderAddress;
