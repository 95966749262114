import {
    USER_SIM_DETAILS_REQUEST,
    USER_SIM_DETAILS_SUCCESS,
    USER_SIM_DETAILS_FAILURE,
} from './profile-constants';

const userSimDetailsRequest = (id) => ({
  id,
  type: USER_SIM_DETAILS_REQUEST,
});

export const userSimDetailsSuccess = (data) => ({
  data,
  type: USER_SIM_DETAILS_SUCCESS,
});
export const userSimDetailsFailure = () => ({
  type: USER_SIM_DETAILS_FAILURE,
});

export const userSimDetails = async (id, dispatch) => {
  dispatch(userSimDetailsRequest(id));
};
