/* eslint-disable sort-keys */
module.exports = {
 // PORT: process.env.PORT || 3011,
 // IDENTITY_SERVICE_URL: process.env.IDENTITY_SERVICE_URL || 'https://identity-service.moneyloji.com',
 // FILE_SERVICE_URL: process.env.FILE_SERVICE_URL || 'https://file-service.moneyloji.com',
 // LOAN_SERVICE_URL: process.env.LOAN_SERVICE_URL || 'https://loan-service.moneyloji.com',
 // BANKING_SERVICE_URL: process.env.BANKING_SERVICE_URL || 'https://banking-service.moneyloji.com',
 // PARTNER_SERVICE_URL: process.env.PARTNER_SERVICE_URL || 'https://partner-service.moneyloji.com',
 // REPORTING_SERVICE_URL: process.env.REPORTING_SERVICE_URL || 'https://reporting-service.moneyloji.com',

PORT: process.env.PORT || 3011,
  IDENTITY_SERVICE_URL: process.env.IDENTITY_SERVICE_URL || 'https://moneyloji-service.moneyloji.com',
  FILE_SERVICE_URL: process.env.FILE_SERVICE_URL || 'https://moneyloji-service.moneyloji.com',
  LOAN_SERVICE_URL: process.env.LOAN_SERVICE_URL || 'https://moneyloji-service.moneyloji.com',
  BANKING_SERVICE_URL: process.env.BANKING_SERVICE_URL || 'https://moneyloji-service.moneyloji.com',
  PARTNER_SERVICE_URL: process.env.PARTNER_SERVICE_URL || 'https://moneyloji-service.moneyloji.com',
  REPORTING_SERVICE_URL: process.env.REPORTING_SERVICE_URL || 'https://reporting-service.moneyloji.com',
  NUCLEON_HEALTH_SERVICE: process.env.NUCLEON_HEALTH_SERVICE || 'https://healthcare-service.moneyloji.com',
};

