import {
  HEALTH_CARE_LOAN_INFO_FAILURE,
  HEALTH_CARE_LOAN_INFO_REQUEST,
  HEALTH_CARE_LOAN_INFO_SUCCESS,
} from './health-care-loan-info-constants';

const initialState = {
  isFetching: false,
  healthCareLoanInfoDetails: [],
};

const healthCareLoanInfo = (state = initialState, action) => {
  switch (action.type) {
    case HEALTH_CARE_LOAN_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case HEALTH_CARE_LOAN_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case HEALTH_CARE_LOAN_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        healthCareLoanInfoDetails: action.data,
      };
    default:
      return state;
  }
};

export default healthCareLoanInfo;
