import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import { healthCareLoanInfo } from './health-care-loan-info-action';
import Header from '../../components/header';
import HealthCareLoanInformationCard from '../../components/Health-Care-Loan/health-care-loan-information';
import HealthCareRePayment from '../../components/Health-Care-Loan/health-care-repayment';
import Footer from '../../components/footer';

const HealthCareLoanInformation = ({ match }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const path = window.location.pathname.split('/');
  const loanId = path[path.length - 1];

  // eslint-disable-next-line no-unused-vars
  const { healthCareLoanInfoDetails } = useSelector(
    (state) => state.healthCareLoanInfo
  );

  const {
    patient, loanDetails,
  } = healthCareLoanInfoDetails;

  const addClassCallBack = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (match.params) {
      healthCareLoanInfo(match.params.id, dispatch);
    } else if (loanId) {
      healthCareLoanInfo(loanId, dispatch);
    }
  }, []);

  return (
    <React.Fragment>
      <div>
        <Header />
        <div className="common-container">
          <div className={`common-wrapper ${show ? 'active' : ''} `}>
            <div className="col-md-12 mpad">
              <div className="common-heading">
                <h1>Health Care Loans Information</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Health Care Loan Information</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="Loan-info-sec-container row">
              <div className="col-md-3 mpad">
                <HealthCareLoanInformationCard
                  healthCareLoanInfoDetails={healthCareLoanInfoDetails}
                  patient={patient && patient}
                />
              </div>
              <div className="col-md-9 mpad">
                <div className="loan-info-repay">
                  <div className="col-md-12 mpad close-loan-repayment-container">
                    <HealthCareRePayment
                      loanDetails={loanDetails}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer show={show} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default HealthCareLoanInformation;
