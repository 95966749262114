import {
  TEXT,
  DATE_RANGE_TYPE,
  NUMBER_RANGE_TYPE,
  NUMBER,
  SELECT
} from '../../constants/data-table';

export const DISBURSMENT_REQUEST = 'DISBURSMENT_REQUEST';
export const DISBURSMENT_SUCCESS = 'DISBURSMENT_SUCCESS';
export const DISBURSMENT_FAILURE = 'DISBURSMENT_FAILURE';

export const DISBURSMENT_CHIP_REQUEST = 'DISBURSMENT_CHIP_REQUEST';
export const DISBURSMENT_CHIP_SUCCESS = 'DISBURSMENT_CHIP_SUCCESS';
export const DISBURSMENT_CHIP_FAILURE = 'DISBURSMENT_CHIP_FAILURE';

export const disbursmentFilters = [
  {
    name: 'Loan Number',
    value: 'number',
    filterCondition: 'eq',
    placeholder: 'ML-001230',
    type: TEXT,
  },
  {
    name: 'Name',
    value: 'name',
    filterCondition: 'ilike',
    placeholder: 'Rahul Kumar',
    type: TEXT,
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '9876543263',
    type: NUMBER,
  },
  {
    name: 'Value',
    value: 'amount',
    filterCondition: 'eq',
    minPlaceholder: 'min value',
    maxPlaceholder: 'max value',
    minFilterCondition: 'gte',
    maxFilterCondition: 'lte',
    type: NUMBER_RANGE_TYPE,
  },
  {
    name: 'Loan Status',
    value: 'loanStatus',
    placeholder: 'active',
    filterCondition: 'eq',
    type: SELECT,
    options: [
      { name: 'Active', value: 'active' },
      { name: 'Closed', value: 'closed' },
    ],
  },
  {
    name: 'Disbursement Date',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  }
];
