
export const LINK = 'LINK';
export const DROPDOWN = 'DROPDOWN';

export const HeadLinksAdmin = [
  {
    type: LINK,
    href: '/dashboard',
    label: 'Dashboard'
  },
  {
    type: LINK,
    href: '/users',
    label: 'User'
  },
  {
    type: LINK,
    href: '/loan',
    label: 'Loans'
  },
  {
    type: DROPDOWN,
    label: 'HealthCare',
    dropdownItem: [
      {
        type: LINK,
        href: '/policy-holder',
        label: 'Policy Holders'
      },
      {
        type: LINK,
        href: '/health-care-loan',
        label: 'Health Care Loan'
      },
      {
        type: LINK,
        href: '/generated-leads',
        label: 'Generated Lists'
      },
      {
        type: LINK,
        href: '/sent-utrs',
        label: 'Sent UTRs'
      },
    ]
  },
  {
    type: DROPDOWN,
    label: 'Reports',
    dropdownItem: [
      {
        type: LINK,
        href: '/disbursment',
        label: 'Disbursements'
      },
      {
        type: LINK,
        href: '/collections',
        label: 'Collections'
      },
      {
        type: LINK,
        href: '/defaults',
        label: 'Defaults'
      },
      {
        type: LINK,
        href: '/allocations',
        label: 'Allocations'
      },
      {
        type: LINK,
        href: '/report/loan',
        label: 'Loans Report'
      },
      {
        type: LINK,
        href: '/report/users',
        label: 'Users Report'
      },
      {
        type: LINK,
        href: '/report/users?sortBy=updatedAt',
        label: 'Users With Recent Updates'
      },
      {
        type: LINK,
        href: '/emi',
        label: 'EMIs'
      },
      {
        type: LINK,
        href: '/payments',
        label: 'Payments'
      },
      {
        type: LINK,
        href: '/report/loan-dpd',
        label: 'Loan Dpd'
      },
      {
        type: LINK,
        href: '/report/daily-report',
        label: 'Daily Report'
      },
    ]
  },
  {
    type: DROPDOWN,
    label: 'Settings',
    dropdownItem: [
      {
        type: LINK,
        href: '/manageusers',
        label: 'Manage Users'
      },
      {
        type: LINK,
        href: '/managepincode',
        label: 'Manage Pincode'
      },
      {
        type: LINK,
        href: '/managebanks',
        label: 'Manage Banks'
      },
      {
        type: LINK,
        href: '/manage-partner',
        label: 'Manage Partner'
      },
      {
        type: LINK,
        href: '/manageprofession',
        label: 'Manage Profession'
      },
      {
        type: LINK,
        href: '/manage-product',
        label: 'Manage Product'
      },
    ]
  }
];

export const HeaderLinksCreditManager = [
  {
    type: LINK,
    href: '/dashboard',
    label: 'Dashboard'
  },
  {
    type: LINK,
    href: '/users',
    label: 'User'
  },
  {
    type: LINK,
    href: '/loan',
    label: 'Loans'
  },
  {
    type: LINK,
    href: '/loan-leads',
    label: 'Loans-Leads'
  },
  {
    type: DROPDOWN,
    label: 'HealthCare',
    dropdownItem: [
      {
        type: LINK,
        href: '/policy-holder',
        label: 'Policy Holders'
      },
      {
        type: LINK,
        href: '/health-care-loan',
        label: 'Health Care Loan'
      },
      {
        type: LINK,
        href: '/generated-leads',
        label: 'Generated Lists'
      },
      {
        type: LINK,
        href: '/sent-utrs',
        label: 'Sent UTRs'
      },
    ]
  },
  {
    type: DROPDOWN,
    label: 'Reports',
    dropdownItem: [
      {
        type: LINK,
        href: '/disbursment',
        label: 'Disbursements'
      },
      {
        type: LINK,
        href: '/collections',
        label: 'Collections'
      },
      {
        type: LINK,
        href: '/defaults',
        label: 'Defaults'
      },
      {
        type: LINK,
        href: '/report/loan',
        label: 'Loans Report'
      },
      {
        type: LINK,
        href: '/report/users',
        label: 'Users Report'
      },
      {
        type: LINK,
        href: '/report/users?sortBy=updatedAt',
        label: 'Users With Recent Updates'
      },
      {
        type: LINK,
        href: '/emi',
        label: 'EMIs'
      },
      {
        type: LINK,
        href: '/payments',
        label: 'Payments'
      },
      {
        type: LINK,
        href: '/report/loan-dpd',
        label: 'Loan Dpd'
      },
      {
        type: LINK,
        href: '/report/daily-report',
        label: 'Daily Report'
      },
    ]
  },
]

export const HeaderLinksCashCollection =
    [
      {
        type: LINK,
        href: '/dashboard',
        label: 'Dashboard'
      },
      {
        type: LINK,
        href: '/users',
        label: 'User'
      },
      {
        type: LINK,
        href: '/loan',
        label: 'Loans'
      },
      {
        type: LINK,
        href: '/loan-leads',
        label: 'Loans-Leads'
      },
      {
        type: DROPDOWN,
        label: 'Reports',
        dropdownItem: [
          {
            type: LINK,
            href: '/disbursment',
            label: 'Disbursements'
          },
          {
            type: LINK,
            href: '/collections',
            label: 'Collections'
          },
          {
            type: LINK,
            href: '/defaults',
            label: 'Defaults'
          },
          {
            type: LINK,
            href: '/report/loan',
            label: 'Loans Report'
          },
          {
            type: LINK,
            href: '/report/users',
            label: 'Users Report'
          },
          {
            type: LINK,
            href: '/report/users?sortBy=updatedAt',
            label: 'Users With Recent Updates'
          },
          {
            type: LINK,
            href: '/emi',
            label: 'EMIs'
          },
          {
            type: LINK,
            href: '/payments',
            label: 'Payments'
          },
          {
            type: LINK,
            href: '/report/loan-dpd',
            label: 'Loan Dpd'
          },
          {
            type: LINK,
            href: '/report/daily-report',
            label: 'Daily Report'
          },
        ]
      },
    ]
export const HeaderLinksCallCenterStaff = [
  {
    type: LINK,
    href: '/users',
    label: 'User'
  },
  {
    type: LINK,
    href: '/loan',
    label: 'Loans'
  },
  {
    type: LINK,
    href: '/loan-leads',
    label: 'Loans-Leads'
  },
  {
    type: DROPDOWN,
    label: 'HealthCare',
    dropdownItem: [
      {
        type: LINK,
        href: '/policy-holder',
        label: 'Policy Holders'
      },
      {
        type: LINK,
        href: '/health-care-loan',
        label: 'Health Care Loan'
      },
      {
        type: LINK,
        href: '/generated-leads',
        label: 'Generated Lists'
      },
      {
        type: LINK,
        href: '/sent-utrs',
        label: 'Sent UTRs'
      },
    ]
  },
  {
    type: DROPDOWN,
    label: 'Reports',
    dropdownItem: [
      {
        type: LINK,
        href: '/defaults',
        label: 'Defaults'
      },
      {
        type: LINK,
        href: '/report/loan',
        label: 'Loans Report'
      },
      {
        type: LINK,
        href: '/report/users',
        label: 'Users Report'
      },
      {
        type: LINK,
        href: '/report/users?sortBy=updatedAt',
        label: 'Users With Recent Updates'
      },
      {
        type: LINK,
        href: '/report/daily-report',
        label: 'Daily Report'
      },
    ]
  },
];

export const HeaderLinksAssanCredits = [
  {
    type: LINK,
    href: '/aassan-credits-loans',
    label: 'Aassan-Credits-Loans'
  },
];