/* eslint-disable no-unused-vars */
import React from 'react';
import {
  statusAndVerifiedMarker,
  viewOrDownloadFile,
  addLinkToField,
  viewOrDownloadBackAadharFile,
  viewOrDownloadFrontAadharFile,
  viewOrDownloadFrontFile,
  viewOrDownloadBackFile,
  viewOrDownloadPanFile,
  addLinkToFieldLoan,
} from '../../../utils/table-data-helper';
import { toDateTimeFormat, toDateFormat, toNumberWithComma } from '../../../utils/formattor';

const fraudReportAnalyticsStatus = (cell) => {
  if(cell === 'FAIL'){
    return <label className="status-fail-anyalisis">{cell}</label>;
  }else if(cell === 'PASS'){
    return <label className="status-pass-anyalisis">{cell}</label>;
  }
  return <label className="status-initiated-anyalisis">{cell}</label>;

};

export const getprofileTabDetailsConst = {
  IDENTITY_CHECK: {
    tableName: 'IDENTITY CHECK',
    tableHeaderData: [
      {
        name: 'Document Type',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentType',
      },
      {
        name: 'DownLoad Front Pic',
        dataFormat: viewOrDownloadFile,
        dataKey: 'documentFrontId',
      },
      {
        name: 'Download Back Pic',
        dataFormat: viewOrDownloadFile,
        dataKey: 'documentBackId',
      },
      {
        name: 'Document Number',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentNumber',
      },
      {
        name: 'status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
    ],
  },
  FINANCIAL_CHECK: {
    tableName: 'FINANCIAL CHECK',
    tableHeaderData: [
      {
        name: 'Document Type',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentType',
      },
      {
        name: 'Download Document',
        dataFormat: viewOrDownloadFile,
        dataKey: 'documentFileId',
      },
      {
        name: 'Validity',
        dataFormat: (cell, tableRowData = null) => toDateFormat(cell),
        dataKey: 'validity',
      },
      {
        name: 'Document Password',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentPassword',
      },
      {
        name: 'ifsc',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'ifsc',
      },
      {
        name: 'status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
      {
        name: 'action',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentType',
      },
    ],
  },
  USER_CALL_LOGS: {
    tableName: 'USER CALL LOGS',
    tableHeaderData: [
      {
        name: 'Category',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'category',
      },
      {
        name: 'Created At',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'createdAt',
      },
      {
        name: 'Created By',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'name',
      },
      {
        name: 'Description',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'description',
      },
      {
        name: 'Status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
    ],
  },
  LOAN_INFO: {
    tableName: 'LOAN INFO',
    tableHeaderData: [
      {
        name: 'Loan Number',
        dataFormat: addLinkToFieldLoan,
        dataKey: 'number',
      },
      {
        name: 'Loan Amount',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'amount',
      },
      {
        name: 'Last Payment Date',
        dataFormat: (cell, tableRowData = null) => toDateTimeFormat(cell),
        dataKey: 'lastPaymentDate',
      },
      {
        name: 'Status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
    ],
  },
  DOCUMENTS: {
    tableName: 'DOCUMENTS',
    tableHeaderData: [
      {
        name: 'Document Type',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'type',
      },
      {
        name: 'DownLoad Front Pic',
        dataFormat: viewOrDownloadFrontFile,
        dataKey: 'frontDocumentId',
      },
      {
        name: 'Download Back Pic',
        dataFormat: viewOrDownloadBackFile,
        dataKey: 'backDocumentId',
      },
      // {
      //   name: 'Document Number',
      //   dataFormat: (cell,tableRowData = null) => cell,
      //   dataKey: 'documentNumber'
      // },
      {
        name: 'status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
      {
        name: 'Created At',
        dataFormat: (cell, tableRowData = null) => toDateTimeFormat(cell),
        dataKey: 'createdAt',
      },
      {
        name: 'Updated At',
        dataFormat: (cell, tableRowData = null) => toDateTimeFormat(cell),
        dataKey: 'updatedAt',
      },
    ],
  },
  REFERENCES: {
    tableName: 'REFERENCES',
    tableHeaderData: [
      {
        name: 'Name',
        dataFormat: addLinkToField,
        dataKey: 'name',
      },
      {
        name: 'Mobile Number',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'mobileNumber',
      },
      {
        name: 'Email',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'emailId',
      },
      {
        name: 'Relationship',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'relationship',
      },
      {
        name: 'Aadhar Front',
        dataFormat: viewOrDownloadFrontAadharFile,
      },
      {
        name: 'Aadhar Back',
        dataFormat: viewOrDownloadBackAadharFile,
      },
      {
        name: 'Pan Card',
        dataFormat: viewOrDownloadPanFile,
      },
      {
        name: 'status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
      {
        name: 'uploadDocs',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'uploadDocs',
      },
    ],
  },
  FRAUD_ANALYSIS: {
    tableName: 'FRAUD ANALYISIS',
    tableHeaderData: [
      {
        name: 'Rule',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'rule',
      },
      {
        name: 'Rule Id',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'ruleId',
      },
      {
        name: 'status',
        dataFormat: (cell, tableRowData = null) => fraudReportAnalyticsStatus(cell),
        dataKey: 'status',
      },
    ]
  },
  LAST_MONTH_LOANS: {
    tableName: 'LAST MONTH LOANS',
    tableHeaderData: [
      {
        name: 'category',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'category',
      },
      {
        name: 'amount',
        dataFormat: (cell, tableRowData = null) =>toNumberWithComma(cell),
        dataKey: 'amount',
      },
      {
        name: 'balanceAfterTransaction',
        dataFormat: (cell, tableRowData = null) => toNumberWithComma(cell),
        dataKey: 'balanceAfterTransaction',
      },
      {
        name: 'description',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'description',
      },
      {
        name: 'date',
        dataFormat: (cell, tableRowData = null) => toDateFormat(cell),
        dataKey: 'date',
      },
    ]
  },
  SIM_DETAILS: {
    tableName: 'NETWORK INTELLIGENCE',
    tableHeaderData: [
      {
        name: 'Label',
        dataFormat: (cell, tableRowData = null) => _.startCase(cell),
        dataKey: '0',
      },
      {
        name: 'Value',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: '1',
      },
    ]
  },
};
