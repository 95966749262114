import { SELECT, TEXT } from '../../../constants/form-type';

export const userAddressKeyValueMap = [
  {
    key: 'addressLine1',
    prefix: ''
  },
  {
    key: 'addressLine2',
    prefix: ', '

  },
  {
    key: 'city',
    prefix: ', '
  },
  {
    key: 'stateName',
    prefix: ', '
  },
  {
    key: 'pincode',
    prefix: ' - '
  },
]

export const editAddressFormConstant = [
  {
    label: 'Flat No. House No. Building No.',
    name: 'addressLine1',
    placeholder: 'B-165, First Floor',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'Socity Area Stree',
    name: 'addressLine2',
    placeholder: 'Building name',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'Address Type: ',
    name: 'addressType',
    placeholder: 'Haryana',
    validation: {},
    options: [
      { name: 'Current', value: 'current' },
      { name: 'Permanent', value: 'permanent' },
      { name: 'Office', value: 'office' }
    ],
    isDisabled: false,
    type: SELECT,
  },
  {
    label: 'Pin Code',
    name: 'pincode',
    placeholder: '124001',
    validation: {},
    isDisabled: false,
    type: TEXT,
  },
  {
    label: 'City',
    name: 'districtName',
    placeholder: 'New Delhi',
    validation: {},
    isDisabled: true,
    type: TEXT,
  },
  {
    label: 'State',
    name: 'stateName',
    placeholder: 'Haryana',
    validation: {},
    options: [],
    isDisabled: true,
    type: SELECT,
  },
  {
    label: 'Country',
    name: 'country',
    placeholder: 'India',
    validation: {},
    options: [
      { name: 'India', value: 'India' },
      // {name:'Pakistan',value:'pakistan'},
      // {name:'Nepal',value:'nepal'},
      // {name:'SriLanka',value:'srilanka'},
    ],
    isDisabled: true,
    type: SELECT,
  }
]