import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../../utils/http-helper';
import { addNewUser } from '../../../utils/urls';
import { ADD_HEALTH_USER_REQUEST } from './add-nucleon-user-constants';
import { addHealthCareUserFailure, addHealthCareUserSuccess } from './add-health-care-user-action'

const { postRequest } = new HttpHelper();

export function* addHealthCareUser(action) {
  try {
    const response = yield call(postRequest, {
      url: addNewUser(),
      data: action.data,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');

      yield put(addHealthCareUserFailure());
    } else {
      yield put(addHealthCareUserSuccess());

      Toastr.success('Policy Has Been Saved', 'Success');

      window.location.pathname = '/policy-holder'
    }

  } catch (err) {
    throw new Error(err);
  }
}

export function* watchAddHealthCareUser() {
  yield takeEvery(ADD_HEALTH_USER_REQUEST, addHealthCareUser);
}
