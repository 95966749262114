import React, { useState } from 'react';
import { userDetailsKeyValueMap } from './user-details-const';
import './user-details-basic.css';
import FieldValue from '../../field-key-value';
import { getValueForKeyInObj } from '../../../utils/table-data-helper';

const UserDetailsBasicExtra = ({ userProfileData,updateProfileDetails }) => {
  const [keyValueMap, setKeyValueMap] = useState(userDetailsKeyValueMap);

  const onChangeHandler = (value, index) => {
    const newUserState = JSON.parse(JSON.stringify(userDetailsKeyValueMap));

    newUserState[index].value = value;
    setKeyValueMap(newUserState);
    const payload = {};

    if(newUserState[index].name2){
      payload[`${newUserState[index].name}`]= parseInt(value.split('-')[0].trim(),10);
      payload[`${newUserState[index].name2}`]= parseInt(value.split('-')[1].trim(),10);
    }else{
      payload[`${newUserState[index].name}`]= value;
    }
    updateProfileDetails(payload);
  }

  return (
    <React.Fragment>
      <div className="user-details-basic-extra-wrapper">
        {keyValueMap.map((item, index) =>
          <FieldValue
            key={index}
            fieldName={item.label}
            fieldValue={getValueForKeyInObj(userProfileData, item.key) + (item.key2 ? ` - ${  getValueForKeyInObj(userProfileData, item.key2)}` : '')}
            type={item.type}
            options={item.options}
            verifyStatus={userProfileData && item.verifyStatusKey && userProfileData[item.verifyStatusKey]}
            valueForForm={item.value}
            onChangeHandler={(e) => { onChangeHandler(e, index) }}
          />
        )}
        <div className='col-md-12' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name">Total Work Experienace</div>
            <div className="field-value">{userProfileData.content ? (`${userProfileData.content.totalWorkExperience && userProfileData.content.totalWorkExperience.year} year ${userProfileData.content.totalWorkExperience && userProfileData.content.totalWorkExperience.month} month` || '-') : '-'}</div>
          </div>
        </div>
        <div className='col-md-12' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name">Current Work Experienace</div>
            <div className="field-value">{userProfileData.content ? (`${userProfileData.content.workExperienceInCurrentCompany && userProfileData.content.workExperienceInCurrentCompany.year} year ${userProfileData.content.workExperienceInCurrentCompany && userProfileData.content.workExperienceInCurrentCompany.month} month` || '-') : '-'}</div>
          </div>
        </div>

        <div className='col-md-12' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name"> Source </div>
            <div className="field-value">{userProfileData.user.source ? (`${userProfileData.user.source.split('&')[0]}`).replace("utm_source=", "") : '-'}</div>
          </div>
        </div>
        <div className='col-md-12' style={{ left: "-15px" }}>
          <div className="field-value-wrapper">
            <div className="field-name">Medium</div>
            <div className="field-value">{userProfileData.user.source  ? (`${userProfileData.user.source.split('&')[1]}`).replace("utm_medium=", "") : '-'}</div>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default UserDetailsBasicExtra;
