/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { dailyReportFilters } from './daily-report-constant';
import { dailyReport as dailyReportAction } from './daily-report-action';

import Header from '../../components/header';
import Footer from '../../components/footer';

import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { collectionExportUrl } from '../../utils/urls';

const DailyReport = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getCollectionData = (urlParams) => {
    dailyReportAction(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt']
    };

    getCollectionData(urlParams);
  }, []);

  const { dailyReportDetails, totalRecords, isFetching } = useSelector(
    (state) => state.dailyReport
  );

  const dailyReportList =
  dailyReportDetails &&
  dailyReportDetails.map((dailyReportData) => {

      return {
        totalUsersCount: dailyReportData.totalUsersCount,
        salariedUsersCount: dailyReportData.salariedUsersCount,
        salaryAboveTwentyCount: dailyReportData.salaryAboveTwentyCount,
        panUploadedCount: dailyReportData.panUploadedCount,
        panVerifiedCount: dailyReportData.panVerifiedCount,
        aadharUploadedCount: dailyReportData.aadharUploadedCount,
        aadharVerifiedCount: dailyReportData.aadharVerifiedCount,
        statementUploadedCount: dailyReportData.statementUploadedCount,
        salarySlipUploadedCount: dailyReportData.salarySlipUploadedCount,
        crifScoreAboveSixHundredCount: dailyReportData.crifScoreAboveSixHundredCount,
        eligibilityCreatedCount: dailyReportData.eligibilityCreatedCount,
      };
    });

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'totalUsersCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: true,
      name: 'Total Users',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'salariedUsersCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Salaried Users',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'salaryAboveTwentyCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Salary Above Twenty',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'panUploadedCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Pan Uploaded',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'panVerifiedCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Pan Verified',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'aadharUploadedCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Aadhar Uploaded',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'aadharVerifiedCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Aadhar Verified',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'statementUploadedCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Statement Uploaded',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'salarySlipUploadedCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Salary Slip Uploaded',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'crifScoreAboveSixHundredCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'crif Score Above Six Hundred',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'eligibilityCreatedCount',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'eligibility Created',
      width: '120',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Daily Report</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Report</Breadcrumb.Item>
                <Breadcrumb.Item active>daily Report</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          <div className="today-emi-sec">
          <DataTable
              tableData={dailyReportList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getCollectionData}
              exportUrl={collectionExportUrl}
              tableTitle="DAILY REPORT"
              pageFilters={dailyReportFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
        </div>
      </div>
    </div>
  );
};

export default DailyReport;
