import { NUMBER } from '../../../constants/data-table';
import { DATE, SELECT, TEXT } from '../../../constants/form-type';

export const addLoanConstant = [
  {
    label: 'Hospital Name',
    name: 'hospitalName',
    placeholder: '',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: SELECT,
    isDisabled: false,
    options: [],
  },
  {
    label: 'Loan Amount',
    name: 'hospitalDueAmount',
    placeholder: 'Enter Disbursement Loan Amount for Hospital',
    isDisabled: false,
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Hospital Account',
    name: 'hospitalAccountNumber',
    placeholder: 'Hospital Account Number',
    isDisabled: true,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Hospital IFSC',
    name: 'hospitalIfsc',
    placeholder: 'Hospital IFSC',
    isDisabled: true,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Pharmacy Name',
    name: 'pharmacyName',
    placeholder: '',
    isDisabled: false,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: SELECT,
    options: [],
  },
  {
    label: 'Loan Amount',
    name: 'pharmacyDueAmount',
    placeholder: 'Enter Disbursement Loan Amount for Pathology',
    isDisabled: false,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Pharmacy Account',
    name: 'pharmacyAccountNumber',
    placeholder: 'Pharmacy Account Number',
    isDisabled: true,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Pharmacy IFSC',
    name: 'pharmacyIfsc',
    placeholder: 'Pharmacy IFSC',
    isDisabled: true,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Pathology Name',
    name: 'pathologyName',
    placeholder: '',
    isDisabled: false,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: SELECT,
    options: [],
  },
  {
    label: 'Loan Amount',
    name: 'pathologyDueAmount',
    placeholder: 'Enter Disbursement Loan Amount for Pharmacy',
    isDisabled: false,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Pathology Account',
    name: 'pathologyAccountNumber',
    placeholder: 'Pathology Account Number',
    isDisabled: true,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Pathology IFSC',
    name: 'pathologyIfsc',
    placeholder: 'Pathology IFSC',
    isDisabled: true,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Tenure',
    name: 'tenure',
    placeholder: 'Enter Tenure',
    isDisabled: false,
    validation: { isRequired: false, isEmail: false, isMobile: false },
    type: NUMBER,
  },
  {
    label: 'Relation With policy Holder',
    name: 'relationWithPolicyHolder',
    placeholder: '',
    isDisabled: false,
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: SELECT,
    options: [
      { name: 'self', value: 'self' },
      { name: 'daughter', value: 'daughter' },
      { name: 'son', value: 'son' },
      { name: 'mother', value: 'mother' },
      { name: 'father', value: 'father' },
      { name: 'spouse',value: 'spouse' }
    ],
  },
  {
    label: 'Date of Admission',
    name: 'doa',
    placeholder: '',
    isDisabled: true,
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: DATE,
  },
];

export const patientFields = [
  {
    label: 'Title',
    name: 'title',
    placeholder: 'Select Title',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    options: [
      { name: 'mr.', value: 'Mr.' },
      { name: 'mrs.', value: 'Mrs.' },
    ],
    type: SELECT,
  },
  {
    label: 'First Name',
    name: 'firstName',
    placeholder: 'Enter First Name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Enter Last Name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Gender',
    name: 'gender',
    placeholder: 'Select Gender',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    options: [
      { name: 'Male', value: 'male' },
      { name: 'Female', value: 'female' },
      { name: 'Other', value: 'other' },
    ],
    type: SELECT,
  },
  {
    label: 'Date of Birth',
    name: 'dob',
    placeholder: 'Select Date of Birth',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: DATE,
  },
  {
    label: 'PAN number',
    name: 'panNumber',
    placeholder: 'Enter Pan Number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Aadhaar Number',
    name: 'aadhaarNumber',
    placeholder: 'Enter Aadhar Number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
];

// {
  //   label: 'Residence Since',
  //   name: 'livingSince',
  //   placeholder: 'Select Date',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: DATE,
  // },
  // {
  //   label: 'Residence Type',
  //   name: 'addressType',
  //   placeholder: 'Select',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: SELECT,
  // },
  // {
  //   label: 'Address Street 1',
  //   name: 'addressStreet1',
  //   placeholder: 'Enter Street No 1',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: TEXT,
  // },
  // {
  //   label: 'Address Street 2',
  //   name: 'addressStreet2',
  //   placeholder: 'Enter Street No 2',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: TEXT,
  // },
  // {
  //   label: 'city',
  //   name: 'city',
  //   placeholder: 'Enter city name',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: TEXT,
  // },
  // {
  //   label: 'pincode',
  //   name: 'pincode',
  //   placeholder: 'Enter pincode',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: TEXT,
  // },
  // {
  //   label: 'Permanent Address Street 1',
  //   name: 'permanentAddressStreet1',
  //   placeholder: 'Enter Street No 1',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: TEXT,
  // },
  // {
  //   label: 'Permanent Address Street 2',
  //   name: 'permanentAddressStreet2',
  //   placeholder: 'Enter Street No 2',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: TEXT,
  // },
  // {
  //   label: 'Permanent Address city',
  //   name: 'permanentAddressCity',
  //   placeholder: 'Enter city name',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: TEXT,
  // },
  // {
  //   label: 'Permanent Address pincode',
  //   name: 'permanentAddressPincode',
  //   placeholder: 'Enter pincode',
  //   validation: { isRequired: true, isEmail: false, isMobile: false },
  //   type: TEXT,
  // },