import { TEXT, DATE } from '../../../constants/field-value-key';

export const UserSummaryDetails = [
  {
    label: 'Gender',
    key: 'gender',
    type: TEXT,
  },
  {
    label: 'Date of Birth',
    key: 'dob',
    type: DATE,
  },
  {
    label: 'Father Name(as on PAN)',
    key: 'fatherName',
    type: TEXT,
  },
  {
    label: 'Phone Number',
    key: 'mobileNumber',
    type: TEXT,
  },
  {
    label: 'Email',
    key: 'email',
    type: TEXT,
  },
  {
    label: 'PAN number',
    key: 'panNumber',
    type: TEXT,
  },
  {
    label: 'Aadhaar Number',
    key: 'aadhaarNumber',
    type: TEXT,
  },
  {
    label: 'Policy Number',
    key: 'policyNumber',
    type: TEXT,
  },
  {
    label: 'Policy Start Date',
    key: 'policyStartDate',
    type: DATE,
  },
  {
    label: 'Policy End Date',
    key: 'policyExpiryDate',
    type: DATE,
  },
]