import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { hospitalList } from '../../../containers/neuclon-profile/hospital-list-action';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import { getHospitalListOption, getPharmacyListOption, getPathologyListOption } from '../../../containers/profile/common';
import { addpatientLoan } from '../../../containers/neuclon-profile/add-patient-loan-action';
import './add-loan.css';
import FormElement from '../../form-elements/index';
import Button from '../../form-elements/button';

import {
  setInitialFormValues,
  formFieldValidator,
  validateForm,
  getFormDataValue,
} from '../../../utils/form-helper';
import { addLoanConstant, patientFields } from './add-loan-constant';

const AddLoan = ({
  addLoan,
  setAddLoan,
  closeaddLoanPopup,
  hospitalDetailsData,
  setPublicId,
  policyHolderId,
  handleReload,
}) => {
  const [addLoanForm, setAddLoanForm] = useState([]);
  const [hospitalData, setHospitalData] = useState([]);
  const [pharmacyData, setPharmacyData] = useState([]);
  const [pathologyData, setPathologyData] = useState([]);
  const [isSelf, setIsSelf ] = useState(true);
  const [addpatientField, setAddPatientFields] = useState([]);

  const dispatch = useDispatch();

  const { success } = useSelector((state) => state.addPatientLoan);
  const updateHospitalList = (gethospitalList) => {
    setHospitalData(getHospitalListOption(gethospitalList));
  }

  const updatePharmacyList = (getphramacyList) => {
    setPharmacyData(getPharmacyListOption(getphramacyList));
  }

  const updatePathologyList = (getpathologyList) => {
    setPathologyData(getPathologyListOption(getpathologyList));
  }

  useEffect(() => {
    if(Object.keys(hospitalDetailsData).length > 0){
      updatePharmacyList(hospitalDetailsData.pharmacies);
      updatePathologyList(hospitalDetailsData.pathologies);
    }
  },[hospitalDetailsData])

  const populateOptions = (formName, options) => {
    if(formName === 'pharmacyName'){
      return pharmacyData;
    }else if(formName === 'pathologyName'){
      return pathologyData;
    }else if(formName === 'hospitalName'){
      return hospitalData;
    }
    return options;
  }

  useEffect(() => {
    hospitalList(dispatch,updateHospitalList);
    setInitialFormValues(addLoanConstant, setAddLoanForm);
    setInitialFormValues(patientFields, setAddPatientFields);
  }, []);

  const onChangeHandler = (form, formField, formFieldValue, setForm) => {
    if(formField === 'hospitalName' && formFieldValue !== undefined){
      setPublicId(formFieldValue);
    }

    if(formField === 'relationWithPolicyHolder' && formFieldValue !== undefined && formFieldValue !== 'self'){
      setIsSelf(false);
    }else if(formField === 'relationWithPolicyHolder' && formFieldValue !== undefined && formFieldValue === 'self'){
      setIsSelf(true);
    }

    const newForm = JSON.parse(JSON.stringify(form));

    if (newForm && Array.isArray(newForm)) {
      newForm.map((formItemParam) => {
        const formItem = formItemParam

        if (formItem.name === formField) {
          formItem.value = formFieldValue;
          formItem.isTouched = true;
          const [isValid, result] = formFieldValidator(formItem.validation, formItem.value);

          formItem.isValid = isValid;
          formItem.errorMsg = result;
        }
        return false;
      });
      setForm(newForm);
    }
  }

  const CheckPatientForm = () => {
    const [addNewPatientForm, isValid] = validateForm(addpatientField);

    setAddPatientFields(addNewPatientForm);
    if (isValid) {
      return true;
    }
    return false;
  }

  const handleAddLoan = () => {

    if(!isSelf){
      const checkPatientFields = CheckPatientForm();

      if(checkPatientFields){
        const [newAddLoanForm, isValid] = validateForm(addLoanForm);

        setAddLoanForm(newAddLoanForm);

        if (isValid) {
          const newAddLoanObj = getFormDataValue(addLoanForm);
          const newAddPatientObj = getFormDataValue(addpatientField);
          const amount = Number(newAddLoanObj.hospitalDueAmount) + Number(newAddLoanObj.pharmacyDueAmount) + Number(newAddLoanObj.pathologyDueAmount);
          const { doa,relationWithPolicyHolder,tenure } = newAddLoanObj;

          const hospitalCode = hospitalData.find(({ value }) => newAddLoanObj.hospitalName === value);
          const pharmacyCode = pharmacyData && pharmacyData.length > 0 ? pharmacyData.find(({ value }) => newAddLoanObj.pharmacyName === value) : '';
          const pathologyCode = pathologyData && pathologyData.length > 0 ? pathologyData.find(({ value }) => newAddLoanObj.pathologyName === value) : '';

          const payload = { id: policyHolderId,data: { doa,relationWithPolicyHolder,tenure,amount ,loanDetail: {
            hospital: {
              code: hospitalCode.code,
              amount: Number(newAddLoanObj.hospitalDueAmount)
            },
            pharmacy: {
              code: (pharmacyCode && pharmacyCode !== '') ? pharmacyCode.code : '' ,
              amount: Number(newAddLoanObj.pharmacyDueAmount)
            },
            pathology: {
              code: (pathologyCode && pathologyCode !== '') ? pathologyCode.code : '',
              amount: Number(newAddLoanObj.pathologyDueAmount),
            }
          },patient: newAddPatientObj } };

          addpatientLoan(payload,handleReload,dispatch)
        }

      }
    }
    const [newAddLoanForm, isValid] = validateForm(addLoanForm);

    setAddLoanForm(newAddLoanForm);

    if (isValid) {
      const newAddLoanObj = getFormDataValue(addLoanForm);
      const amount = Number(newAddLoanObj.hospitalDueAmount) + Number(newAddLoanObj.pharmacyDueAmount) + Number(newAddLoanObj.pathologyDueAmount);
      const { doa,relationWithPolicyHolder,tenure } = newAddLoanObj;

      const hospitalCode = hospitalData.find(({ value }) => newAddLoanObj.hospitalName === value);
      const pharmacyCode = pharmacyData && pharmacyData.length > 0 ? pharmacyData.find(({ value }) => newAddLoanObj.pharmacyName === value) : '';
      const pathologyCode = pathologyData && pathologyData.length > 0 ? pathologyData.find(({ value }) => newAddLoanObj.pathologyName === value) : '';

      const payload = { id: policyHolderId,data: {
        doa,relationWithPolicyHolder,tenure,amount ,loanDetail: {
          hospital: {
            code: hospitalCode.code,
            amount: Number(newAddLoanObj.hospitalDueAmount)
          },
          pharmacy: {
            code: (pharmacyCode && pharmacyCode !== '') ? pharmacyCode.code : '' ,
            amount: Number(newAddLoanObj.pharmacyDueAmount)
          },
          pathology: {
            code: (pathologyCode && pathologyCode !== '') ? pathologyCode.code : '',
            amount: Number(newAddLoanObj.pathologyDueAmount),
          }
        } } };

      addpatientLoan(payload,handleReload,dispatch);
    }
  };

  if(success){
    closeaddLoanPopup();
  }

  const formValues = (name,value) => {
    const newAddLoanObj = getFormDataValue(addLoanForm);
    const hospitalDetail = hospitalData.find((item) => newAddLoanObj.hospitalName === item.value);
    const pharmacyDetail = pharmacyData && pharmacyData.length > 0 ? pharmacyData.find((item) => newAddLoanObj.pharmacyName === item.value) : '';
    const pathologyDetail = pathologyData && pathologyData.length > 0 ? pathologyData.find((item) => newAddLoanObj.pathologyName === item.value) : '';

    switch(true){
      case name === 'hospitalAccountNumber':
        return hospitalDetail ? hospitalDetail.accountNumber: '';
      case name === 'hospitalIfsc':
        return hospitalDetail ? hospitalDetail.ifsc: '';
      case name === 'pharmacyAccountNumber':
        return pharmacyDetail ? pharmacyDetail.accountNumber: '';
      case name === 'pharmacyIfsc':
        return pharmacyDetail ? pharmacyDetail.ifsc: '';
      case name === 'pathologyAccountNumber':
        return pathologyDetail ? pathologyDetail.accountNumber: '';
      case name === 'pathologyIfsc':
        return pathologyDetail ? pathologyDetail.ifsc: '';
      default:
        return value;
    }
  }

  return (
    <Modal
      show={addLoan}
      onHide={() => setAddLoan(false)}
      animation={false}
      className="delete-reason-popup"
    >
      <div className="common-image">
        <div className="common-img" onClick={() => setAddLoan(false)}>
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="editaddress-sec">
          <div className="editaddress-header">
            <h2>Add Loan</h2>
          </div>
          <div className="add-partner-form-container">
            {addLoanForm.map((formItem, index) => {
              return (
                <div className={`add-partner-form-item`} key={index}>
                  <FormElement
                    inputType={formItem.type}
                    lableName={formItem.label}
                    name={formItem.name}
                    placeholder={formItem.placeholder}
                    value={
                      formValues(formItem.name,formItem.value)
                    }
                    // value={formItem.value}
                    options={
                      populateOptions(formItem.name,formItem.options)
                    }
                    onChangeHandler={(targetValue) => onChangeHandler(addLoanForm, formItem.name, targetValue, setAddLoanForm) }
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={formItem.isDisabled}
                    // isDisabled={(formItem.name !== 'hospitalName' && pharmacyData.length > 0 && pathologyData.length > 0) && false }
                    isTouched={formItem.isTouched}
                  />
                </div>
              )
            })}
            {!isSelf && addpatientField.map((formItem, index) => {
              return (
                <div className={`add-partner-form-item`} key={index}>
                  <FormElement
                    inputType={formItem.type}
                    lableName={formItem.label}
                    name={formItem.name}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={
                      populateOptions(formItem.name,formItem.options)
                    }
                    onChangeHandler={(targetValue) => onChangeHandler(addpatientField, formItem.name, targetValue, setAddPatientFields) }
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={formItem.isDisabled}
                    isTouched={formItem.isTouched}
                  />
                </div>
              )
            })}
          </div>
          <div className="add-partner-button-wrapper">
            <Button click={ () => closeaddLoanPopup() } isGreyedOut={false} text={'Cancel'} />
            <Button click={handleAddLoan} isGreyedOut={false} text={'SUBMIT'} isMarginLeft={true} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddLoan;
