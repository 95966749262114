import {
  ADD_PATIENT_LOAN_FALIURE,
  ADD_PATIENT_LOAN_SUCCESS,
  ADD_PATIENT_LOAN_REQUEST,
} from './nucleon-profile-constants';

const addPatientLoanRequest = (data,addloanSuccessCallback) => ({
  data,
  addloanSuccessCallback,
  type: ADD_PATIENT_LOAN_REQUEST,
});

export const addPatientLoanSuccess = () => ({
  type: ADD_PATIENT_LOAN_SUCCESS,
});
export const addPatientLoanFailure = () => ({
  type: ADD_PATIENT_LOAN_FALIURE,
});

export const addpatientLoan = async (data,addloanSuccessCallback,dispatch) => {
  dispatch(addPatientLoanRequest(data,addloanSuccessCallback));
};
