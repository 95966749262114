import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { policyHolderAddressUrl } from '../../utils/urls';
import { POLICY_HOLDER_ADDRESS_REQUEST } from './nucleon-profile-constants';
import { policyHolderAddressSuccess, policyHolderAddressFailure } from './policy-holder-address-action';

const { getRequest } = new HttpHelper();

export function* policyHolderAddress(action) {
  try {
    const url = policyHolderAddressUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    yield put(policyHolderAddressSuccess(response.data));
  } catch (error) {
    yield put(policyHolderAddressFailure());
  }
}

export function* watchpolicyHolderAddress() {
  yield takeEvery(POLICY_HOLDER_ADDRESS_REQUEST, policyHolderAddress);
}
