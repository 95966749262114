/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { defaultFilters } from './defaults-constants';
import { defaults } from './defaults-action';
import { getDefaultReports } from './defaults-report-action'

import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateFormat,
  toNumberWithComma,
} from '../../utils/formattor';
import { loanDetailsExportUrl } from '../../utils/urls';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';

const Defaults = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getDefaultsData = (urlParams) => {
    defaults(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt']
    };

    getDefaultsData(urlParams);
    getDefaultReports(dispatch);
  }, []);

  const { defaultsDetails, totalRecords, isFetching } = useSelector(
    (state) => state.defaults
  );

  const { data } = useSelector(
    (state) => state.getDefaultReports
  )

  const collectionList =
    defaultsDetails &&
    defaultsDetails.map((defaultsData, index) => {
      const id = index + 1;

      return {
        amount: defaultsData.principalAmount,
        dueDate: defaultsData.dueDate,
        emiNumber: defaultsData.totalPaid || '-',
        id,
        mobileNumber: defaultsData.mobileNumber,
        name: defaultsData.name,
        number: defaultsData.number,
        publicId: defaultsData.publicId,
        status: defaultsData.status,
        userId: defaultsData.userId,
        loanId: defaultsData.loanId
      };
    });

  function buttonFormatter(cell) {
    if (cell === 'failed' || cell === 'unpaid') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'paid') {
      return <label className="label-status-paid">{cell}</label>;
    } else if (cell === 'Partial-Paid') {
      return <label className="status-partialpaid">{cell}</label>;
    } else if (cell === 'Payment-Initiated') {
      return <label className="label-status-initiated">{cell}</label>;
    }

    return '-';
  }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };

  function loanNumberHandle(cell, row) {
    return <Link to={`/loan/${row.loanId}`}>{cell || '-'}</Link>;
  }

  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '80',
    },
    {
      columnClassName: '',
      dataField: 'number',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'emiNumber',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'EMI No',
      width: '120',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'dueDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'EMI Due Date',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Value',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Defaults</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Report</Breadcrumb.Item>
                <Breadcrumb.Item active>Defaults</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
          <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{data && data.totalRiskAmount? toNumberWithComma(data.totalRiskAmount) :'0.00'}</h5>
                        <h3 className="heading-disbursement">Total Principal at risk</h3>
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">&#8377;{data && data.principalEmiZeroDays ? toNumberWithComma(data.principalEmiZeroDays):'0.00'}</h5>
                        <h3 className="heading-disbursement">Principal Of Emis Delayed By 0+ Days</h3>
                        </div>
                    </div>
                  </div>

                   <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{data &&
                          data.principalEmiThirtyDays ? toNumberWithComma(data.principalEmiThirtyDays):'0.00'}
                        </h5>
                        <h3 className="heading-disbursement">Principal of Emis Delayed By 30+ Days</h3>
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">&#8377;{data && data.principalEmiSixtyDays? toNumberWithComma(data.principalEmiSixtyDays):'0.00'}</h5>
                        <h3 className="heading-disbursement">Principal Of Emis Delayed By 60+ Days</h3>
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">&#8377;{data && data.principalEmiNintyDays? toNumberWithComma(data.principalEmiNintyDays):'0.00'}</h5>
                        <h3 className="heading-disbursement">Principal Of Emis Delayed By 90+ Days</h3>
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">&#8377;{data && data.principalEmiOneEightyDays? toNumberWithComma(data.principalEmiOneEightyDays):'0.00'}</h5>
                        <h3 className="heading-disbursement">Principal Of Emis Delayed By 180+ Days</h3>
                        </div>
                    </div>
                  </div>
              </div>
          <div className="today-emi-sec">
          <DataTable
              tableData={collectionList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getDefaultsData}
              exportUrl={loanDetailsExportUrl}
              tableTitle="DEFAULTS"
              pageFilters={defaultFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
            {/* <div className="col-md-12 mpad">
              <div className="table-title-sec">
                <h1>DEFAULTS</h1>
                <button
                  disabled={executePayment}
                  className="black-border-btn"
                  onClick={executePaymentHandle}
                >
                  Execute Payments
                </button>
              </div>
              {executePayment && (
                <div className="default-loader">
                  <div className="default-loader-sec">
                    <div className="default-loader-sec-loader">
                      <div className="loader"></div>
                    </div>
                    <p>Please wait! while payment executed process started.</p>
                  </div>
                  <span>
                    <img
                      alt="close icon"
                      src={CloseRoundIconGray}
                      onClick={() => setExecutePayment(false)}
                    />
                  </span>
                </div>
              )}
            </div> */}
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
      </div>
    </div>
  );
};

export default Defaults;
