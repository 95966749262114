/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { dashboard } from './dashboard-action';
import { dashboardCard } from './dashboard-card-action';
import { recentActivity } from './recent-activity-action';
import { dashboardReportsAction } from './dashboard-reports-actions';
import {
  dashboardFilters,
  userBlockFilter,
  loanBlockFilter,
} from './dashboard-constants';

import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import PageLoader from '../../components/Loaders/page-loader';
import CircularNewProgessbar from '../../components/dashboard/circular-progress-bar-layout';
import {
  toNumberWithCommaAndDecimal,
  toDateFormat,
  toNumberWithComma,
} from '../../utils/formattor';

import { getUserBlock } from './user-block-action';
import { getLoanBlock } from './loan-block-action';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { loanDetailsExportUrl } from '../../utils/urls';

import UsersIcon from '../../../images/icons/users.png';
import RupeesIcon from '../../../images/icons/rupees.png';
import SavingsIcon from '../../../images/icons/savings.png';
import PeoplesIcon from '../../../images/icons/peoples.png';
import BalanceIcon from '../../../images/icons/balance.png';

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [userBlockIndex, setUserBlockIndex] = useState(0);
  const [loanBlockIndex, setLoanBlockIndex] = useState(0);

  const dispatch = useDispatch();

  const getDashboardData = (urlParams) => {
    dashboard(urlParams, dispatch);
    if(urlParams['filters[0][key]'] === "createdAt" || urlParams['filters[0][key]'] === undefined){
      dashboardReportsAction(urlParams, dispatch);
    }else{
      const params = {
            pageNumber: CURRENT_DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            'sorting[0][direction]': ['DESC'],
            'sorting[0][key]': ['createdAt'],
          };

      dashboardReportsAction(params,dispatch);
    }
  };

  // const { dashboardCardDetails } = useSelector((state) => state.dashboardCard);
  const { isFetchUserBlock, totalUser, totalTestCaseUser } = useSelector(
    (state) => state.userBlock
  );
  const { dashboardDetailsData } = useSelector((state) => state.dashboardReportsReducer);

  const { isFetchLoanBlock, totalLoan, totalTestCaseLoan } = useSelector(
    (state) => state.loanBlock
  );

  useEffect(() => {
    setTimeout(() => setIsPageLoading(false), 1000);

    const pageNumberWithSortingParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    };

    dashboardCard(dispatch);
    recentActivity(pageNumberWithSortingParams, dispatch);
  }, []);

  useEffect(() => {
    const urlParamsForUserBlock = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      [`filters[0][${userBlockFilter[userBlockIndex].filterCondition}]`]: userBlockFilter[
        userBlockIndex
      ].filterOnValue,
      'filters[0][key]': userBlockFilter[userBlockIndex].value,
    };

    if(!userBlockFilter[userBlockIndex].filterCondition){
      delete urlParamsForUserBlock[`filters[0][${userBlockFilter[userBlockIndex].filterCondition}]`]
      delete urlParamsForUserBlock['filters[0][key]'];
    }
    getUserBlock(urlParamsForUserBlock, dispatch);
  }, [userBlockIndex]);

  useEffect(() => {
    const urlParamsForLoanBlock = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      [`filters[0][${loanBlockFilter[loanBlockIndex].filterCondition}]`]: loanBlockFilter[
        loanBlockIndex
      ].filterOnValue,
      'filters[0][key]': loanBlockFilter[loanBlockIndex].value,
    };

    getLoanBlock(urlParamsForLoanBlock, dispatch);
  }, [loanBlockIndex]);

  const { dashboardDetails, totalRecords, isFetching } = useSelector(
    (state) => state.dashboard
  );

  const dashboardList =
    dashboardDetails &&
    dashboardDetails.map((dashboardData, index) => {
      const id = index + 1;

      return {
        emiNumber: '-',
        id,
        ...dashboardData,
      };
    });

  const addClassCallBack = () => {
    setShow(!show);
  };

  // Functions will be receiving params: cell, row, rowIdx.
  function buttonFormatter(cell) {
    if (cell === 'paid') {
      return <label className="label-status-paid">{cell}</label>;
    } else if (cell === 'unpaid' || cell === 'failed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'Partial-Paid') {
      return <label className="status-partialpaid">{cell}</label>;
    } else if (cell === 'Payment-Initiated') {
      return <label className="label-status-initiated">{cell}</label>;
    }
    return '-';
  }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };

  function userLoanNo(cell, row) {
    return <Link to={`/loan/${row.loanId}`}>{cell}</Link>;
  }
  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }
  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  const nextUserBlockDetails = () => {
    if (userBlockIndex < userBlockFilter.length-1) {
      setUserBlockIndex(userBlockIndex + 1);
    }
  };
  const preUserBlockDetails = () => {
    if (userBlockIndex > 0) {
      setUserBlockIndex(userBlockIndex - 1);
    }
  };

  const nextLoanBlockDetails = () => {
    if (loanBlockIndex < loanBlockFilter.length-1) {
      setLoanBlockIndex(loanBlockIndex + 1);
    }
  };
  const preLoanBlockDetails = () => {
    if (loanBlockIndex > 0) {
      setLoanBlockIndex(loanBlockIndex - 1);
    }
  };
  // const { users } = dashboardCardDetails;

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '80',
    },
    {
      columnClassName: '',
      dataField: 'number',
      dataFormat: userLoanNo,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'emiNumber',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'EMI No',
      width: '120',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'dueDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'EMI Due Date',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'dueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Value',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
  ];

  return (
    <div>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Fragment>
          <Header />
          <div className="common-container">
            <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
            <div className={`common-wrapper ${show ? 'active' : ''} `}>
              <div className="col-md-12 mpad">
                <div className="common-heading">
                  <h1>Dashboard</h1>
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
              <div className='row mb-3' style={{ justifyContent: 'space-evenly' }}>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{dashboardDetailsData && dashboardDetailsData.totalUsers}</h5>
                        <h3 className="heading-disbursement">Total Users</h3>
                        <p className='para-disbursement'>No of total users in our system</p>
                        <img src={PeoplesIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{dashboardDetailsData && dashboardDetailsData.activeLoans}</h5>
                        <h3 className="heading-disbursement">Active Loan</h3>
                        <p className='para-disbursement'>No of Active loans in our system</p>
                        <img src={UsersIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{dashboardDetailsData && dashboardDetailsData.closedLoans}</h5>
                        <h3 className="heading-disbursement">Closed Loan</h3>
                        <p className='para-disbursement'>No of closed loans in our system</p>
                        <img src={UsersIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{dashboardDetailsData && dashboardDetailsData.TotalLoans}</h5>
                        <h3 className="heading-disbursement">Total Loan</h3>
                        <p className='para-disbursement'>No of loans in our system</p>
                        <img src={UsersIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{dashboardDetailsData &&
                          (dashboardDetailsData.processingAmount ? toNumberWithComma(dashboardDetailsData.processingAmount) : 0)}
                          </h5>
                        <h3 className="heading-disbursement">Total Processing Amount</h3>
                        <p className='para-disbursement'>Total Processing Amount Taken</p>
                        <img src={UsersIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{dashboardDetailsData &&
                          (dashboardDetailsData.bounceChargesAccured ? toNumberWithComma(dashboardDetailsData.bounceChargesAccured) : 0)}
                        </h5>
                        <h3 className="heading-disbursement">Total Bounce Charges</h3>
                        <p className='para-disbursement'>Total Bounce Charges Taken</p>
                        <img src={UsersIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-3 mt-3' style={{ justifyContent: 'space-evenly' }}>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{dashboardDetailsData &&
                          (dashboardDetailsData.penalAmountAccured !== null ? toNumberWithComma(dashboardDetailsData.penalAmountAccured) : 0)}
                        </h5>
                        <h3 className="heading-disbursement">Total Penal Charges</h3>
                        <p className='para-disbursement'>Total Penal Charges Taken</p>
                        <img src={UsersIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{dashboardDetailsData &&
                          (dashboardDetailsData.netInterestAmount !== null ? toNumberWithComma(dashboardDetailsData.netInterestAmount) : 0)}
                        </h5>
                        <h3 className="heading-disbursement">Total Net Interest Amount</h3>
                        <p className='para-disbursement'>Total Net Interest Amount Till Date in our system</p>
                        <img src={UsersIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{dashboardDetailsData &&
                          (dashboardDetailsData.TotalProfit !== null ? toNumberWithComma(dashboardDetailsData.TotalProfit) : 0)}
                        </h5>
                        <h3 className="heading-disbursement">Total Net Profit</h3>
                        <p className='para-disbursement'>Total Net Profit Till Date in our system</p>
                        <img src={UsersIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{dashboardDetailsData &&
                          (dashboardDetailsData.principalDisbursed !== null ? toNumberWithComma(dashboardDetailsData.principalDisbursed) : 0)}
                        </h5>
                        <h3 className="heading-disbursement">Principal Disbursed Amount</h3>
                        <img src={RupeesIcon} alt='placeholder' />
                        <p className='para-disbursement'>Total Principal Disbursed Amount</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{dashboardDetailsData &&
                          (dashboardDetailsData.balanceDueAmount !== null ? toNumberWithComma(dashboardDetailsData.balanceDueAmount) : 0)}
                        </h5>
                        <h3 className="heading-disbursement">Total Balance Due</h3>
                        <p className='para-disbursement'>Total Balance Due Amount</p>
                        <img src={BalanceIcon} alt='placeholder' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip-dashboard">
                      <div className="chip-body">
                        <h5 className="num-disbursement">
                          &#8377;{dashboardDetailsData &&
                          (dashboardDetailsData.totalPrincipalCollected !== null ? toNumberWithComma(dashboardDetailsData.totalPrincipalCollected) : 0)}
                        </h5>
                        <h3 className="heading-disbursement">Total Principal Amount Collected</h3>
                        <img src={SavingsIcon} alt='placeholder' />
                        <p className="para-disbursement">Total Principal Amount Collected in system till date</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="dashboar-user-loan-progressive-layout">
                <CircularNewProgessbar
                  title={'User Report'}
                  testCaseTotal={totalTestCaseUser}
                  textToDisplay={userBlockFilter[userBlockIndex].text}
                  total={totalUser}
                  name={'Total user'}
                  link={userBlockFilter[userBlockIndex].link}
                  isFetching={isFetchUserBlock}
                  next={nextUserBlockDetails}
                  pre={preUserBlockDetails}
                />
                <CircularNewProgessbar
                  title={'Loan Report'}
                  testCaseTotal={totalTestCaseLoan}
                  textToDisplay={loanBlockFilter[loanBlockIndex].text}
                  total={totalLoan}
                  name={'Total loan'}
                  link={loanBlockFilter[loanBlockIndex].link}
                  isFetching={isFetchLoanBlock}
                  next={nextLoanBlockDetails}
                  pre={preLoanBlockDetails}
                />
              </div>
              <div className="today-emi-sec">
                <DataTable
                  tableData={dashboardList}
                  tableHeaderData={tableHeaderData}
                  totalRecords={totalRecords}
                  isFetching={isFetching}
                  getTableData={getDashboardData}
                  exportUrl={loanDetailsExportUrl}
                  tableTitle="TODAY’S EMI"
                  pageFilters={dashboardFilters}
                  defaultSortKey={'createdAt'}
                  defaultSortDirection={'desc'}
                />
              </div>
            </div>
            <Footer show={show} />
            <div className={`common-side-bar ${show ? 'active' : ''} `}>
              <SideBar addClassCallBack={addClassCallBack} show={show} />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Dashboard;
