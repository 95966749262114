import { all } from 'redux-saga/effects';

import { watchManagePincode } from '../containers/manage-pincode/manage-pincode-saga';
import { watchManageUsers } from '../containers/manage-users/manage-users-saga';
import { watchManageProfession } from '../containers/manage-profession/manage-profession-saga';
import { watchManageBank } from '../containers/manage-bank/manage-bank-saga';
import { watchprofileDetails } from '../containers/profile/profile-details-saga';
import { watchSignin } from '../containers/sign-in/signin-saga';
import { watchchangePassword } from '../containers/change-password/change-password-saga';
import { watchRoleList } from '../containers/manage-users/role-list-saga';
import { watchaddUser } from '../containers/manage-users/add-user-saga';
import { watchAddCallLogs } from '../containers/profile/add-call-logs-saga';
import { watchEditUser } from '../containers/manage-users/edit-user-saga';
import { watchGeneratePassword } from '../containers/manage-users/generate-password-saga';
import { watchEditBank } from '../containers/manage-bank/edit-bank-saga';
import { watchBankDetails } from '../containers/manage-bank/bank-details-saga';
import { watchuserAddress } from '../containers/profile/user-address-saga';
import { watchStateList } from '../containers/profile/state-list-saga';
import { watchAddAddress } from '../containers/profile/address-saga';
import { watchEditPincode } from '../containers/manage-pincode/edit-pincode-saga';
import { watchResetPassword } from '../containers/reset-password/reset-password-saga';
import { watchFileUpload } from '../containers/profile/file-upload-saga';
import { watchCallLogs } from '../containers/profile/call-logs-saga';
import { watchUserProfile } from '../containers/profile/user-profile-saga';
import { watchEmi } from '../containers/emi/emi-saga';
import { watchEmiChip } from '../containers/emi/emi-chip-saga';
import { watchLoan } from '../containers/loan/loan-saga';
import { watchLeadsLoan  } from '../containers/leads-loan/loan-saga';
import { watchLoanInfo } from '../containers/loan-information/loan-information-saga';
import { watchDisburseLoanNow } from '../containers/loan-information/disbuse-loan-saga';
import { watchEmandateNow } from '../containers/loan-information/emandate-saga';
import { watchCollection } from '../containers/collections/collections-saga';
import { watchCollectionChip } from '../containers/collections/collections-chip-saga';
import { watchDefaults } from '../containers/defaults/defaults-saga';
import { watchPayments } from '../containers/payments/payments-saga';
import { watchDisbursment } from '../containers/disbursment/disbursment-saga';
import { watchDisbursmentChip } from '../containers/disbursment/disbursment-chip-saga';
import { watchDashboard } from '../containers/dashboard/dashboard-saga';
import { watchRecentActivity } from '../containers/dashboard/recent-activity-saga';
import { watchNewRegistration } from '../containers/dashboard/new-registration-saga';
import { watchExportCsv } from '../containers/dashboard/export-csv-saga';
import { watchDashboardCard } from '../containers/dashboard/dashboard-card-saga';
import { watchUploadDocs } from '../containers/profile/upload-docs-saga';
import { watchgurantorUploadDocs } from '../containers/profile/gurantor-upload-docs-saga';
import { watchBlockUser } from '../containers/profile/block-unblock-saga';
import { watchViewFile } from '../containers/dashboard/view-file-saga';
import { watchPaymentDetails } from '../components/side-bar/payment-details-saga';
import { watchPayment } from '../containers/loan-information/loan-payment/payment-sega';
import { watchManagePartner } from '../containers/manage-partner/manage-partner-saga';
import { watchUsers } from '../containers/users/users-saga';
import { watchViewPartner } from '../containers/view-partner/view-partner-saga';
import { watchAddBank } from '../containers/profile/bank-saga';
import { watchUserBlock } from '../containers/dashboard/user-block-sega';
import { watchLoanBlock } from '../containers/dashboard/loan-block-sega';
import { watchLoanActions } from '../containers/loan-information/loan-actions/loan-actions-saga';
import { watchPaymentsLoan } from '../containers/loan-information/payment-details/payments-saga';
import { watchIncreaseValidity } from '../containers/profile/increase-validity-saga';
import { watchGetCallLogsReports } from '../containers/users/get-daily-reports-saga';
import { watchDashboardReports } from '../containers/dashboard/dashboard-reports-saga';
import { watchGetDefaultReports } from '../containers/defaults/defaults-report-saga';
import { watchuserAddressLocation } from '../containers/profile/user-address-location-saga';
import { watchuserSimDetails } from '../containers/profile/user-sim-details-saga';
import { watchpartnerList } from '../containers/loan/get-partner-saga';
import { watchLoanCall } from '../containers/loan-information/loan-call-log-saga';
import { watchHospitalList } from '../containers/neuclon-profile/hospital-list-saga';
import { watchHospitalDetails } from '../containers/neuclon-profile/hospital-details-saga';
import { watchAddHealthCareUser } from '../containers/neucleon/add-neuclon-user/add-health-care-user-saga';
import { watchGetPolicyHolders } from '../containers/neucleon/get-policy-holders-saga';
import { watchPolicyDetails } from '../containers/neuclon-profile/policy-details-saga';
import { watchfileUploadPolicyHolderDocsFile } from '../containers/neuclon-profile/file-upload-policy-holder-docs-saga';
import { watchuploadDocsPolicyHolder } from '../containers/neuclon-profile/upload-docs-policy-holder-saga';
import { watchpolicyHolderAddress } from '../containers/neuclon-profile/policy-holder-address-saga';
import { watchaddPolicyHolderAddress } from '../containers/neuclon-profile/add-policy-holder-address-saga';
import { watchaddpatientLoan } from '../containers/neuclon-profile/add-patient-loan-saga';
import { watchhealthCareLoan } from '../containers/health-care-loan/health-care-loan-saga';
import { watchhealthCareLoanInfo } from '../containers/health-care-loan-info/health-care-loan-info-saga';
import { watchsendEmailToLender } from '../containers/send-email-to-lender/send-email-to-lender-saga';
import { watchSendGeneratedLeads } from '../containers/send-email-to-lender/send-generated-leads-saga';
import { watchHealthCareLenderList } from '../containers/neuclon-profile/partner-list-saga';
import { watchgenerateloanLead } from '../containers/neuclon-profile/generate-loan-lead-saga';
import { watchsentutrs } from '../containers/sent-utrs/sent-utrs-saga';
import { watchUpdateUtr } from '../containers/sent-utrs/update-utr-saga';
import { watchLoanDpd } from '../containers/loan-dpd/loan-dpd-saga';
import { watchDailyReport } from '../containers/daily-report/daily-report-saga';
import { watchDeleteKyc } from '../containers/profile/delete-kyc-saga';
import { watchGetProduct } from '../containers/manage-product/get-product-saga';
import { watchEditProduct } from '../containers/manage-product/edit-product-saga';

const sagas = function* sagas() {
  yield all([
    watchManagePincode(),
    watchManageUsers(),
    watchManageProfession(),
    watchManageBank(),
    watchprofileDetails(),
    watchSignin(),
    watchchangePassword(),
    watchRoleList(),
    watchaddUser(),
    watchEditUser(),
    watchGeneratePassword(),
    watchEditBank(),
    watchBankDetails(),
    watchuserAddress(),
    watchStateList(),
    watchAddAddress(),
    watchAddCallLogs(),
    watchCallLogs(),
    watchEditPincode(),
    watchResetPassword(),
    watchFileUpload(),
    watchEmi(),
    watchEmiChip(),
    watchLoan(),
    watchLeadsLoan(),
    watchLoanInfo(),
    watchCollection(),
    watchCollectionChip(),
    watchDefaults(),
    watchPayments(),
    watchDisbursment(),
    watchDisbursmentChip(),
    watchDashboard(),
    watchRecentActivity(),
    watchNewRegistration(),
    watchExportCsv(),
    watchDashboardCard(),
    watchUploadDocs(),
    watchgurantorUploadDocs(),
    watchBlockUser(),
    watchUserProfile(),
    watchViewFile(),
    watchPaymentDetails(),
    watchPayment(),
    watchManagePartner(),
    watchViewPartner(),
    watchDisburseLoanNow(),
    watchUsers(),
    watchEmandateNow(),
    watchAddBank(),
    watchUserBlock(),
    watchLoanBlock(),
    watchLoanActions(),
    watchPaymentsLoan(),
    watchIncreaseValidity(),
    watchGetCallLogsReports(),
    watchDashboardReports(),
    watchGetDefaultReports(),
    watchuserAddressLocation(),
    watchuserSimDetails(),
    watchpartnerList(),
    watchLoanCall(),
    watchHospitalList(),
    watchHospitalDetails(),
    watchAddHealthCareUser(),
    watchGetPolicyHolders(),
    watchPolicyDetails(),
    watchfileUploadPolicyHolderDocsFile(),
    watchuploadDocsPolicyHolder(),
    watchpolicyHolderAddress(),
    watchaddPolicyHolderAddress(),
    watchaddpatientLoan(),
    watchhealthCareLoan(),
    watchhealthCareLoanInfo(),
    watchsendEmailToLender(),
    watchSendGeneratedLeads(),
    watchHealthCareLenderList(),
    watchgenerateloanLead(),
    watchsentutrs(),
    watchUpdateUtr(),
    watchLoanDpd(),
    watchDailyReport(),
    watchDeleteKyc(),
    watchGetProduct(),
    watchEditProduct(),
  ]);
};

export default sagas;
