/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';
import moment from 'moment';
// import { useSelector, useDispatch } from 'react-redux';

import { managePartnerFilter } from './nucleon-constants';
import { getPolicyHolders } from './get-policy-holders-action';
// import { managePartner } from './manage-partner-action';

import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import Button from '../../components/form-elements/button';
import { setDefaultFilter } from '../../utils/default-filter-helper';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { partnerExportUrl } from '../../utils/urls';
import './neucleon.css';

const NeucleonList = ({ location }) => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

//   const getPartnerData = (urlParams) => {
//     managePartner(urlParams, dispatch);
//   };
const { isFetching, policyHolders, totalRecords } = useSelector((state) => state.getPolicyHolders);

const policyMemberList =
  policyHolders &&
  policyHolders.map((user, index) => {
      const id = index + 1;

      return {
        ...user,
        id,
      };
    });

//   const { partnerDetails, totalRecords, isFetching } = useSelector(
//     (state) => state.managePartner
//   );
//   const partnerList =
//   partnerDetails &&
//   partnerDetails.map((partnerData, index) => {
//       const id = index + 1;

//       return {
//         name: partnerData.name,
//         publicId: partnerData.publicId,
//         id,
//         code: partnerData.code,
//         logoUrl: partnerData.logoUrl,
//         description: partnerData.description,
//         tags: partnerData.tags,
//         status: partnerData.status
//       };
//     });

  function partnerNameLinkHandler(cell, row) {
    return <Link to={`/policy-holder/${row.publicId}`}><img src={row.logoUrl} height={26}/>{row.firstName} {row.lastName}</Link>;
  }

  function policyNumberLinkHandler(cell, row) {
    return <Link to={`/policy-holder/${row.publicId}`}><img src={row.logoUrl} height={26}/>{row.policyNumber}</Link>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  function dateTimeFormatter(cell) {
    return <label>{cell?moment(cell).format('DD-MM-YYYY hh:mm:ss'):'-'}</label>;
  }

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'name',
      dataFormat: partnerNameLinkHandler,
      dataSort: true,
      isKey: false,
      name: 'Borrower Name',
      width: '250',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'policyNumber',
      dataFormat: policyNumberLinkHandler,
      dataSort: true,
      isKey: false,
      name: 'Policy Number',
      width: '250',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: (cell)=>cell,
      dataSort: true,
      isKey: false,
      name: 'Mobile Number',
      width: '180',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'email',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: false,
      name: 'Email',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'panNumber',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'PAN Number',
      width: '200',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'aadhaarNumber',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'Aadhar number',
      width: '200',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'gender',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'Gender',
      width: '200',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'createdAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Created At',
      width: '120',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'updatedAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Updated At',
      width: '120',
    },
  ];

  const getUsersData = (urlParams) => {
    getPolicyHolders(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getUsersData(urlParams);
      }
      setDefaultFilter(managePartnerFilter, value, filterOnValue);
    } else {
      getUsersData(urlParams);
    }
  }, []);

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>HEALTH CARE</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Policy Holders</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="manage-partner-list-container">
          <span className="add-partner-btn-wrapper"><Link to={'add-policy-holder'}><Button click={()=>{}} text={"ADD POLICY HOLDER"}/></Link></span>
            <DataTable
              tableData={policyMemberList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getUsersData}
              exportUrl={partnerExportUrl}
              tableTitle=""
              pageFilters={managePartnerFilter}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
      </div>
    </div>
  );
};

export default NeucleonList;
