import {
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
} from './manage-product-constants';

const editProductRequest = (
  userDetails,
  productSettingId,
  concurrencyStamp,
  userSuccessCallback
) => ({
  concurrencyStamp,
  productSettingId,
  type: EDIT_PRODUCT_REQUEST,
  userDetails,
  userSuccessCallback,
});

export const editProductSuccess = () => ({
  type: EDIT_PRODUCT_SUCCESS,
});

export const editProductFailure = () => ({
  type: EDIT_PRODUCT_FAILURE,
});

export const editProduct = (
  userDetails,
  productSettingId,
  concurrencyStamp,
  userSuccessCallback,
  dispatch
) => {
  dispatch(
    editProductRequest(
      userDetails,
      productSettingId,
      concurrencyStamp,
      userSuccessCallback
    )
  );
};
