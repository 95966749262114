import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { healthCareLoanInfoUrl } from '../../utils/urls';
import { HEALTH_CARE_LOAN_INFO_REQUEST } from './health-care-loan-info-constants';
import { healthCareLoanInfoSuccess, healthCareLoanInfoFailure } from './health-care-loan-info-action';

const { getRequest } = new HttpHelper();

export function* healthCareLoanInfo(action) {
  try {
    const url = healthCareLoanInfoUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    yield put(healthCareLoanInfoSuccess(response.data));
  } catch (error) {
    yield put(healthCareLoanInfoFailure());
  }
}

export function* watchhealthCareLoanInfo() {
  yield takeEvery(HEALTH_CARE_LOAN_INFO_REQUEST, healthCareLoanInfo);
}
