import { SELECT } from '../../../constants/data-table';
import {
  TEXT,
  DATE
} from '../../../constants/form-type';

export const ADD_HEALTH_USER_FALIURE = 'ADD_HEALTH_USER_FALIURE';
export const ADD_HEALTH_USER_SUCCESS = 'ADD_HEALTH_USER_SUCCESS';
export const ADD_HEALTH_USER_REQUEST = 'ADD_HEALTH_USER_REQUEST';

export const addPartnerFormFields = [
  {
    label: 'Title',
    name: 'title',
    placeholder: 'Select Title',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    options: [
      { name: 'mr.', value: 'Mr.' },
      { name: 'mrs.', value: 'Mrs.' },
    ],
    type: SELECT,
  },
  {
    label: 'First Name',
    name: 'firstName',
    placeholder: 'Enter First Name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Enter Last Name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Gender',
    name: 'gender',
    placeholder: 'Select Gender',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    options: [
      { name: 'Male', value: 'male' },
      { name: 'Female', value: 'female' },
      { name: 'Other', value: 'other' },
    ],
    type: SELECT,
  },
  {
    label: 'Date of Birth',
    name: 'dob',
    placeholder: 'Select Date of Birth',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: DATE,
  },
  {
    label: 'Father Name(as on PAN)',
    name: 'fatherName',
    placeholder: 'Enter Father Name',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Phone Number',
    name: 'mobileNumber',
    placeholder: 'Enter Phone Number',
    validation: { isRequired: true, isEmail: false, isMobile: true },
    type: TEXT,
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Enter Email Address',
    validation: { isRequired: true, isEmail: true, isMobile: false },
    type: TEXT,
  },
  {
    label: 'PAN number',
    name: 'panNumber',
    placeholder: 'Enter Pan Number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Aadhaar Number',
    name: 'aadhaarNumber',
    placeholder: 'Enter Aadhar Number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Policy Number',
    name: 'policyNumber',
    placeholder: 'Enter Policy Number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: TEXT,
  },
  {
    label: 'Policy Start Date',
    name: 'policyStartDate',
    placeholder: 'Enter Policy Number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: DATE,
  },
  {
    label: 'Policy End Date',
    name: 'policyExpiryDate',
    placeholder: 'Enter Policy Number',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: DATE,
  },
];
