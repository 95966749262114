/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import markerMapIcon from '../../../images/icons/markerMapIcon.svg';
import './field-key-value.css';
import {
  TEXT,
  TEXT_MAPICON,
  IMAGE,
  URL,
  VERIFY_TEXT,
  VERIFYIED_STATUS,
  VERIFYIED_STATUS_2,
  VERIFYIED_STATUS_3,
  DATE,
  TEXT_EDITABLE_SELECT_BOX,
} from '../../constants/field-value-key';
import { toDateFormat, toCapitalizeFirstLetter } from '../../utils/formattor';
import FormElement from '../form-elements';
import { SELECT } from '../../constants/form-type';
import Button from '../form-elements/button';

const FieldValue = ({
  fieldName,
  fieldValue,
  type,
  verifyStatus,
  options,
  valueForForm,
  onChangeHandler,
  onSubmitHandler,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const getFieldValue = (typeParam) => {
    switch (typeParam) {
      case TEXT:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">{fieldValue || '-'}</div>
          </div>
        );
      case TEXT_MAPICON:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">{fieldValue || '-'} {fieldValue ? <img alt="show" src={markerMapIcon} onClick ={() => {onSubmitHandler();}}/> :''}</div>
            <div className="edit-button-wrapper">
            </div>
          </div>
        );
      case IMAGE:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              <img src={fieldValue} alt={fieldName} height={40}></img>
            </div>
          </div>
        );
      case URL:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              <a href={fieldValue}>{fieldValue || '-'}</a>
            </div>
          </div>
        );
      case VERIFY_TEXT:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              {verifyStatus === VERIFYIED_STATUS || verifyStatus === VERIFYIED_STATUS_2 || verifyStatus === VERIFYIED_STATUS_3 ? (
                <CheckCircleIcon className="check-circle-green" />
              ) : (
                <CancelIcon className="check-cancel-red" />
              )}{' '}
              {fieldValue || '-'}
            </div>
          </div>
        );
      case TEXT_EDITABLE_SELECT_BOX:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              {!isEdit ? (
                valueForForm ? (
                  toCapitalizeFirstLetter(valueForForm)
                ) : (
                  toCapitalizeFirstLetter(fieldValue) || '-'
                )
              ) : (
                <div className="select-form-wrapper">
                  <FormElement
                    inputType={SELECT}
                    name=""
                    placeholder={fieldName}
                    value={valueForForm}
                    onChangeHandler={onChangeHandler}
                    options={options}
                  />
                </div>
              )}
            </div>
            <div className="edit-button-wrapper">
              <Button
                text={isEdit ? 'Done' : 'Edit'}
                click={() => {
                  setIsEdit(!isEdit);
                }}
              />
            </div>
          </div>
        );
      case DATE:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">
              {(fieldValue && toDateFormat(fieldValue)) || '-'}
            </div>
          </div>
        );
      default:
        return (
          <div className="field-value-wrapper">
            <div className="field-name">{fieldName}</div>
            <div className="field-value">{fieldValue || '-'}</div>
          </div>
        );
    }
  };

  return <React.Fragment>{getFieldValue(type)}</React.Fragment>;
};

export default FieldValue;
