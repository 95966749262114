import { HEALTH_CARE_LOAN_FAILURE, HEALTH_CARE_LOAN_REQUEST, HEALTH_CARE_LOAN_SUCCESS } from './health-care-loan-constants';

const healthCareLoanRequest = (urlParams) => ({
  type: HEALTH_CARE_LOAN_REQUEST,
  urlParams,
});

export const healthCareLoanSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: HEALTH_CARE_LOAN_SUCCESS,
});

export const healthCareLoanFailure = () => ({
  type: HEALTH_CARE_LOAN_FAILURE,
});

export const healthCareLoan = async (urlParams, dispatch) => {
  dispatch(healthCareLoanRequest(urlParams));
};
