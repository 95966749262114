export const HOSPITAL_DETAILS_REQUEST = 'HOSPITAL_DETAILS_REQUEST';
export const HOSPITAL_DETAILS_SUCCESS = 'HOSPITAL_DETAILS_SUCCESS';
export const HOSPITAL_DETAILS_FAILURE = 'HOSPITAL_DETAILS_FAILURE';

export const HOSPITAL_LIST_REQUEST = 'HOSPITAL_LIST_REQUEST';

export const POLICY_DETAILS_REQUEST = 'POLICY_DETAILS_REQUEST';
export const POLICY_DETAILS_SUCCESS = 'POLICY_DETAILS_SUCCESS';
export const POLICY_DETAILS_FAILURE = 'POLICY_DETAILS_FAILURE';

export const POLICY_HOLDER_ADDRESS_REQUEST = 'POLICY_HOLDER_ADDRESS_REQUEST';
export const POLICY_HOLDER_ADDRESS_SUCCESS = 'POLICY_HOLDER_ADDRESS_SUCCESS';
export const POLICY_HOLDER_ADDRESS_FAILURE = 'POLICY_HOLDER_ADDRESS_FAILURE';

export const ADD_POLICY_HOLDER_ADDRESS_REQUEST  = 'ADD_POLICY_HOLDER_ADDRESS_REQUEST';
export const ADD_POLICY_HOLDER_ADDRESS_SUCCESS  = 'ADD_POLICY_HOLDER_ADDRESS_SUCCESS';
export const ADD_POLICY_HOLDER_ADDRESS_FAILURE  = 'ADD_POLICY_HOLDER_ADDRESS_FAILURE';
export const EDIT_POLICY_HOLDER_ADDRESS_REQUEST = 'EDIT_POLICY_HOLDER_ADDRESS_REQUEST';

export const UPLOAD_DOCS_POLICY_HOLDER_REQUEST = 'UPLOAD_DOCS_POLICY_HOLDER_REQUEST';
export const UPLOAD_DOCS_POLICY_HOLDER_SUCCESS = 'UPLOAD_DOCS_POLICY_HOLDER_SUCCESS';
export const UPLOAD_DOCS_POLICY_HOLDER_FAILURE = 'UPLOAD_DOCS_POLICY_HOLDER_FAILURE';

export const FILE_UPLOAD_POLICY_HOLDER_REQUEST = 'FILE_UPLOAD_POLICY_HOLDER_REQUEST';

export const ADD_PATIENT_LOAN_FALIURE = 'ADD_PATIENT_LOAN_FALIURE';
export const ADD_PATIENT_LOAN_SUCCESS = 'ADD_PATIENT_LOAN_SUCCESS';
export const ADD_PATIENT_LOAN_REQUEST = 'ADD_PATIENT_LOAN_REQUEST';

export const HEALTH_CARE_LENDER_LIST_REQUEST = 'HEALTH_CARE_LENDER_LIST_REQUEST';

export const GENERATE_LOAN_LEAD_FALIURE = 'GENERATE_LOAN_LEAD_FALIURE';
export const GENERATE_LOAN_LEAD_SUCCESS = 'GENERATE_LOAN_LEAD_SUCCESS';
export const GENERATE_LOAN_LEAD_REQUEST = 'GENERATE_LOAN_LEAD_REQUEST';
