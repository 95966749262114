export const ADD_HOSPITAL_REQUEST = 'ADD_HOSPITAL_REQUEST';
export const ADD_HOSPITAL_SUCCESS = 'ADD_HOSPITAL_SUCCESS';
export const ADD_HOSPITAL_FAILURE = 'ADD_HOSPITAL_FAILURE';

export const HOSPITAL_LIST_REQUEST = 'HOSPITAL_LIST_REQUEST';

export const GET_POLICY_HOLDERS_REQUEST = 'GET_POLICY_HOLDERS_REQUEST';
export const GET_POLICY_HOLDERS_SUCCESS = 'GET_POLICY_HOLDERS_SUCCESS';
export const GET_POLICY_HOLDERS_FAILURE = 'GET_POLICY_HOLDERS_FAILURE';

export const managePartnerFilter = [
  {
    name: 'Policy Number',
    value: 'policyNumber',
    filterCondition: 'eq',
    placeholder: 'Enter Policy name',
    type: 'text',
  },
  {
    name: 'Aadhaar Number',
    value: 'aadhaarNmber',
    filterCondition: 'eq',
    placeholder: 'Enter Aadhaar Number',
    type: 'text',
  },
  {
    name: 'Pan Number',
    value: 'panNumber',
    filterCondition: 'eq',
    placeholder: 'Enter Pan Number',
    type: 'text',
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: 'Enter Mobile Number',
    type: 'text',
  }
];
