import {
  HOSPITAL_DETAILS_REQUEST,
  HOSPITAL_DETAILS_SUCCESS,
  HOSPITAL_DETAILS_FAILURE,
} from './nucleon-profile-constants';

const initialState = {
  error: false,
  isFetching: false,
  hospitalData: {},
};

const hospitalDetailsData = (state = initialState, action) => {
  switch (action.type) {
    case HOSPITAL_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case HOSPITAL_DETAILS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case HOSPITAL_DETAILS_SUCCESS:
      return {
        error: false,
        isFetching: false,
        hospitalData: action.data,
      };
    default:
      return state;
  }
};

export default hospitalDetailsData;
