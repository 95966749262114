import { SELECT } from '../../../constants/form-type';

export const fileUploadFormForFileType = [
  {
    label: 'Document Type',
    name: 'docType',
    placeholder: '',
    options: [
      { name: 'Aadhaar Card', value: 'aadharCard' },
      { name: 'Pan Card', value: 'panCard' },
      { name: 'Policy Document', value: 'policyDocument' },
    ],
    validation: {},
    type: SELECT,
  }
]
export const uploadFileFormConst = {
  aadharCard: {
    frontPicKey: 'aadharFrontDocumentId',
    backPicKey: 'aadharBackDocumentId',
    documentKeyName: 'aadharNumber',
    markedDocmentStautsKey: '',
    value: [],
    isMarkValid: false
  },
  panCard: {
    frontPicKey: 'panFrontDocumentId',
    backPicKey: 'panBackDocumentId',
    documentKeyName: 'panNumber',
    markedDocmentStautsKey: '',
    value: [],
    isMarkValid: false
  },
  policyDocument: {
    frontPicKey: '',
    backPicKey: '',
    documentKeyName: 'policyDocumentId',
    markedDocmentStautsKey: '',
    value: [],
    isMarkValid: false
  },
}