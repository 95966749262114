import { TEXT } from '../../../constants/field-value-key';

export const preApprovedAmountDetails = [
  {
    label: "CIBIL SCORE",
    key: "score",
    type: TEXT,
  },
  {
    label: "DPD in 12 Months",
    key: "dpdInLast12Months",
    type: TEXT,
  },
  {
    label: "DPD in 6 Months",
    key: "dpdInLast6Months",
    type: TEXT,
  },
  {
    label: "Current OverDue Value",
    key: "currentOverdueValue",
    type: TEXT,
  },
  {
    label: "Current Balance",
    key: "currentBalance",
    type: TEXT,
  },
  {
    label: "Credit Limit",
    key: "creditLimit",
    type: TEXT,
  },
]