import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { increaseValidityUrl } from '../../utils/urls';
import { INCREASE_VALIDITY_REQUEST } from './profile-constants';
import { increaseVaildityFailure, increaseVailditySuccess } from './increase-validity-action';

const { patchRequest } = new HttpHelper();

export function* increaseValidity(action) {
  try {
    const url = increaseValidityUrl(action.id)
    const response = yield call(patchRequest, {
      headers: {
        'Content-Type': 'application/json'
      },
      url,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(
        `${data.details[0].name} : ${data.details[0].message}`,
        'Failure'
      );
      yield put(increaseVaildityFailure());

    } else {
      Toastr.success(response.headers.message,'Success');
      yield put(increaseVailditySuccess());
      action.increaseSuccessCallback();
    }
  } catch (error) {
    // Toastr.error(error, 'Failure Response');
  }
}

export function* watchIncreaseValidity() {
  yield takeEvery(INCREASE_VALIDITY_REQUEST, increaseValidity);
}
