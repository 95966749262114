import {
  GET_POLICY_HOLDERS_REQUEST,
  GET_POLICY_HOLDERS_SUCCESS,
  GET_POLICY_HOLDERS_FAILURE,
} from './nucleon-constants';

const getPolicyHoldersRequest = (urlParams) => ({
  type: GET_POLICY_HOLDERS_REQUEST,
  urlParams,
});

export const getPolicyHoldersSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: GET_POLICY_HOLDERS_SUCCESS,
});
export const getPolicyHoldersFailure = () => ({
  type: GET_POLICY_HOLDERS_FAILURE,
});

export const getPolicyHolders = async (urlParams, dispatch) => {
  dispatch(getPolicyHoldersRequest(urlParams));
};
