import {
  TEXT,
  NUMBER,
  NUMBER_RANGE_TYPE,
  DATE_RANGE_TYPE,
  SELECT,
} from '../../constants/data-table';

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'MANAGE_USERS_SUCCESS';
export const USERS_FAILURE = 'USERS_FAILURE';

export const GET_USERS_REPORTS_REQUEST = 'GET_USERS_REPORTS_REQUEST';
export const GET_USERS_REPORTS_SUCCESS = 'GET_USERS_REPORTS_SUCCESS';
export const GET_USERS_REPORTS_FAILURE = 'GET_USERS_REPORTS_FAILURE';

export const usersFilters = [
  {
    name: 'Crif Score',
    value: 'crifScore',
    filterCondition: 'eq',
    // defaultFilterValue:['677','700'],
    minPlaceholder: 'min value',
    maxPlaceholder: 'max value',
    minFilterCondition: 'gte',
    maxFilterCondition: 'lte',
    type: NUMBER_RANGE_TYPE,
  },
  {
    name: 'Name',
    value: 'name',
    filterCondition: 'ilike',
    placeholder: 'Rahul Kumar',
    type: TEXT,
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '9876543263',
    type: NUMBER,
  },
  {
    name: 'Application status',
    value: 'callStatus',
    filterCondition: 'eq',
    type: SELECT,
    options: [
      { name: 'Completed', value: 'completed' },
      { name: 'Pending', value: 'pending' },
      { name: 'Updated', value: 'updated' },
      { name: 'Rejected', value: 'rejected' },
    ],
  },
  {
    name: 'Bank Name',
    value: 'bankName',
    filterCondition: 'ilike',
    placeholder: 'HDFC Bank',
    type: TEXT,
  },
  {
    name: 'Email',
    value: 'primaryEmail',
    filterCondition: 'ilike',
    placeholder: 'User Email',
    type: TEXT,
  },
  {
    name: 'Allocated To',
    value: 'allocatedTo',
    filterCondition: 'ilike',
    placeholder: 'Employer Name',
    type: TEXT,
  },
  {
    name: 'Employement Type',
    value: 'employmentType',
    filterCondition: 'eq',
    placeholder: 'User Email',
    type: SELECT,
    options: [
      { name: 'Salaried', value: 'salaried' },
      { name: 'Self Employed', value: 'self-employed' },
    ],
  },
  {
    name: 'Pan Document Status',
    value: 'panDocumentStatus',
    filterCondition: 'eq',
    placeholder: 'verified',
    type: SELECT,
    options: [
      { name: 'Verified', value: 'verified' },
      { name: 'Pending', value: 'pending' },
    ],
  },
  {
    name: 'Aadhar Document Status',
    value: 'aadharDocumentStatus',
    filterCondition: 'eq',
    placeholder: 'verified',
    type: SELECT,
    options: [
      { name: 'Verified', value: 'verified' },
      { name: 'Pending', value: 'pending' },
    ],
  },
  {
    name: 'KYC Status',
    value: 'isKycCompleted',
    filterCondition: 'eq',
    placeholder: 'verified',
    type: SELECT,
    options: [
      { name: 'Completed', value: 'true' },
      { name: 'Pending', value: 'false' },
    ],
  },
  {
    name: 'Bankstatement Status',
    value: 'bankStatementDocumentStatus',
    filterCondition: 'eq',
    placeholder: 'failed',
    type: SELECT,
    options: [
      { name: 'Verified', value: 'verified' },
      { name: 'Pending', value: 'pending' },
      { name: 'Failed', value: 'failed' },
      { name: 'Valid', value: 'valid' },
      { name: 'Invalid', value: 'invalid' },
      { name: 'Fraud', value: 'frauded' },
    ],
  },
  {
    name: 'Financial Check',
    value: 'isFinancialVerified',
    filterCondition: 'eq',
    placeholder: 'failed',
    type: SELECT,
    options: [
      { name: 'Verified', value: 'true' },
      { name: 'Un-verified', value: 'false' },
    ],
  },
  {
    name: 'Registration Fee Expiry Status',
    value: 'registrationFeeExpiryStatus',
    filterCondition: 'eq',
    placeholder: 'failed',
    type: SELECT,
    options: [
      { name: 'Valid', value: 'valid' },
      { name: 'Expired', value: 'expired' },
    ],
  },
  {
    name: 'Registration Fee Expiry',
    value: 'registrationFeeExpiry',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'registrationFeeExpiry',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'registrationFeeExpiry',
  },
  {
    name: 'User Registered on',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  },
];
