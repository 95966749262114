import React ,{ useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import Utils from '../utils/index';
import './not-found-404.css';

import NotFoundImage from '../../images/404.svg';

const NotFound404 = () => {
  const [role,setRole] = useState('');
  const [link,setlink] = useState('');

  useEffect(() => {
    const userrole = new Utils();
    const Role = userrole.getUserRole();

    setRole(Role);
    if(role !== 'system-admin' || role !== 'credit-manager' || role !== 'collection-staff' || role !== 'credit-head'){
      setlink('/users');
    }else {
      setlink('/');
    }

  },[role,link]);
  return (
    <div className="error-page-img">
      <div>
        <img alt="Not Found" src={NotFoundImage} />
      </div>
      <div className="error-page-text">
        <h1> ERROR 404</h1>
        <p>PAGE NOT FOUND</p>
      </div>
      <div className="home-btn">
        <Link to={link} className="black-border-btn">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound404;
