import {
  ADD_HEALTH_USER_FALIURE,
  ADD_HEALTH_USER_SUCCESS,
  ADD_HEALTH_USER_REQUEST,
} from './add-nucleon-user-constants';

const initialState = {
  error: false,
  success: false,
  isloading: true,
};

const addHealthCareUser = (state = initialState, action) => {
  switch (action.type) {
    case ADD_HEALTH_USER_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case ADD_HEALTH_USER_FALIURE:
      return {
        ...state,
        error: true,
        isloading: false,
      };
    case ADD_HEALTH_USER_SUCCESS:
      return {
        error: false,
        isFetching: false,
        success: true,
      };
    default:
      return state;
  }
};

export default addHealthCareUser;