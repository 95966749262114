import {
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
} from './send-email-to-lender-constants';

const initialState = {
  error: false,
  isFetching: false,
  totalRecords: '',
  loanList: [],
};

const sendEmailToLender = (state = initialState, action) => {
  switch (action.type) {
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
        loanList: [],
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        loanList: [],
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        totalRecords: action.totalRecords,
        loanList: action.data,
      };
    default:
      return state;
  }
};

export default sendEmailToLender;
