/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import moment from 'moment';
import _ from 'lodash';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Breadcrumb, Modal } from 'react-bootstrap';

import { getProduct } from './get-product-action';
import { editProduct } from './edit-product-action'

import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import Footer from '../../components/footer';
import SidebarIcon from '../../components/sidebar-icon';
import { productFilters } from './manage-product-constants';

import { userExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import EditProduct from '../../components/edit-product';
import EditProductAction from '../../components/edit-product/edit-product-action';

const ManageProduct = ({ location }) => {
  // Filter State
  const [show, setShow] = useState(false);
  const [editManageProduct, setEditManageProduct] = useState({
    concurrencyStamp: '',
    name: '',
    publicId: '',
    priority: '',
    status: '',
    productSettingId: '',
  });
  const [edituserpopup, setEdituserpopup] = useState(false);

  const { isFetching, product, totalRecords } = useSelector((state) => state.getProduct);

  const productList =
  product &&
  product.map((element, index) => {
    const id = index + 1;

    return {
      ...element,
      concurrencyStampProduct: element.concurrencyStamp,
      concurrencyStamp: element.productSetting ? element.productSetting.concurrencyStamp : '-',
      priority: element.productSetting ? element.productSetting.priority : '-',
      productSettingId: element.productSetting ? element.productSetting.publicId : '-',
      id,
    };
  });

  const dispatch = useDispatch();

  const getProductData = (urlParams) => {
    getProduct(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getProductData(urlParams);
      }
      setDefaultFilter(productFilters, value, filterOnValue);
    } else {
      getProductData(urlParams);
    }
  }, []);

  const userNameHandle = (cell, row) => {
    return <Link to={`/users/${row.publicId}`}>{cell || '-'}</Link>;
  };

  const buttonFormatter = (cell) => {
    if (cell === 'deleted') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'active') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="label-status-initiated">{cell}</label>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  function dateTimeFormatter(cell) {
    return <label>{cell?moment(cell).format('DD-MM-YYYY hh:mm:ss'):'-'}</label>;
  }

  const updateUserSuccess = () => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    };

    getProduct(urlParams,dispatch);
  };

  const handleUpdateProduct = () => {
    setEditManageProduct(editManageProduct);
    let updateUserDetails;

    updateUserDetails = editManageProduct;

    updateUserDetails = _.omit(updateUserDetails, [
      'productId',
      'concurrencyStamp',
    ]);

    editProduct(
      updateUserDetails,
      editManageProduct.productSettingId,
      editManageProduct.concurrencyStamp,
      updateUserSuccess,
      dispatch
    );
    setEdituserpopup(false);
  };

  function actionIcon(onClick, cell) {
    const handleEditShowPopup = () => {
      setEditManageProduct({
        concurrencyStamp: cell.concurrencyStamp,
        name: cell.name,
        productSettingId: cell.productSettingId,
        status: cell.status,
        priority: cell.priority,
      });

      setEdituserpopup(true);
    };

    return (
      <EditProductAction
        handleEditShowPopup={() => handleEditShowPopup(onClick)}
      />
    );
  }
  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '30',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userNameHandle,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'code',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Code',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'priority',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Priority',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '180',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'createdAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Created At',
      width: '120',
    },
    {
      columnClassName: '',
      dataField: 'action',
      dataFormat: actionIcon,
      dataSort: false,
      isKey: false,
      name: 'Actions',
      width: '150',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Products</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Manage Product</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="loan-container mar0 mt-2">
            <DataTable
              tableData={productList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getProductData}
              exportUrl={userExportUrl}
              tableTitle="PRODUCTS"
              pageFilters={productFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
        <Modal
          show={edituserpopup}
          onHide={() => setEdituserpopup(false)}
          animation={false}
          className="edit-reason-popup"
        >
          <div className="common-image">
            <div className="common-img" onClick={() => setEdituserpopup(false)}>
              <img alt="close-icon-gray" src={CloseIconWhite} />
            </div>
          </div>
          <Modal.Body>
            <EditProduct
              closeEditUserPopup={() => setEdituserpopup(false)}
              editUserDetails={editManageProduct}
              setEditUserDetails={setEditManageProduct}
              handleUpdateUser={handleUpdateProduct}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ManageProduct;
