import config from '../../config';
import Storage from './storage';

const portal = document.querySelector('.app');

const storage = new Storage();

export const IDENTITY_SERVICE_URL = portal.getAttribute(
  'data-identity-service-url'
)
  ? portal.getAttribute('data-identity-service-url')
  : config.IDENTITY_SERVICE_URL;

export const FILE_SERVICE_URL = portal.getAttribute('data-file-service-url')
  ? portal.getAttribute('data-file-service-url')
  : config.FILE_SERVICE_URL;

export const LOAN_SERVICE_URL = portal.getAttribute('data-loan-service-url')
  ? portal.getAttribute('data-loan-service-url')
  : config.LOAN_SERVICE_URL;

export const BANKING_SERVICE_URL = portal.getAttribute(
  'data-banking-service-url'
)
  ? portal.getAttribute('data-banking-service-url')
  : config.BANKING_SERVICE_URL;

export const PARTNER_SERVICE_URL = portal.getAttribute(
  'data-partner-service-url'
)
  ? portal.getAttribute('data-partner-service-url')
  : config.PARTNER_SERVICE_URL;

export const REPORTING_SERVICE_URL = portal.getAttribute(
  'data-reporting-service-url'
)
  ? portal.getAttribute('data-reporting-service-url')
  : config.REPORTING_SERVICE_URL;

// URL parameter helper.
const attachParams = (baseUrl, params) => {
  const url = new URL(baseUrl);

  url.search = new URLSearchParams(params).toString();
  return url;
};

export const LOGIN_URL = `${IDENTITY_SERVICE_URL}/login`;

export const CHANGE_PASSWORD_URL = `${IDENTITY_SERVICE_URL}/change-password`;

export const RESET_PASSWORD_URL = `${IDENTITY_SERVICE_URL}/set-password`;

export const ROLE_LIST_URL = `${IDENTITY_SERVICE_URL}/role`;

export const STATE_LIST_URL = `${IDENTITY_SERVICE_URL}/state`;

export const ADD_USER_URL = `${IDENTITY_SERVICE_URL}/save-admin-user`;

export const ADD_CALL_LOGS_URL = `${IDENTITY_SERVICE_URL}/call-log`;

export const GET_PARTNER_LIST_URL = `${IDENTITY_SERVICE_URL}/partner`;

export const UPLOAD_PROFILE_PIC_URL = `${IDENTITY_SERVICE_URL}/profile-pic`;
export const DISBURSEMENT_CHIP_URL = `${IDENTITY_SERVICE_URL}/get-disbursement-report`;
export const COLLECTION_CHIP_URL = `${IDENTITY_SERVICE_URL}/get-collections-report`;
export const EMI_CHIP_URL = `${IDENTITY_SERVICE_URL}/get-emi-reports`;
export const DEFAULTS_CHIP_URL = `${IDENTITY_SERVICE_URL}/get-defaults-report`;
export const SEND_GENERATED_LEADS_URL = `${config.NUCLEON_HEALTH_SERVICE}/loan/email/to-be-send`;
export const HEALTH_CARE_LENDER_PARTNER_LIST_URL = `${config.NUCLEON_HEALTH_SERVICE}/partner`;

export const callLogStatus = (params = null) => {
  const callStatusUrl = `${IDENTITY_SERVICE_URL}/call-log-status`;

  if (params) {
    return attachParams(callStatusUrl, params);
  }
  return callStatusUrl;
}

export const dashboardReportsUrl = (params = null) => {
  const dashboardUrl = `${IDENTITY_SERVICE_URL}/dashboard`;

  if (params) {
    return attachParams(dashboardUrl, params);
  }
  return dashboardUrl;
}

export const manageUsersUrl = (params = null) => {
  const manageUsersBaseUrl = `${IDENTITY_SERVICE_URL}/users`;

  if (params) {
    return attachParams(manageUsersBaseUrl, params);
  }
  return manageUsersBaseUrl;
};

export const manageProductUrl = (params = null) => {
  const manageProductBaseUrl = `${IDENTITY_SERVICE_URL}/product`;

  if (params) {
    return attachParams(manageProductBaseUrl, params);
  }
  return manageProductBaseUrl;
};

export const UsersUrl = (params = null) => {
  const manageUsersBaseUrl = `${REPORTING_SERVICE_URL}/users`;

  if (params) {
    return attachParams(manageUsersBaseUrl, params);
  }
  return manageUsersBaseUrl;
};

export const LoanDpdUrl = (params = null) => {
  const manageUsersBaseUrl = `${REPORTING_SERVICE_URL}/loan-dpd`;

  if (params) {
    return attachParams(manageUsersBaseUrl, params);
  }
  return manageUsersBaseUrl;
};

export const sentUtrsUrl = (params = null) => {
  const sentUtrsBaseUrl = `${config.NUCLEON_HEALTH_SERVICE}/loan/email/sent`;

  if (params) {
    return attachParams(sentUtrsBaseUrl, params);
  }
  return sentUtrsBaseUrl;
};

export const getEmailSendToLenderListUrl = (params = null) => {
  const getEmailSentToLenderListBaseUrl = `${config.NUCLEON_HEALTH_SERVICE}/lead`;

  if (params) {
    return attachParams(getEmailSentToLenderListBaseUrl, params);
  }
  return getEmailSentToLenderListBaseUrl;
};

export const getPolicyHoldersListUrl = (params = null) => {
  const GET_POLICY_HOLDERS_LIST_URLS = `${config.NUCLEON_HEALTH_SERVICE}/policy-holder`;

  if (params) {
    return attachParams(GET_POLICY_HOLDERS_LIST_URLS, params);
  }
  return getPolicyHoldersListUrl;
};

export const callLogsUrl = (params = null) => {
  const callLogsBaseUrl = `${REPORTING_SERVICE_URL}/get-allocations-list`;

  if (params) {
    return attachParams(callLogsBaseUrl, params);
  }
  return callLogsBaseUrl;
};

export const managePincodeUrl = (params = null) => {
  const managePincodeBaseUrl = `${REPORTING_SERVICE_URL}/pincode`;

  if (params) {
    return attachParams(managePincodeBaseUrl, params);
  }
  return managePincodeBaseUrl;
};

export const manageProfessionUrl = (params = null) => {
  const manageProfessionBaseUrl = `${REPORTING_SERVICE_URL}/profession`;

  if (params) {
    return attachParams(manageProfessionBaseUrl, params);
  }
  return manageProfessionBaseUrl;
};

export const profileDetailsUrl = (userId) => {
  const PROFILE_DETAILS_URL = `${IDENTITY_SERVICE_URL}/profile/${userId}`;

  return PROFILE_DETAILS_URL;
};

export const addPatientLoanUrl = (policyHolderId) => {
  const ADD_PATIENT_LOAN_URL = `${config.NUCLEON_HEALTH_SERVICE}/loan/${policyHolderId}`;

  return ADD_PATIENT_LOAN_URL;
};

export const generateLoanLeadUrl = () => {
  const GENERATE_LOAN_URL = `${config.NUCLEON_HEALTH_SERVICE}/lead`;

  return GENERATE_LOAN_URL;
};

export const healthCareLoanUrl = (params = null) => {
  const HEALTH_CARE_LOAN_URL = `${config.NUCLEON_HEALTH_SERVICE}/loan`;

  if (params) {
    return attachParams(HEALTH_CARE_LOAN_URL, params);
  }

  return HEALTH_CARE_LOAN_URL;
};

export const policyHolderDetailsUrl = (userId) => {
  const PROFILE_DETAILS_URL = `${config.NUCLEON_HEALTH_SERVICE}/policy-holder/${userId}`;

  return PROFILE_DETAILS_URL;
};

export const gurantorprofileDetailsUrl = (publicId) => {
  const GURANTOR_PROFILE_DETAILS_URL = `${IDENTITY_SERVICE_URL}/loan-reference-info/${publicId}`;

  return GURANTOR_PROFILE_DETAILS_URL;
};

export const increaseValidityUrl = (userId) => {
  const INCREASE_VALIDITY_URL = `${IDENTITY_SERVICE_URL}/preprocessing/payment/${userId}`;

  return INCREASE_VALIDITY_URL;
};

export const deletekycUrl = (userId) => {
  const DELETE_KYC_URL = `${IDENTITY_SERVICE_URL}/update-user-kyc/${userId}`;

  return DELETE_KYC_URL;
};

export const editManageProductUrl = (productId) => {
  const EDIT_PRODUCT_URL = `${IDENTITY_SERVICE_URL}/product/${productId}/setting`;

  return EDIT_PRODUCT_URL;
};

export const blockUserUrl = (userId) => {
  const BLOCK_USER_URL = `${IDENTITY_SERVICE_URL}/blocked-unblocked-user/${userId}`;

  return BLOCK_USER_URL;
};

export const loanInfoUrl = (loanId) => {
  const LOAN_INFO_URL = `${LOAN_SERVICE_URL}/loan/${loanId}`;

  return LOAN_INFO_URL;
};

export const healthCareLoanInfoUrl = (loanId) => {
  const HEALTH_CARE_LOAN_INFO_URL = `${config.NUCLEON_HEALTH_SERVICE}/loan/${loanId}`;

  return HEALTH_CARE_LOAN_INFO_URL;
};

export const disburseLoanUrl = () => {
  const DISBURSE_URL = `${LOAN_SERVICE_URL}/disbursement`;

  return DISBURSE_URL;
};

export const emandateUrl = (publicId) => {
  const EMANDATE_URL = `${IDENTITY_SERVICE_URL}/execute-payment/${publicId}`;

  return EMANDATE_URL;
};

export const loanActionURL = (id) => {
  const LOAN_ACTION_URL = `${LOAN_SERVICE_URL}/loan/${id}/status`;

  return LOAN_ACTION_URL;
};

export const viewPartnerInfo = (partnerId) => {
  const VIEW_PARTNER_URL = `${PARTNER_SERVICE_URL}/partner/${partnerId}`;

  return VIEW_PARTNER_URL;
};

export const viewProducts = () => {
  const VIEW_PRODUCT_URL = `${PARTNER_SERVICE_URL}/product`;

  return VIEW_PRODUCT_URL;
};

export const addnewProductURL = () => {
  const PRODUCT_URL = `${PARTNER_SERVICE_URL}/product`;

  return PRODUCT_URL;
};

export const addNewUser = () => {
  const ADD_USER_USER = `${config.NUCLEON_HEALTH_SERVICE}/policy-holder`;

  return ADD_USER_USER;
};

export const updateProductDetailsURL = (productId) => {
  const UPDATE_PRODUCT_URL = `${PARTNER_SERVICE_URL}/product/${productId}`;

  return UPDATE_PRODUCT_URL;
};

export const partnerRazorPayInfo = (partnerId) => {
  const VIEW_PARTNER_RAZOR_URL = `${BANKING_SERVICE_URL}/razorpay/setting/${partnerId}`;

  return VIEW_PARTNER_RAZOR_URL;
};

export const partnerPaytmInfo = (partnerId) => {
  const VIEW_PARTNER_PAYTM_URL = `${BANKING_SERVICE_URL}/paytm/setting/${partnerId}`;

  return VIEW_PARTNER_PAYTM_URL;
};

export const razorPayWalletSetting = () => {
  const RAZOR_PAY_WALLET_SETTING_URL = `${BANKING_SERVICE_URL}/razorpay/setting`;

  return RAZOR_PAY_WALLET_SETTING_URL;
};

export const paytmWalletSetting = () => {
  const PAYTM_WALLET_SETTING_URL = `${BANKING_SERVICE_URL}/paytm/setting`;

  return PAYTM_WALLET_SETTING_URL;
};

export const getBankNameDetailsURL = () => {
  const BANK_URL = `${BANKING_SERVICE_URL}/banks`;

  return BANK_URL;
};

export const getHospitalListURL = () => {
  const HOSPITAL_URL = `${config.NUCLEON_HEALTH_SERVICE}/hospital`;

  return HOSPITAL_URL;
}

export const bankDetailsEditStatmentUrl = () => {
  const BANK_ADD_EDIT_URL = `${IDENTITY_SERVICE_URL}/bank-statement-info`;

  return BANK_ADD_EDIT_URL;
};
export const bankDetailsAddEditURL = () => {
  const BANK_ADD_EDIT_URL = `${IDENTITY_SERVICE_URL}/user-bank-detail`;

  return BANK_ADD_EDIT_URL;
};

export const manualPaymentUrl = (loanId) => {
  const MANUAL_PAYMENT_URL = `${LOAN_SERVICE_URL}/payment/${loanId}`;

  return MANUAL_PAYMENT_URL;
};

export const autoPaymentUrl = (loanId) => {
  const AUTO_PAYMENT_URL = `${LOAN_SERVICE_URL}/payment/${loanId}/initiate`;

  return AUTO_PAYMENT_URL;
};
export const loanEsignAgreementUrl = (fileId) => {
  const loanEsignAgreementExportUrl = `${FILE_SERVICE_URL}/view-file/${fileId}`;

  if (fileId) {
    const newParam = {};

    newParam.access_token = storage.get('token');
    return attachParams(loanEsignAgreementExportUrl, newParam);
  }
  return loanEsignAgreementExportUrl;
};

export const userAddressUrl = (userAddressId) => {
  const USER_ADDRESS_URL = `${IDENTITY_SERVICE_URL}/user-address/${userAddressId}`;

  return USER_ADDRESS_URL;
};

export const policyHolderAddressUrl = (userAddressId) => {
  const USER_ADDRESS_URL = `${config.NUCLEON_HEALTH_SERVICE}/user-address/${userAddressId}`;

  return USER_ADDRESS_URL;
};

export const addAddressUrl = (addAddressId) => {
  const ADD_ADDRESS_URL = `${IDENTITY_SERVICE_URL}/user-address/${addAddressId}`;

  return ADD_ADDRESS_URL;
};
export const getPincodeURL = (pincode) => {
  const PINCODE_URL = `${IDENTITY_SERVICE_URL}/pincode/${pincode}`;

  return PINCODE_URL;
};

export const editUserUrl = (editUserId) => {
  const EDIT_USER_URL = `${IDENTITY_SERVICE_URL}/users/${editUserId}`;

  return EDIT_USER_URL;
};

export const editManageProduct = (productId) => {
  const EDIT_MANAGE_URL = `${IDENTITY_SERVICE_URL}/product/${productId}/settings`;

  return EDIT_MANAGE_URL;
};

export const updateUtrUrl = (publicId) => {
  const UPDATE_UTR_URL = `${config.NUCLEON_HEALTH_SERVICE}/loan/utr/${publicId}`;

  return UPDATE_UTR_URL;
}

export const policyDetailsUrl = (editUserId) => {
  const EDIT_USER_URL = `${config.NUCLEON_HEALTH_SERVICE}/policy-holder/${editUserId}`;

  return EDIT_USER_URL;
};

export const getHospitalDetailsUrl = (editUserId) => {
  const HOSPITAL_DETAILS_URL = `${config.NUCLEON_HEALTH_SERVICE}/hospital/${editUserId}`;

  return HOSPITAL_DETAILS_URL;
};

export const generatePasswordUrl = (publicId) => {
  const GENERATE_PASSWORD_URL = `${IDENTITY_SERVICE_URL}/reset-password/${publicId}`;

  return GENERATE_PASSWORD_URL;
};

export const editPincodeUrl = (editPincodeId) => {
  const EDIT_PINCODE_URL = `${IDENTITY_SERVICE_URL}/pincode/${editPincodeId}`;

  return EDIT_PINCODE_URL;
};

export const userAddressLocationUrl = (userAddressId) => {
  const USER_ADDRESS_LOCATION_URL = `${IDENTITY_SERVICE_URL}/get-user-device-extraction-info/${userAddressId}`;

  return USER_ADDRESS_LOCATION_URL;
};

export const userSimDetailsUrl = (userId) => {
  const USER_SIM_DETAILS_URL = `${IDENTITY_SERVICE_URL}/getSimDetails/${userId}`;

  return USER_SIM_DETAILS_URL;
};

export const loanCallLogUrl = (loanId) => {
  const USER_SIM_DETAILS_URL = `${IDENTITY_SERVICE_URL}/loan-call-log/${loanId}`;

  return USER_SIM_DETAILS_URL;
};
// FILE SERVICE URLS
export const FILE_UPLOAD_URL = `${IDENTITY_SERVICE_URL}/file`;

export const FILE_UPLOAD_POLICY_HOLDER_URL = `${config.NUCLEON_HEALTH_SERVICE}/file`;

export const EMI_URL = `${LOAN_SERVICE_URL}/emi`;

export const emiUrl = (params = null) => {
  const emiBaseUrl = `${REPORTING_SERVICE_URL}/loan-detail`;

  if (params) {
    return attachParams(emiBaseUrl, params);
  }
  return emiBaseUrl;
};

export const paymentDetailsUrl = (params = null) => {
  const paymentDetailsBaseUrl = `${REPORTING_SERVICE_URL}/collection`;

  if (params) {
    return attachParams(paymentDetailsBaseUrl, params);
  }
  return paymentDetailsBaseUrl;
};

export const viewFileUrl = (profilePicId) => {
  const emiBaseUrl = `${FILE_SERVICE_URL}/file/${profilePicId}`;

  return emiBaseUrl;
};

export const recentActivityUrl = (params = null) => {
  const recentAcitityBaseUrl = `${REPORTING_SERVICE_URL}/recent-acitity`;

  if (params) {
    return attachParams(recentAcitityBaseUrl, params);
  }
  return recentAcitityBaseUrl;
};

export const newRegistrationUrl = (params = null) => {
  const newRegistrationBaseUrl = `${REPORTING_SERVICE_URL}/users`;

  if (params) {
    return attachParams(newRegistrationBaseUrl, params);
  }
  return newRegistrationBaseUrl;
};

export const userBlockDashboardURL = (params = null) => {
  const userBLockDeshboardBaseURL = `${REPORTING_SERVICE_URL}/users`;

  if (params) {
    return attachParams(userBLockDeshboardBaseURL, params);
  }
  return userBLockDeshboardBaseURL;
};

export const loanBlockDashboardURL = (params = null) => {
  const loanBLockDeshboardBaseURL = `${REPORTING_SERVICE_URL}/loan`;

  if (params) {
    return attachParams(loanBLockDeshboardBaseURL, params);
  }
  return loanBLockDeshboardBaseURL;
};

export const exportCsvUrl = (params = null, exportUrl) => {
  const exportCsvBaseUrl = `${REPORTING_SERVICE_URL}/${exportUrl}/export/csv`;

  if (params) {
    return attachParams(exportCsvBaseUrl, params);
  }
  return exportCsvBaseUrl;
};

export const partnerUrl = (params = null) => {
  const partnerBaseUrl = `${PARTNER_SERVICE_URL}/partner`;

  if (params) {
    return attachParams(partnerBaseUrl, params);
  }
  return partnerBaseUrl;
};

export const partnerExportUrl = (params = null) => {
  const partnerExportBaseUrl = `${PARTNER_SERVICE_URL}/partner/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(partnerExportBaseUrl, newParams);
  }
  return partnerExportBaseUrl;
};

export const disbursmentUrl = (params = null) => {
  const disbursmentBaseUrl = `${REPORTING_SERVICE_URL}/disbursement`;

  if (params) {
    return attachParams(disbursmentBaseUrl, params);
  }
  return disbursmentBaseUrl;
};

export const collectionExportUrl = (params = null) => {
  const collectionExportBaseUrl = `${REPORTING_SERVICE_URL}/collection/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(collectionExportBaseUrl, newParams);
  }
  return collectionExportBaseUrl;
};
export const disbursmentExportUrl = (params = null) => {
  const disbursmentExportBaseUrl = `${REPORTING_SERVICE_URL}/disbursement/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(disbursmentExportBaseUrl, newParams);
  }
  return disbursmentExportBaseUrl;
};

export const paymentExportUrl = (params = null) => {
  const paymentExportUrlDATA = `${REPORTING_SERVICE_URL}/payment/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(paymentExportUrlDATA, newParams);
  }
  return paymentExportUrlDATA;
};

export const loanExportUrl = (params = null) => {
  const disbursmentExportBaseUrl = `${REPORTING_SERVICE_URL}/loan/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(disbursmentExportBaseUrl, newParams);
  }
  return disbursmentExportBaseUrl;
};

export const assanCreditloanExportUrl = (params = null) => {
  const disbursmentExportBaseUrl = `${REPORTING_SERVICE_URL}/loan/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    newParams['filters[10][ilike]'] = 'Aasaan credits personal loan';
    newParams['filters[10][key]'] = 'partnerName';
    return attachParams(disbursmentExportBaseUrl, newParams);
  }
  return disbursmentExportBaseUrl;
};

export const loanLeadsExportUrl = (params = null) => {
  const disbursmentExportBaseUrl = `${REPORTING_SERVICE_URL}/loan/leads/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(disbursmentExportBaseUrl, newParams);
  }
  return disbursmentExportBaseUrl;
};

export const loanDetailsExportUrl = (params = null) => {
  const loanDetailsExportBaseUrl = `${REPORTING_SERVICE_URL}/loan-detail/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(loanDetailsExportBaseUrl, newParams);
  }
  return loanDetailsExportBaseUrl;
};

export const userExportUrl = (params = null) => {
  const userExportBaseUrl = `${REPORTING_SERVICE_URL}/users/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(userExportBaseUrl, newParams);
  }
  return userExportBaseUrl;
};

export const loanDpdExportUrl = (params = null) => {
  const userExportBaseUrl = `${REPORTING_SERVICE_URL}/loan-dpd/export/csv`;

  const newParams = params;

  if (newParams) {
    newParams.access_token = storage.get('token');
    return attachParams(userExportBaseUrl, newParams);
  }
  return userExportBaseUrl;
};

export const getUserProfilePic = (profilePicId) => {
  const getUserExportBaseUrl = `${FILE_SERVICE_URL}/view-file/${profilePicId}`;
  const params = {};

  params.access_token = storage.get('token');
  return attachParams(getUserExportBaseUrl, params);
};
export const getURLtoDownloadFile = (doumentId) => {
  const getUserExportBaseUrl = `${FILE_SERVICE_URL}/view-file/${doumentId}`;
  const params = {};

  params.access_token = storage.get('token');
  return attachParams(getUserExportBaseUrl, params);
};

export const getURLtoDownloadPolicyFile = (doumentId) => {
  const getUserExportBaseUrl = `${config.NUCLEON_HEALTH_SERVICE}/view-file/${doumentId}`;
  const params = {};

  params.access_token = storage.get('token');
  return attachParams(getUserExportBaseUrl, params);
};

export const getUserReportUrl = (penNumber) => {
  const getUserReportExportBaseUrl = `${IDENTITY_SERVICE_URL}/order/crif-score-report/${penNumber}`;
  const params = {};

  params.access_token = storage.get('token');
  return attachParams(getUserReportExportBaseUrl, params);
};
export const loanUrl = (params = null) => {
  const loanBaseUrl = `${REPORTING_SERVICE_URL}/loan`;

  if (params) {
    return attachParams(loanBaseUrl, params);
  }
  return loanBaseUrl;
};

export const leadsLoanUrl = (params = null) => {
  const loanBaseUrl = `${REPORTING_SERVICE_URL}/loan/leads`;

  if (params) {
    return attachParams(loanBaseUrl, params);
  }
  return loanBaseUrl;
};

export const collectionUrl = (params = null) => {
  const collectionBaseUrl = `${REPORTING_SERVICE_URL}/collection`;

  if (params) {
    return attachParams(collectionBaseUrl, params);
  }
  return collectionBaseUrl;
};

export const dailyReportUrl = (params = null) => {
  const collectionBaseUrl = `${REPORTING_SERVICE_URL}/daily-report`;

  if (params) {
    return attachParams(collectionBaseUrl, params);
  }
  return collectionBaseUrl;
};

export const defaultsUrl = (params = null) => {
  const defaultsBaseUrl = `${REPORTING_SERVICE_URL}/loan-detail`;

  if (params) {
    return attachParams(defaultsBaseUrl, params);
  }
  return defaultsBaseUrl;
};

export const paymentsUrl = (params = null) => {
  const paymentsBaseUrl = `${REPORTING_SERVICE_URL}/payment`;

  if (params) {
    return attachParams(paymentsBaseUrl, params);
  }
  return paymentsBaseUrl;
};

export const DASHBOARD_CARD_URL = `${LOAN_SERVICE_URL}/dashboard`;

// BANKING SERVICE URLS
export const MANAGE_BANK_URL = `${BANKING_SERVICE_URL}/banks`;

export const manageBankUrl = (params = null) => {
  const manageBankBaseUrl = `${REPORTING_SERVICE_URL}/banks`;

  if (params) {
    return attachParams(manageBankBaseUrl, params);
  }
  return manageBankBaseUrl;
};

export const bankDetailsUrl = (publicId) => {
  const bankDetailsBaseUrl = `${BANKING_SERVICE_URL}/banks/${publicId}`;

  return bankDetailsBaseUrl;
};

export const editBankUrl = (bankId) => {
  const EDIT_BANK_URL = `${BANKING_SERVICE_URL}/banks/${bankId}`;

  return EDIT_BANK_URL;
};
