/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { healthCareLoan } from './health-care-loan-action';
import { healthCareLoanFilters } from './health-care-loan-constants';

import Header from '../../components/header';
import SidebarIcon from '../../components/sidebar-icon';
// import ProgressBar from '../../components/progress-bar';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateTimeFormat,
} from '../../utils/formattor';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { loanExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';

const HealthCareLoan = ({ location }) => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const { healthCareLoanDetails, totalRecords, isFetching } = useSelector(
    (state) => state.healthCareLoan
  );

  // const { dashboardCardDetails } = useSelector((state) => state.dashboardCard);

  const healthCareLoanDetailsList =
  healthCareLoanDetails &&
  healthCareLoanDetails.map((loanData, index) => {
      const id = index + 1;

      return {
        ...loanData,
        id,
      };
    });

  function loanNumberHandle(cell, row) {
    return <Link to={`/health-care-loan/${row.publicId}`}>{cell || '-'}</Link>;
  }

  function buttonFormatter(cell) {
    if (cell === 'closed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'active') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="label-status-initiated">{cell}</label>;
  }

  function emailStatusFormatter(cell) {
    if (cell) {
      return <label className="label-status-paid">True</label>;
    }
    return <label className="status-unpaid">False</label>;
  }

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateTimeFormat(cell);
    }
    return '-';
  };

  const daysFormatter = (cell) => {
    return <label className="status-partialpaid">{cell}</label>;
  };

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const userNameHandle = (cell, row) => {
    return <Link to={`/policy-holder/${row.policyHolderId}`}>{cell || '-'}</Link>;
  };

  function userMobileNo(cell, row) {
    return <Link to={`/policy-holder/${row.policyHolderId}`}>{cell}</Link>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '40',
    },
    {
      columnClassName: '',
      dataField: 'loanNumber',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'borrowerName',
      dataFormat: userNameHandle,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'email',
      dataFormat: userNameHandle,
      dataSort: true,
      isKey: false,
      name: 'Email',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: true,
      isKey: false,
      name: 'Mobile Number',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'policyNumber',
      dataFormat: userMobileNo,
      dataSort: true,
      isKey: false,
      name: 'Policy Number',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'partnerLoanId',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Partner Loan Id',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'partnerUserId',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Partner User Id',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'balanceDueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Balance Due Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'hospitalDueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Hospital Due Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'pharmacyDueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'pharmacy Due Amount',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'pathologyDueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Pathology Due Amount',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'tenure',
      dataFormat: daysFormatter,
      dataSort: true,
      isKey: false,
      name: 'tenure',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'startDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Start Date',
      width: '130',
    },
    {
      columnClassName: '',
      dataField: 'expectedEndDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Expected End Date',
      width: '170',
    },
    {
      columnClassName: '',
      dataField: 'isEmailSent',
      dataFormat: emailStatusFormatter,
      dataSort: true,
      isKey: false,
      name: 'Email Sent',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
    {
        columnClassName: 'col-grey',
        dataField: 'createdAt',
        dataFormat: dateFormatter,
        dataSort: true,
        isKey: false,
        name: 'Created At',
        width: '120',
    },
    {
        columnClassName: 'col-grey',
        dataField: 'updatedAt',
        dataFormat: dateFormatter,
        dataSort: true,
        isKey: false,
        name: 'Updated At',
        width: '120',
    },
  ];

  const getHealthCareLoanData = (urlParams) => {
    healthCareLoan(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getHealthCareLoanData(urlParams);
      }
      setDefaultFilter(healthCareLoanFilters, value, filterOnValue);
    } else {
      getHealthCareLoanData(urlParams);
    }
  }, []);

//  const { loans } = dashboardCardDetails;

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Health-Care-loans</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Health-Care-Loans</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="loan-container">
            <DataTable
              tableData={healthCareLoanDetailsList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getHealthCareLoanData}
              exportUrl={loanExportUrl}
              tableTitle="HEALTH-CARE-LOAN"
              pageFilters={healthCareLoanFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
      </div>
    </div>
  );
};

export default HealthCareLoan;
