import {
  POLICY_HOLDER_ADDRESS_FAILURE,
  POLICY_HOLDER_ADDRESS_REQUEST,
  POLICY_HOLDER_ADDRESS_SUCCESS,
} from './nucleon-profile-constants';

const initialState = {
  error: false,
  isFetching: false,
  policyHolderAddressDetails: [],
};

const policyHolderAddress = (state = initialState, action) => {
  switch (action.type) {
    case POLICY_HOLDER_ADDRESS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case POLICY_HOLDER_ADDRESS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case POLICY_HOLDER_ADDRESS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        userAddressDetails: action.data,
      };
    default:
      return state;
  }
};

export default policyHolderAddress;
