import {
  LOAN_DPD_FAILURE,LOAN_DPD_SUCCESS,LOAN_DPD_REQUEST
} from './loan-dpd-constants';

const initialState = {
  error: false,
  isFetching: false,
  totalRecords: '',
  users: [],
};

const loanDpd = (state = initialState, action) => {
  switch (action.type) {
    case LOAN_DPD_REQUEST:
      return {
        ...state,
        isFetching: true,
        users: [],
      };
    case LOAN_DPD_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        users: [],
      };
    case LOAN_DPD_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        totalRecords: action.totalRecords,
        users: action.data,
      };
    default:
      return state;
  }
};

export default loanDpd;
