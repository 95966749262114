import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { sentUtrsUrl } from '../../utils/urls';
import { SENT_UTRS_REQUEST } from './sent-utrs-constants';
import { sentUtrsFailure, sentUtrsSuccess } from './sent-utrs-action';

const { getRequest } = new HttpHelper();

export function* sentutrs(action) {

  try {
    const response = yield call(getRequest, {
      url: sentUtrsUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(sentUtrsSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(sentUtrsFailure());
  }
}

export function* watchsentutrs() {
  yield takeEvery(SENT_UTRS_REQUEST, sentutrs);
}
