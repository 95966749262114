import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { generateLoanLeadUrl } from '../../utils/urls';
import { GENERATE_LOAN_LEAD_REQUEST } from './nucleon-profile-constants';
import { generateLoanLeadFailure, generateLoanLeadSuccess } from './generate-loan-lead-action'

const { postRequest } = new HttpHelper();

export function* generateloanLead(action) {
  try {
    const response = yield call(postRequest, {
      url: generateLoanLeadUrl(),
      data: action.data,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');

      yield put(generateLoanLeadFailure());
    } else {
      yield put(generateLoanLeadSuccess());

      Toastr.success('Lead Has Been Generated', 'Success');

      window.location.pathname = `/policy-holder/${action.data.policyHolderId}`;
    }

  } catch (err) {
    throw new Error(err);
  }
}

export function* watchgenerateloanLead() {
  yield takeEvery(GENERATE_LOAN_LEAD_REQUEST, generateloanLead);
}
