import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { policyHolderAddressUrl } from '../../utils/urls';
import { ADD_POLICY_HOLDER_ADDRESS_REQUEST, EDIT_POLICY_HOLDER_ADDRESS_REQUEST } from './nucleon-profile-constants';
import { addPolicyHolderAddressSuccess, addPolicyHolderAddressFailure } from './add-policy-holder-address-action';

const { postRequest, patchRequest } = new HttpHelper();

export function* addPolicyHolderAddress(action) {
  try {
    const url = policyHolderAddressUrl(action.id);

    const response = yield call(postRequest, {
      data: action.addressDetails,
      url,
    });

    yield put(addPolicyHolderAddressSuccess(response.data));
    action.callback();
  } catch (error) {
    yield put(addPolicyHolderAddressFailure());
  }
}

export function* editPolicyHolderAddress(action) {
  try {
    const url = policyHolderAddressUrl(action.id);

    const response = yield call(patchRequest, {
      data: action.addressDetails,
      url,
    });

    yield put(addPolicyHolderAddressSuccess(response.data));
    action.callback();
  } catch (error) {
    yield put(addPolicyHolderAddressFailure());
  }
}

export function* watchaddPolicyHolderAddress() {
  yield takeEvery(ADD_POLICY_HOLDER_ADDRESS_REQUEST, addPolicyHolderAddress);
  yield takeEvery(EDIT_POLICY_HOLDER_ADDRESS_REQUEST, editPolicyHolderAddress);
}
