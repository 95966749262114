import {
  LOAN_CALL_REQUEST,
  LOAN_CALL_SUCCESS,
  LOAN_CALL_FAILURE,
} from './loan-call-log-constants';

const loanCallLogRequest = (id) => ({
  id,
  type: LOAN_CALL_REQUEST,
});

export const loanCallLogSuccess = (data) => ({
  data,
  type: LOAN_CALL_SUCCESS,
});

export const loanCallLogFailure = () => ({
  type: LOAN_CALL_FAILURE,
});

export const loanCall = async (id, dispatch) => {
  dispatch(loanCallLogRequest(id));
};
