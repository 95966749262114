import {
  DAILY_REPORT_FAILURE,
  DAILY_REPORT_SUCCESS,
  DAILY_REPORT_REQUEST,
} from './daily-report-constant';

const dailyReportRequest = (urlParams) => ({
  type: DAILY_REPORT_REQUEST,
  urlParams,
});

export const dailyReportSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: DAILY_REPORT_SUCCESS,
});

export const dailyReportFailure = () => ({
  type: DAILY_REPORT_FAILURE,
});

export const dailyReport = async (urlParams, dispatch) => {
  dispatch(dailyReportRequest(urlParams));
};
