import { NUMBER } from '../../../constants/form-type';

export const discountFormConstant = [
  {
    label: 'Discount',
    name: 'platformFee',
    placeholder: 'Add Discount',
    validation: { isRequired: true, minRange: 5, maxRange: 50 },
    isDisabled: false,
    type: NUMBER,
  },
]