import React from 'react';
import ResponsiveTableCustom from '../../components/responsive-normal-data-table';
import {
  toDateFormat,
  toNumberWithCommaAndDecimal,
} from '../../utils/formattor';

const RePayment = ({ loanDetails ,showPopup ,balanceDueAmount ,getRowTableData, role }) => {
  const dummyFunction = (...args) => {
    return args[0];
  };

  const statusAndVerifiedMarker = (cell, tableRowData) => {
    dummyFunction(tableRowData);
    if (cell === 'closed' || cell === null || cell === '') {
      return <label className="common-status-unpaid">{cell || 'Unknow'}</label>;
    } else if (cell === 'active' || cell === 'verified') {
      return <label className="common-label-status-paid">{cell}</label>;
    } else if (cell === 'Partial-Paid' || cell === 'pending') {
      return <label className="common-status-partialpaid">{cell}</label>;
    } else if (cell === 'initiated') {
      return <label className="common-label-status-initiated">{cell}</label>;
    } else if (cell === 'failed') {
      if(role === 'system-admin' || role === 'credit-head'){
        return <label className="common-status-unpaid"
          onClick={() => { showPopup(true); balanceDueAmount(tableRowData.balanceDueAmount); getRowTableData(tableRowData)}}>Emandate</label>;
      }
      return <label className="common-status-unpaid">{cell}</label>;
    }
    return <label className="common-label-status-initiated">{cell}</label>;
  };

  const tableConstant = [
    // {
    //   name: 'EMI Amount',
    //   dataFormat: (cell, tableRowData = null) => toNumberWithCommaAndDecimal(cell),
    //   dataKey: 'principalAmount',
    // },
    {
      name: 'Status',
      dataFormat: statusAndVerifiedMarker,
      dataKey: 'status',
    },
    {
      name: 'Date',
      dataFormat: (cell) => toDateFormat(cell),
      dataKey: 'dueDate',
    },
    {
      name: 'EMI due amount',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'dueAmount',
    },
    {
      name: 'Balance due amount',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'balanceDueAmount',
    },
    {
      name: 'Bounce Charges',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'bounceAmountAccrued',
    },
    {
      name: 'Penal Charges',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'penalAmountAccrued',
    },
    {
      name: 'Balance penal amount',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'balancePenalAmount',
    },
    {
      name: 'Balance principal amount',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'balancePrincipalAmount',
    },
    {
      name: 'Balance interest amount',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'balanceInterestAmount',
    },
    {
      name: 'Principal Amount',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'principalAmount',
    },
    {
      name: 'Interest Amount',
      dataFormat: (cell) =>
        toNumberWithCommaAndDecimal(cell),
      dataKey: 'interestAmount',
    },
  ];

  return (
    <div className="repayment-sec">
      <div className="repayment-sec-heading">
        <h2>RE-PAYMENT DETAILS</h2>
      </div>
      <div className="repayment-sec-table">
        {loanDetails && (
          <ResponsiveTableCustom
            tableHeaderData={tableConstant}
            tableData={loanDetails}
          />
        )}
      </div>
    </div>
  );
};

export default RePayment;
