import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { editUserUrl, profileDetailsUrl } from '../../utils/urls';
import { USER_PROFILE_REQUEST, USER_PROFILE_UPDATE_REQUEST } from './profile-constants';
import { userProfileSuccess, userProfileFailure } from './user-profile-action';
import { profileUpdateFailure, profileUpdateSuccess } from './user-profile-update-action';

const { getRequest, patchRequest } = new HttpHelper();

export function* userProfile(action) {
  try {
    const url = editUserUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    yield put(userProfileSuccess(response.data));
  } catch (error) {
    yield put(userProfileFailure());
  }
}

export function* userProfileUpdate({ id, concurrencyStamp, values,callback }) {
  try {
    const url = profileDetailsUrl(id);
    const headerParams = {
      'Content-Type': 'application/json',
      'x-coreplatform-concurrencystamp': concurrencyStamp,
    };

    yield call(patchRequest, {
      data: {
        ...values,
      },
      headers: headerParams,
      url,
    });

    yield put(profileUpdateFailure());
    callback();
  } catch (error) {
    yield put(profileUpdateSuccess());
  }
}

export function* watchUserProfile() {
  yield takeEvery(USER_PROFILE_REQUEST, userProfile);
  yield takeEvery(USER_PROFILE_UPDATE_REQUEST, userProfileUpdate);

}
