import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { userSimDetailsUrl } from '../../utils/urls';
import { USER_SIM_DETAILS_REQUEST } from './profile-constants';
import { userSimDetailsSuccess,userSimDetailsFailure } from './user-sim-details-action';


const { getRequest } = new HttpHelper();

export function* userSimDetails(action) {
  try {
    const url = userSimDetailsUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });
   
    yield put(userSimDetailsSuccess(response.data));
  } catch (error) {
    yield put(userSimDetailsFailure());
  }
}

export function* watchuserSimDetails() {
  yield takeEvery(USER_SIM_DETAILS_REQUEST, userSimDetails);
}
