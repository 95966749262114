import React from 'react';
import ResponsiveTableCustom from '../../components/responsive-normal-data-table';
import {
  toDateFormat,
  toNumberWithCommaAndDecimal,
} from '../../utils/formattor';

const HealthCareRePayment = ({ loanDetails }) => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(loanDetails)
  },[loanDetails]);
  function emailStatusFormatter(cell) {
    if (cell) {
      return <label className="label-status-paid">True</label>;
    }
    return <label className="status-unpaid">False</label>;
  }

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };
  const tableConstant = [
    // {
    //   name: 'EMI Amount',
    //   dataFormat: (cell, tableRowData = null) => toNumberWithCommaAndDecimal(cell),
    //   dataKey: 'principalAmount',
    // },
    {
      dataKey: 'number',
      dataFormat: (cell) => cell,
      name: 'Loan No',
    },
    {
      dataKey: 'partnerLoanId',
      dataFormat: (cell) => cell,
      name: 'Partner Loan Id',
    },
    {
      dataKey: 'partnerUserId',
      dataFormat: (cell) => cell,
      name: 'Partner User Id',
    },
    {
      dataKey: 'utr',
      dataFormat: (cell) => cell,
      name: 'UTR',
    },
    {
      dataKey: 'disbursementDate',
      dataFormat: dateFormatter,
      name: 'Disbursed Date',
    },
    {
      dataKey: 'facilityType',
      dataFormat: (cell) => cell,
      name: 'Facility Type',
    },
    {
      dataKey: 'bankName',
      dataFormat: (cell) => cell,
      name: 'Bank Name',
    },
    {
      dataKey: 'accountHolderName',
      dataFormat: (cell) => cell,
      name: 'accountHolderName',
    },
    {
      dataKey: 'ifsc',
      dataFormat: (cell) => cell,
      name: 'IFSC',
    },
    {
      dataKey: 'accountNumber',
      dataFormat: (cell) => cell,
      name: 'Account Number',
    },
    {
      dataKey: 'amount',
      dataFormat: toNumberWithCommaAndDecimal,
      name: 'Amount',
    },
    {
      dataKey: 'isEmailSent',
      dataFormat: emailStatusFormatter,
      name: 'Email Sent',
    },
    {
      dataKey: 'status',
      dataFormat: (cell) => cell,
      name: 'Status',
    },
    {
      dataKey: 'createdAt',
      dataFormat: dateFormatter,
      name: 'Created At',
    },
    {
      dataKey: 'updatedAt',
      dataFormat: dateFormatter,
      name: 'Updated At',
    },
  ];

  return (
    <div className="repayment-sec">
      <div className="repayment-sec-heading">
        <h2>UTR DETAILS</h2>
      </div>
      <div className="repayment-sec-table">
        {data && (
          <ResponsiveTableCustom
            tableHeaderData={tableConstant}
            tableData={data}
          />
        )}
      </div>
    </div>
  );
};

export default HealthCareRePayment;
