import {
  ADD_HEALTH_USER_FALIURE,
  ADD_HEALTH_USER_SUCCESS,
  ADD_HEALTH_USER_REQUEST,
} from './add-nucleon-user-constants';

const addHealthCareUserRequest = (data) => ({
  data,
  type: ADD_HEALTH_USER_REQUEST,
});

export const addHealthCareUserSuccess = () => ({
  type: ADD_HEALTH_USER_SUCCESS,
});
export const addHealthCareUserFailure = () => ({
  type: ADD_HEALTH_USER_FALIURE,
});

export const addHealthCareUser = async (data, dispatch) => {
  dispatch(addHealthCareUserRequest(data));
};
