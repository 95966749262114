import React from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '450px',
  height: '400px'
};
const AddressMap = (props) => {
  const center = { lat: Number(props.maplogLat.latitude), lng: Number(props.maplogLat.longitude) }
  const markers = [
    {
      id: 1,
      color: "red",
      position: { lat: Number(props.maplogLat.latitude), lng: Number(props.maplogLat.longitude) }
    },
    {
      id: 2,
      color: "green",
      position: { lat: Number(props.maplogLat.agreementLat), lng: Number(props.maplogLat.agreementLong) }
    },
    {
      id: 3,
      color: "blue",
      position: { lat: Number(props.maplogLat.selfiLat), lng: Number(props.maplogLat.selfieLong) }
    },
  ];
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA7lX_g86FC7MAjNrLXPAXAgFX7M6pScek"
  })
  const [map,setMap] = React.useState(null)
  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <Modal
      show={props.viewMapDetail}
      onHide={() => props.setViewMapDetail(false)}
      animation={false}
      className="loan-eligible-reason-popup"
    >
      <div className="common-image">
        <div
          className="common-img"
          onClick={() => props.setViewMapDetail(false)}
        >
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="editaddress-sec">
          <div className="filter-header">
            <h2>ADDRESS LOCATION</h2>

          </div>

          { isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              //   defaultCenter={{ lat: 18.559008, lng: -68.388881 }}
              zoom={18}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {/* { <Marker position={center} />} */}

              {markers.map(({ id, color, position }) => (
                <Marker
                  key={id}
                  position={position}
                  onLoad={(marker) => {
                    const customIcon = (opts) => Object.assign({
                      path: 'M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z',
                      fillColor: '#34495e',
                      fillOpacity: 1,
                      strokeColor: '#000',
                      strokeWeight: 1,
                      scale: 1,
                    }, opts);

                    marker.setIcon(customIcon({
                      fillColor: color,
                      strokeColor: 'white'
                    }));

                  }}
                >
                </Marker>
              ))}

            </GoogleMap>
          ) : ('')}

          <div className="filter-bottom">
            <button
              className="cancel-btn filter-btn"
              onClick={() => {
                props.closemapDetailsPopup();
              }}
            >
              Cancel
            </button>
            <button
              className="black-border-btn filter-btn"
              onClick={() => {
                props.closemapDetailsPopup();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddressMap;
