import React, { useState, useEffect } from 'react';
import './pre-approved-amount.css';
import { preApprovedAmountDetails } from './pre-approved-amount-constants';
import FieldValue from '../../field-key-value';

const PreApprovedAmountSummary = ({ preApprovedAmount }) => {

  const [defaultBankStatment, setDefaultBank] = useState({});

  useEffect(() => {
    setDefaultBank(preApprovedAmount);
  }, [preApprovedAmount]);

  return (
    <React.Fragment>
      <div className="personal-bank-details">
        <div className="personal-heading-container">
          <div className="d-flex align-items-baseline">
            <h4 className="personal-common-heading">
                            Pre Approved Amount Summary
            </h4>
          </div>
        </div>
        <div className="bank-statement-info-wrapper">
          {preApprovedAmountDetails.map((item, index) =>
            <FieldValue
              key={index}
              fieldName={item.label}
              fieldValue={defaultBankStatment && defaultBankStatment[item.key]}
              type={item.type}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PreApprovedAmountSummary;