import {
  PRODUCT_FAILURE,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
} from './manage-product-constants';

const initialState = {
  error: false,
  isFetching: false,
  totalRecords: '',
  product: [],
};

const getProduct = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
        product: [],
      };
    case PRODUCT_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        product: [],
      };
    case PRODUCT_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        totalRecords: action.totalRecords,
        product: action.data,
      };
    default:
      return state;
  }
};

export default getProduct;
