import {
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
} from './send-email-to-lender-constants';

const sendEmailToLenderRequest = (urlParams) => ({
  type: SEND_EMAIL_REQUEST,
  urlParams,
});

export const sendEmailToLenderSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: SEND_EMAIL_SUCCESS,
});
export const sendEmailToLenderFailure = () => ({
  type: SEND_EMAIL_FAILURE,
});

export const sendEmailToLender = async (urlParams, dispatch) => {
  dispatch(sendEmailToLenderRequest(urlParams));
};
