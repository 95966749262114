export const NUMBER = "number";
export const TEXT = "text";
export const DATE = 'date';
export const TEXTAREA = 'textarea';
export const SELECT = "select";
export const MULTISELECT = "multiselect";
export const NUMBERRANGE = "numberRange";
export const DATERANGE = "dateRange";
export const CONSTANT = "constant";
export const CONSTANT_RUPEE = "constant_rupee";
export const FILE="FILE"
export const SWITCH="SWITCH"
