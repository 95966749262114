/* eslint-disable max-len */
import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoutes from './custom-routes/private-routes';

import SignIn from './containers/sign-in';
import ForgotPassword from './containers/forgot-password';
import ChangePassword from './containers/change-password';
import ResetPassword from './containers/reset-password';

import Dashboard from './containers/dashboard';
import Profile from './containers/profile';
import Loan from './containers/loan';
import LeadsLoan from './containers/leads-loan';
import LoanInformation from './containers/loan-information';
import Users from './containers/users';
import ManageUsers from './containers/manage-users';
import Disbursment from './containers/disbursment';
import Payments from './containers/payments';
import Defaults from './containers/defaults';
import Emi from './containers/emi';
import Collections from './containers/collections';
import NotFound404 from './public/not-found-404';
import ManageBank from './containers/manage-bank';
import ManageProfession from './containers/manage-profession';
import ManagePincode from './containers/manage-pincode';
import AddPartner from './containers/manage-partner/add-partner';
import AddProduct from './containers/manage-partner/add-product';

import ManagePartner from './containers/manage-partner';
import ViewPartner from './containers/view-partner';

import AssanCreditsLoan from './containers/assan-credits-loans';
import Allocation from './containers/allocations';

import NeucleonList from './containers/neucleon';
import AddNeuclonUser from './containers/neucleon/add-neuclon-user';
import NeuclonProfile from './containers/neuclon-profile';
import HealthCareLoan from './containers/health-care-loan';
import HealthCareLoanInformation from './containers/health-care-loan-info';
import SendEmailToLender from './containers/send-email-to-lender';
import DailyReport from './containers/daily-report';
import SentUTRs from './containers/sent-utrs';
import LoanDpd from './containers/loan-dpd';
import ManageProduct from './containers/manage-product';

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/signin" component={(props) => <SignIn {...props} />} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <PrivateRoutes exact path="/changepassword" permissions={['All']} component={ChangePassword} />
      <PrivateRoutes exact path="/resetpassword" permissions={['All']} component={ResetPassword} />

      <PrivateRoutes exact exact path="/" permissions={['system-admin','credit-manager','credit-head']} component={Dashboard} />
      <PrivateRoutes exact path="/dashboard" permissions = {['system-admin','credit-manager','collection-staff','credit-head']} component={Dashboard} />
      <PrivateRoutes exact path="/users/:id" permissions={['All']} component={Profile} />
      <PrivateRoutes exact path="/loan" permissions={['All']} component={Loan} />
      <PrivateRoutes exact path="/loan-leads"
        permissions={['system-admin','credit-manager','collection-staff','credit-head','call-center-staff']}
        component={LeadsLoan} />

      <PrivateRoutes
        exact
        path="/loan/:id"
        permissions={['All']}
        component={LoanInformation}
      />
      <PrivateRoutes exact path="/users"
        permissions={['system-admin','credit-manager','collection-staff','credit-head','call-center-staff']}
        component={Users} />

      <PrivateRoutes exact path="/manageusers" permissions={['system-admin']} component={ManageUsers} />
      <PrivateRoutes exact path="/disbursment" permissions={['system-admin','credit-manager','collection-staff','credit-head']} component={Disbursment} />
      <PrivateRoutes exact path="/payments" permissions={['system-admin','credit-manager','collection-staff','credit-head']} component={Payments} />
      <PrivateRoutes exact path="/defaults"
        permissions={['system-admin','credit-manager','collection-staff','credit-head','call-center-staff']}
        component={Defaults} />
      <PrivateRoutes exact path="/emi" permissions={['system-admin','credit-manager','collection-staff','credit-head']} component={Emi} />
      <PrivateRoutes exact path="/collections" permissions={['system-admin','credit-manager','collection-staff','credit-head']} component={Collections} />
      <PrivateRoutes exact path="/managebanks" permissions={['system-admin']} component={ManageBank} />
      <PrivateRoutes
        exact
        path="/manageprofession"
        permissions={['system-admin']}
        component={ManageProfession}
      />
      <PrivateRoutes exact path="/managepincode" permissions={['system-admin']} component={ManagePincode} />
      <PrivateRoutes exact path="/add-partner"
        permissions={['system-admin','credit-manager','collection-staff','credit-head','call-center-staff']}
        component={AddPartner} />
      <PrivateRoutes exact path="/add-product"
        permissions={['system-admin','credit-manager','collection-staff','credit-head','call-center-staff']}
        component={AddProduct} />
      <PrivateRoutes exact path="/manage-partner" permissions={['system-admin']} component={ManagePartner} />
      <PrivateRoutes exact path="/partner/:id"
        permissions={['system-admin','credit-manager','collection-staff','credit-head','call-center-staff']}
        component={ViewPartner} />

      <PrivateRoutes exact path="/report/users"
        permissions={['system-admin','credit-manager','collection-staff','credit-head','call-center-staff']}
        component={Users} />
      <PrivateRoutes exact path="/report/loan"
        permissions={['system-admin','credit-manager','collection-staff','credit-head','call-center-staff']}
        component={Loan} />
      <PrivateRoutes exact path="/aassan-credits-loans" permissions={['assan-credits']} component={AssanCreditsLoan} />
      <PrivateRoutes exact path="/allocations" permissions={['system-admin']} component={Allocation} />
      <PrivateRoutes exact path="/health-care-loan" permissions={['system-admin','credit-manager','credit-head','call-center-staff']} component={HealthCareLoan} />
      <PrivateRoutes exact path="/policy-holder" permissions={['system-admin','credit-manager','credit-head','call-center-staff']} component={NeucleonList}/>
      <PrivateRoutes exact path="/add-policy-holder" permissions={['system-admin','credit-manager','credit-head','call-center-staff']} component={AddNeuclonUser}/>
      <PrivateRoutes exact path="/policy-holder/:id" permissions={['system-admin','credit-manager','credit-head','call-center-staff']} component={NeuclonProfile} />
      <PrivateRoutes exact path="/health-care-loan/:id" permissions={['system-admin','credit-manager','credit-head','call-center-staff']} component={HealthCareLoanInformation} />
      <PrivateRoutes exact path="/generated-leads" permissions={['system-admin','credit-manager','credit-head','call-center-staff']} component={SendEmailToLender} />
      <PrivateRoutes exact path="/sent-utrs" permissions={['system-admin','credit-manager','credit-head','call-center-staff']} component={SentUTRs} />
      <PrivateRoutes exact path="/report/loan-dpd" permissions={['system-admin','collection-staff','credit-manager','credit-head']} component={LoanDpd} />
      <PrivateRoutes exact path="/report/daily-report" permissions={['system-admin','collection-staff','credit-manager','credit-head']} component={DailyReport} />
      <PrivateRoutes exact path="/manage-product" permissions={['system-admin']} component={ManageProduct}/>
      <Route exact path="*" component={NotFound404} />
    </Switch>
  </Router>
);

export default Routes;
