import { HOSPITAL_LIST_REQUEST } from './nucleon-profile-constants';

const hospitalListRequest = (data) => ({
  data,
  type: HOSPITAL_LIST_REQUEST,
});

// eslint-disable-next-line import/prefer-default-export
export const hospitalList = (dispatch, hospitalListCallback) => {
  dispatch(hospitalListRequest({ hospitalListCallback }));
};
