import {
  HOSPITAL_DETAILS_REQUEST,
  HOSPITAL_DETAILS_SUCCESS,
  HOSPITAL_DETAILS_FAILURE,
} from './nucleon-profile-constants';

const hospitalDetailsRequest = (id) => ({
  id,
  type: HOSPITAL_DETAILS_REQUEST,
});

export const hospitalDetailsSuccess = (data) => ({
  data,
  type: HOSPITAL_DETAILS_SUCCESS,
});
export const hospitalDetailsFailure = () => ({
  type: HOSPITAL_DETAILS_FAILURE,
});

export const hospitalDetails = async (id, dispatch) => {
  dispatch(hospitalDetailsRequest(id));
};
