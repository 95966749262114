import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { healthCareLoanUrl } from '../../utils/urls';

import { HEALTH_CARE_LOAN_REQUEST } from './health-care-loan-constants';

import { healthCareLoanFailure, healthCareLoanSuccess } from './health-care-loan-action';

const { getRequest } = new HttpHelper();

export function* healthCareLoan(action) {
  try {
    const response = yield call(getRequest, {
      url: healthCareLoanUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(healthCareLoanSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(healthCareLoanFailure());
  }
}

export function* watchhealthCareLoan() {
  yield takeEvery(HEALTH_CARE_LOAN_REQUEST, healthCareLoan);
}
