import React, { useEffect, useState } from 'react';
import { getprofileTabDetailsConst } from './profile-details-tab-constants';
import ResponsiveTableCustom from '../../responsive-normal-data-table';
import Button from '../../form-elements/button';

const ProfileDetailsTab = ({
  identityDocument,
  loans,
  setAddLoan,
  setGenerateLoanLead,
  setLoanid,
}) => {
  const [currentTab, setCurrentTab] = useState('IDENTITY_CHECK');
  const [currentTableData, setCurrentTabData] = useState(identityDocument);
  const [profileTabDetailsConst,setprofileTabDetailsConst] = useState(getprofileTabDetailsConst);

  useEffect(() => {
    setprofileTabDetailsConst(getprofileTabDetailsConst);
  },[profileTabDetailsConst]);

  const LoanLink = (cell, tableRowData = null) => (
    <Link to={`/loan/${tableRowData.publicId}`}>{cell || '-'}</Link>
  );

  const editButtonStatementHandler = (publicId) => {
    setLoanid(publicId);
    setGenerateLoanLead(true);
  };

  // eslint-disable-next-line consistent-return
  const generateLoanLeadButton = (cell, tableRowData = null) => {
    if (tableRowData.status === 'initiated') {
      // |<img src={ViewIcon} width={20} style={{marginLeft:'5px',cursor:'pointer'}}/>
      return (
        <Button
          width={15}
          text={'GENERATE LEAD'}
          style={{ marginRight: '5px', cursor: 'pointer' }}
          click={() => editButtonStatementHandler(tableRowData.publicId)}
        />
      );
    }
  }

  useEffect(() => {
    profileTabDetailsConst.LOAN_INFO.tableHeaderData.map((itemParam) => {
      const item = itemParam;

      if (item.name === 'number') {
        item.dataFormat = LoanLink;
      }

      if(item.name === 'Generate Lead'){
        item.dataFormat = generateLoanLeadButton;
      }
      return false;
    });

  }, []);

  const selectTabHandler = (listItem) => {
    setCurrentTab(listItem);
    switch (listItem) {
      case 'IDENTITY_CHECK':
        setCurrentTabData(identityDocument);
        break;
      case 'LOAN_INFO':
        setCurrentTabData(loans);
        break;
      default:
        break;
    }
  };

  return (
    <div className="profile-details-tab">
      <ul className="table-name-profile-tab-contanier">
        {Object.keys(profileTabDetailsConst).map((listItem, index) => (
          <li
            key={index}
            onClick={() => selectTabHandler(listItem)}
            className={`${
              currentTab === listItem && 'profile-details-tab-active'
            }`}
          >
            {profileTabDetailsConst[listItem].tableName}
          </li>
        ))}
      </ul>
      {currentTab === 'LOAN_INFO' ? (
        <div className="add-user-call-log-custom">
          <span className="add-user-call-text-custom">
            Here you can add Loan
          </span>
          <button
            onClick={() => {
              setAddLoan(true);
            }}
            className="add-user-call-log-button-custom"
          >
            Add Loan
          </button>
        </div>
      ) : null}
      <ResponsiveTableCustom
        tableHeaderData={profileTabDetailsConst[currentTab].tableHeaderData}
        tableData={currentTableData}
      />
    </div>
  );
};

export default ProfileDetailsTab;
