import { call, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { HEALTH_CARE_LENDER_PARTNER_LIST_URL } from '../../utils/urls';
import { HEALTH_CARE_LENDER_LIST_REQUEST } from './nucleon-profile-constants';

const { getRequest } = new HttpHelper();

export function* healthCareLenderList(action) {
  try {
    const response = yield call(getRequest, {
      url: HEALTH_CARE_LENDER_PARTNER_LIST_URL,
    });

    action.data.healthCareLenderListCallback(response.data);
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchHealthCareLenderList() {
  yield takeEvery(HEALTH_CARE_LENDER_LIST_REQUEST, healthCareLenderList);
}
