import {
  GENERATE_LOAN_LEAD_FALIURE,
  GENERATE_LOAN_LEAD_SUCCESS,
  GENERATE_LOAN_LEAD_REQUEST,
} from './nucleon-profile-constants';

const generateLoanLeadRequest = (data) => ({
  data,
  type: GENERATE_LOAN_LEAD_REQUEST,
});

export const generateLoanLeadSuccess = () => ({
  type: GENERATE_LOAN_LEAD_SUCCESS,
});
export const generateLoanLeadFailure = () => ({
  type: GENERATE_LOAN_LEAD_FALIURE,
});

export const generateLoanLead = async (data,dispatch) => {
  dispatch(generateLoanLeadRequest(data));
};
