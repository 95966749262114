import {
  COLLECTION_CHIP_REQUEST,
  COLLECTION_CHIP_SUCCESS,
  COLLECTION_CHIP_FAILURE,
} from './collections-constants';

const initialState = {
  collectionChipData: [],
  isFetching: false,
};

const collectionChip = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_CHIP_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case COLLECTION_CHIP_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case COLLECTION_CHIP_SUCCESS:
      return {
        ...state,
        collectionChipData: action.data,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default collectionChip;
