/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumb } from 'react-bootstrap';

import { addPartnerFormFields } from './add-nucleon-user-constants';
import Header from '../../../components/header';
import SideBar from '../../../components/side-bar';
import SidebarIcon from '../../../components/sidebar-icon';
import Footer from '../../../components/footer';
import FormElement from '../../../components/form-elements/index';
import Button from '../../../components/form-elements/button';
import { addHealthCareUser } from './add-health-care-user-action';
import { setInitialFormValues, onChangeHandler, validateForm, getFormDataValue } from '../../../utils/form-helper';
import './add-nucleon-user-constants.css';

const AddNeuclonUser = () => {
  const [show, setShow] = useState(false);
  const [addPartnerForm, setAddPartnerForm] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setInitialFormValues(addPartnerFormFields, setAddPartnerForm);
  }, []);

  const addClassCallBack = () => {
    setShow(!show);
  };

  const redirectToPage = () => {
    window.location.pathname = '/policy-holder'
  }

  const handleAddUser = () => {
    const [newAddUserForm, isValid] = validateForm(addPartnerForm);

    setAddPartnerForm(newAddUserForm);
    if (isValid) {
      const newAddUserObj = getFormDataValue(addPartnerForm);

      addHealthCareUser(newAddUserObj,dispatch);
    }
  };

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>ADD POLICY HOLDER INFORMATION</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item >Health Care</Breadcrumb.Item>
                <Breadcrumb.Item href="/policy-holder">Policy Holders</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-partner-form-container">
            {addPartnerForm.map((formItem, index) => {
              return (
                <div className={`add-partner-form-item`} key={index}>
                  <FormElement
                    inputType={formItem.type}
                    lableName={formItem.label}
                    name={formItem.name}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={formItem.options ? formItem.options : []}
                    onChangeHandler={(targetValue) => { onChangeHandler(addPartnerForm, formItem.name, targetValue, setAddPartnerForm) }}
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={formItem.isDisabled}
                    isTouched={formItem.isTouched}
                  />
                </div>
              )
            })}
          </div>
          <div className="add-partner-button-wrapper">
            <Button click={ () => redirectToPage() } isGreyedOut={false} text={'Cancel'} />
            <Button click={handleAddUser} isGreyedOut={false} text={'SUBMIT'} isMarginLeft={true} />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
      </div>
    </div>
  );
};

export default AddNeuclonUser;
