/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  // toDateTimeFormat,
  toNumberWithCommaAndDecimal,
} from '../../utils/formattor';
// payments
const HealthCareLoanInformationCard = ({ healthCareLoanInfoDetails, patient }) => (
  <div className="loan-inform">
    <div className="loan-content">
      <Link to={`/policy-holder/${healthCareLoanInfoDetails && healthCareLoanInfoDetails.policyHolderId}`} className="loan-content-name">{patient && `${patient.firstName} ${`${patient.lastName}`}` }</Link>
      <span>{`( ${healthCareLoanInfoDetails && healthCareLoanInfoDetails.number} )`}</span>
    </div>
    <div className="loan-tabel">
      <ul>
        <li>
          <label>Loan Status</label>
          <span>{healthCareLoanInfoDetails && healthCareLoanInfoDetails.status}</span>
        </li>
        <li>
          <label>Loan Amount</label>
          <span>{healthCareLoanInfoDetails && toNumberWithCommaAndDecimal(healthCareLoanInfoDetails.amount)}</span>
        </li>
        <li>
          <label>Hospital Due Amount</label>
          <span>{healthCareLoanInfoDetails && toNumberWithCommaAndDecimal(healthCareLoanInfoDetails.hospitalDueAmount)}</span>
        </li>
        <li>
          <label>Pathology Due Amount</label>
          <span>{healthCareLoanInfoDetails && toNumberWithCommaAndDecimal(healthCareLoanInfoDetails.pharmacyDueAmount)}</span>
        </li>
        <li>
          <label>Pharmacy Due Amount</label>
          <span>{healthCareLoanInfoDetails && toNumberWithCommaAndDecimal(healthCareLoanInfoDetails.pathologyDueAmount)}</span>
        </li>
        <li>
          <label>Loan Tenure</label>
          <span>{healthCareLoanInfoDetails && healthCareLoanInfoDetails.tenure}</span>
        </li>
        <li>
          <label>Bounce Charges</label>
          <span>{healthCareLoanInfoDetails && healthCareLoanInfoDetails.bounceCharges}</span>
        </li>
        <li>
          <label>Email Status</label>
          <span>{healthCareLoanInfoDetails && healthCareLoanInfoDetails.isEmailSent}</span>
        </li>
        <li>
          <label>Status</label>
          <span>{healthCareLoanInfoDetails && healthCareLoanInfoDetails.status}</span>
        </li>
      </ul>
    </div>
  </div>
);

export default HealthCareLoanInformationCard;
