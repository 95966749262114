/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import ResponsiveTableCustom from '../responsive-normal-data-table';
import {
  toDateFormat,
  toNumberWithCommaAndDecimal,
} from '../../utils/formattor';

const ExpandedComponent = ({ data }) => {
  const [utrdata, setData] = React.useState([]);

  React.useEffect(() => {
    setData(data.loanDetails)
  },[data]);
  function emailStatusFormatter(cell) {
    if (cell) {
      return <label className="label-status-paid">True</label>;
    }
    return <label className="status-unpaid">False</label>;
  }

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };
  const tableConstant = [
    {
      dataKey: 'number',
      dataFormat: (cell) => cell,
      name: 'Loan No',
    },
    {
      dataKey: 'partnerLoanId',
      dataFormat: (cell) => cell,
      name: 'Partner Loan Id',
    },
    {
      dataKey: 'partnerUserId',
      dataFormat: (cell) => cell,
      name: 'Partner User Id',
    },
    {
      dataKey: 'disbursementDate',
      dataFormat: dateFormatter,
      name: 'Disbursed Date',
    },
    {
      dataKey: 'facilityType',
      dataFormat: (cell) => cell,
      name: 'Facility Type',
    },
    {
      dataKey: 'bankName',
      dataFormat: (cell) => cell,
      name: 'Bank Name',
    },
    {
      dataKey: 'accountHolderName',
      dataFormat: (cell) => cell,
      name: 'accountHolderName',
    },
    {
      dataKey: 'ifsc',
      dataFormat: (cell) => cell,
      name: 'IFSC',
    },
    {
      dataKey: 'accountNumber',
      dataFormat: (cell) => cell,
      name: 'Account Number',
    },
    {
      dataKey: 'amount',
      dataFormat: toNumberWithCommaAndDecimal,
      name: 'Amount',
    },
    {
      dataKey: 'isEmailSent',
      dataFormat: emailStatusFormatter,
      name: 'Email Sent',
    },
    {
      dataKey: 'status',
      dataFormat: (cell) => cell,
      name: 'Status',
    },
    {
      dataKey: 'createdAt',
      dataFormat: dateFormatter,
      name: 'Created At',
    },
    {
      dataKey: 'updatedAt',
      dataFormat: dateFormatter,
      name: 'Updated At',
    },
  ];

  return (
    <div className="repayment-sec" style={{ textAlign: 'center' }}>
      <div className='row'>
        <div className="repayment-sec-heading" style={{
          marginBottom: '50px' }}>
          <h2 style={{ fontSize: '20px' }}>PREVIEW LEAD DETAILS</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <div className="loan-inform" style={{
            marginBottom: '50px',
            marginTop: '50px' }}>
            <div className="loan-content">
              <div className="repayment-sec-heading">
                <h2>POLICY DETAILS</h2>
              </div>
              <Link to={`/policy-holder/${(data && data.policyHolder) && data.policyHolder.publicId}`} className="loan-content-name">{((data && data.policyHolder) && `${data.policyHolder.firstName}`)}  {((data && data.policyHolder) && `${data.policyHolder.lastName}`)} </Link>
              <span>{(data && data.policyHolder) && `( ${data.policyHolder.policyNumber} )`}</span>
            </div>
            <div className="loan-tabel">
              <ul>
                <li>
                  <label>policy Start Date</label>
                  <span>{(data && data.policyHolder) && dateFormatter(data.policyHolder.policyStartDate)}</span>
                </li>
                <li>
                  <label>policy Expiry Date</label>
                  <span>{(data && data.policyHolder) && dateFormatter(data.policyHolder.policyExpiryDate)}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className="loan-inform" style={{
            marginBottom: '50px',
            marginTop: '50px' }}>
            <div className="loan-content">
              <div className="repayment-sec-heading">
                <h2>PATIENT DETAILS</h2>
              </div>
            </div>
            <div className="loan-tabel">
              <ul>
                <li>
                  <label>Patient Name</label>
                  <span>{((data && data.patient) && `${data.patient.firstName}`)}  {((data && data.patient) && `${data.patient.lastName}`)}</span>
                </li>
                <li>
                  <label>Pan Number</label>
                  <span>{(data && data.patient) && data.patient.panNumber}</span>
                </li>
                <li>
                  <label>Aadhar Number</label>
                  <span>{(data && data.patient) && data.patient.aadhaarNumber}</span>
                </li>
                <li>
                  <label>Relation with Policy Holder</label>
                  <span>{(data && data.patient) && data.patient.relationWithPolicyHolder}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="loan-inform" style={{
        marginBottom: '50px',
        marginTop: '20px',
        marginLeft: '20px',
        marginRight: '20px' }}>
        <div className="repayment-sec">
          <div className="loan-content">
            <div className="repayment-sec-heading">
              <h2>UTR DETAILS</h2>
            </div>
          </div>
          <div className="loan-tabel">
            <div className="repayment-sec-table">
              {utrdata && (
                <ResponsiveTableCustom
                  tableHeaderData={tableConstant}
                  tableData={utrdata}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandedComponent;