import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import CloseIconWhite from '../../../../images/icons/closeIconWhite.svg';
import {
  userAddressKeyValueMap,
  editAddressFormConstant,
} from './address-details-constant';
import {
  setInitialFormValues,
  onChangeHandler,
  getFormDataValue,
  setFormWithValues,
  validateForm,
} from '../../../utils/form-helper';
import FormField from '../../form-elements';

const AddressDetails = (props) => {
  const [editAddressForm, setEditAddressForm] = useState([]);
  const [currentAddressToEdit, setCurrentAddressToEdit] = useState({});
  const [defaultAddressIndex, setDefaultAddressIndex] = useState(-1);

  useEffect(() => {
    setInitialFormValues(
      editAddressFormConstant,
      setEditAddressForm,
      currentAddressToEdit
    );
    setDefaultAddressIndex(-1);
    setCurrentAddressToEdit({});
  }, [props.viewAddressDetail]);

  useEffect(() => {
    setInitialFormValues(
      editAddressFormConstant,
      setEditAddressForm,
      currentAddressToEdit
    );
  }, [currentAddressToEdit]);

  useEffect(() => {
    const newPincodeDetails = { ...props.pincodeDetails };

    newPincodeDetails.country = 'India';
    setFormWithValues(editAddressForm, setEditAddressForm, newPincodeDetails);
  }, [props.pincodeDetails]);

  const getPincodeDetails = (pincode) => {
    const regex = new RegExp('^[1-9][0-9]{5}$');
    const isValidPin = regex.test(pincode);

    if (isValidPin) {
      props.getAddressDetailsWithPincode(pincode);
    }
  };
  // To handle the edit of the existing address, Hide the details and open the form
  // @param index for the address on which click is pressed
  const editHandler = (index) => {
    if (currentAddressToEdit.editIndex === index) {
      setCurrentAddressToEdit({});
    } else {
      const editAdressNewObj = {
        addressLine1: props.address[index].addressLine1,
        addressLine2: props.address[index].addressLine2,
        addressLine3: props.address[index].addressLine3,
        districtName: props.address[index].districtName,
        stateName: props.address[index].stateName,
        pincode: props.address[index].pincode,
        country: props.address[index].country,
        addressType: props.address[index].addressType,
        ownership: props.address[index].ownership,
        editIndex: index,
        isPrimary: props.address[index].isPrimary,
      };

      setCurrentAddressToEdit(editAdressNewObj);
    }
  };
  // On click of the Ok button
  const okButtonHandler = () => {
    let isClosePopup = true;

    if (currentAddressToEdit.editIndex > -1) {
      const newEditAddressObj = getFormDataValue(editAddressForm);

      if (defaultAddressIndex === currentAddressToEdit.editIndex) {
        newEditAddressObj.isPrimary = true;
      }
      const [newEditForm, isValid] = validateForm(editAddressForm);

      setEditAddressForm(newEditForm);
      if (isValid) {
        props.editAddressHandler(
          props.address[currentAddressToEdit.editIndex].publicId,
          newEditAddressObj
        );
      } else {
        isClosePopup = false;
      }
    }
    if (
      defaultAddressIndex > -1 &&
      defaultAddressIndex !== currentAddressToEdit.editIndex
    ) {
      const setPrimaryobj = {
        isPrimary: true,
        publicId: props.address[defaultAddressIndex].publicId,
      };

      props.editAddressHandler(
        props.address[defaultAddressIndex].userId,
        setPrimaryobj
      );
    }
    if (isClosePopup) props.closeAddressDetailsPopup();
  };
  const callGetPinCodeDetails = (formName, targetValue) => {
    if (formName === 'pincode') getPincodeDetails(targetValue);
  };
  const callSetDefaultAddress = (index)=>{
    if(defaultAddressIndex === index){
      setDefaultAddressIndex(-1)
    }else{
      setDefaultAddressIndex(index);
    }
  }

  return (
    <Modal
      show={props.viewAddressDetail}
      onHide={() => props.setViewAddressDetail(false)}
      animation={false}
      className="loan-eligible-reason-popup"
    >
      <div className="common-image">
        <div
          className="common-img"
          onClick={() => props.setViewAddressDetail(false)}
        >
          <img alt="close" src={CloseIconWhite} />
        </div>
      </div>
      <Modal.Body>
        <div className="addressdetails-sec logs">
          <div className="filter-header">
            <h2>ADDRESS DETAILS</h2>
            <button
              className="black-border-btn add-btn"
              onClick={() => {
                props.closeAndOpenAddAddressPopup();
              }}
            >
              Add New Address
            </button>
          </div>
          {props.address.map((add, index) => (
            <div key={index}>
              {add.isPrimary ? (
                <div className="addressdetails-sec-btn">
                  <button className="default-chip">Default</button>
                </div>
              ) : null}
              {/* User address details */}
              <div className="editaddress-visible-sec">
                {!(index === currentAddressToEdit.editIndex) ? (
                  <h2>
                    {userAddressKeyValueMap.map((item, newIndex) => (
                      <span key={newIndex}>{item.prefix + add[item.key]}</span>
                    ))}
                  </h2>
                ) : (
                  // edit Address Form in place of address details if user wants to edit address
                  <div className="edit-address-form-wrapper">
                    {editAddressForm.map((formItem, innerIndex) => (
                      <FormField
                        inputType={formItem.type}
                        key={innerIndex}
                        lableName={formItem.label}
                        name={formItem.name}
                        className={
                          innerIndex === 2 ? 'column-width-double' : ''
                        }
                        placeholder={formItem.placeholder}
                        value={formItem.value}
                        isDisabled={formItem.isDisabled}
                        options={
                          formItem.name === 'stateName'
                            ? props.stateData
                            : formItem.options
                        }
                        onChangeHandler={(targetValue) => {
                          onChangeHandler(
                            editAddressForm,
                            formItem.name,
                            targetValue,
                            setEditAddressForm
                          );
                          callGetPinCodeDetails(formItem.name, targetValue);
                        }}
                        isValid={formItem.isValid}
                        errorMsg={formItem.errorMsg}
                        isDisabled={formItem.isDisabled}
                        isTouched={formItem.isTouched}
                      />
                    ))}
                  </div>
                )}
                <div className="editaddress-visible-sec-btn">
                  <button
                    onClick={() => {
                      editHandler(index);
                    }}
                    className="black-border-btn"
                  >
                    {index === currentAddressToEdit.editIndex
                      ? 'Cancel'
                      : 'Edit'}
                  </button>
                  {/* Show set as default only in case the address is not default */}
                  {!add.isPrimary ? (
                    <button
                      className={`black-border-btn ${
                        defaultAddressIndex === index
                          ? 'set-default-active'
                          : ''
                      }`}
                      onClick={() => {callSetDefaultAddress(index)  }}
                    >
                      Set as Default
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ))}

          <div className="filter-bottom">
            <button
              className="cancel-btn filter-btn"
              onClick={() => {
                props.closeAddressDetailsPopup();
              }}
            >
              Cancel
            </button>
            <button
              className="black-border-btn filter-btn"
              onClick={() => {
                okButtonHandler();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddressDetails;
