export const PROFILE_DETAILS_REQUEST = 'PROFILE_DETAILS_REQUEST';
export const PROFILE_DETAILS_SUCCESS = 'PROFILE_DETAILS_SUCCESS';
export const PROFILE_DETAILS_FAILURE = 'PROFILE_DETAILS_FAILURE';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const EDIT_ADDRESS_REQUEST = 'EDIT_ADDRESS_REQUEST';
export const GET_ADDRESS_DETAILS_WITH_PINCODE = 'GET_ADDRESS_DETAILS_WITH_PINCODE';
export const GET_ADDRESS_DETAILS_WITH_PINCODE_SUCCESS = 'GET_ADDRESS_DETAILS_WITH_PINCODE_SUCCESS';

export const GET_BANK_NAME_DETAILS_REQUEST = 'GET_BANK_NAME_DETAILS_REQUEST';
export const ADD_NEW_BANK_DETAILS_REQUEST = 'ADD_NEW_BANK_DETAILS_REQUEST';
export const EDIT_BANK_DETAILS_REQUEST = 'EDIT_BANK_DETAILS_REQUEST';
export const EDIT_BANK_STATEMENT_REQUEST = 'EDIT_BANK_STATEMENT_REQUEST';

export const BANK_REQUEST_FAILURE = 'BANK_REQUEST_FAILURE';
export const BANK_REQUEST_SUCCESS = 'BANK_REQUEST_SUCCESS';

export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

export const FILE_UPLOAD = 'FILE_UPLOAD';

export const UPLOAD_PROFILE_PIC_REQUEST = 'UPLOAD_PROFILE_PIC__REQUEST';
export const UPLOAD_PROFILE_PIC_SUCCESS = 'UPLOAD_PROFILE_PIC__SUCCESS';
export const UPLOAD_PROFILE_PIC_FAILURE = 'UPLOAD_PROFILE_PIC__FAILURE';

export const UPLOAD_DOCS_REQUEST = 'UPLOAD_DOCS__REQUEST';
export const UPLOAD_DOCS_SUCCESS = 'UPLOAD_DOCS__SUCCESS';
export const UPLOAD_DOCS_FAILURE = 'UPLOAD_DOCS__FAILURE';

export const GURANTOR_UPLOAD_DOCS_REQUEST = 'GURANTOR_UPLOAD_DOCS__REQUEST';
export const GURANTOR_UPLOAD_DOCS_SUCCESS = 'GURANTOR_UPLOAD_DOCS__SUCCESS';
export const GURANTOR_UPLOAD_DOCS_FAILURE = 'GURANTOR_UPLOAD_DOCS__FAILURE';

export const USER_ADDRESS_REQUEST = 'USER_ADDRESS_REQUEST';
export const USER_ADDRESS_SUCCESS = 'USER_ADDRESS_SUCCESS';
export const USER_ADDRESS_FAILURE = 'USER_ADDRESS_FAILURE';

export const CALL_LOGS_REQUEST = 'CALL_LOGS_REQUEST';
export const CALL_LOGS_SUCCESS = 'CALL_LOGS_SUCCESS';
export const CALL_LOGS_FAILURE = 'CALL_LOGS_FAILURE';

export const ADD_CALL_LOGS_REQUEST = 'ADD_CALL_LOGS_REQUEST';
export const ADD_CALL_LOGS_SUCCESS = 'ADD_CALL_LOGS_SUCCESS';
export const ADD_CALL_LOGS_FAILURE = 'ADD_CALL_LOGS_FAILURE';

export const BLOCK_UNBLOCK_REQUEST = 'BLOCK_UNBLOCK_REQUEST';
export const BLOCK_UNBLOCK_SUCCESS = 'BLOCK_UNBLOCK_SUCCESS';
export const BLOCK_UNBLOCK_FAILURE = 'ADD_CALL_LOGS_FAILURE';

export const INCREASE_VALIDITY_REQUEST = 'INCREASE_VALIDITY_REQUEST';
export const INCREASE_VALIDITY_SUCCESS = 'INCREASE_VALIDITY_SUCCESS';
export const INCREASE_VALIDITY_FAILURE = 'INCREASE_VALIDITY_FAILURE';

export const USER_ADDRESS_LOCATION_REQUEST = 'USER_ADDRESS_LOCATION_REQUEST';
export const USER_ADDRESS_LOCATION_SUCCESS = 'USER_ADDRESS_LOCATION_SUCCESS';
export const USER_ADDRESS_LOCATION_FAILURE = 'USER_ADDRESS_LOCATION_FAILURE';

export const USER_SIM_DETAILS_REQUEST = 'USER_SIM_DETAILS_REQUEST';
export const USER_SIM_DETAILS_SUCCESS = 'USER_SIM_DETAILS_SUCCESS';
export const USER_SIM_DETAILS_FAILURE = 'USER_SIM_DETAILS_FAILURE';

export const DELETE_USER_KYC_REQUEST = 'DELETE_USER_KYC_REQUEST';
export const DELETE_USER_KYC_SUCCESS = 'DELETE_USER_KYC_SUCCESS';
export const DELETE_USER_KYC_FAILURE = 'DELETE_USER_KYC_FAILURE';

export const STATE_LIST_REQUEST = 'STATE_LIST_REQUEST';
