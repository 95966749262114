import React from 'react';

import { Form } from 'react-bootstrap';

const EditProduct = ({
  closeEditUserPopup,
  editUserDetails,
  setEditUserDetails,
  handleUpdateUser,
}) => {
  return (
    <div className="editpopup-sec">
      <div className="editpopup-header">
        <h2>Edit</h2>
      </div>
      <div className="editpopup-form-sec">
        <Form>
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">Name</label>
            <Form.Control
              type="text"
              placeholder="Sunil Kumar"
              name="name"
              value={editUserDetails.name}
              onChange={(e) =>
                setEditUserDetails({ ...editUserDetails, name: e.target.value })
              }
              disabled={true}
            />
          </div>
          <div className="editpopup-input-value">
            <label className="editpopup-input-title">Priority</label>
            <Form.Control
              type="number"
              placeholder="Enter number"
              name="priority"
              value={editUserDetails.priority}
              onChange={(e) =>
                setEditUserDetails({ ...editUserDetails, priority: e.target.value })
              }
            />
          </div>
        </Form>
      </div>
      <div className="editpopup-bottom">
        <button className="cancel-btn" onClick={closeEditUserPopup}>
          Cancel
        </button>
        <button className="black-border-btn" onClick={handleUpdateUser}>
          OK
        </button>
      </div>
    </div>
  );
};

export default EditProduct;
