import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { gurantorprofileDetailsUrl } from '../../utils/urls';
import { GURANTOR_UPLOAD_DOCS_REQUEST } from './profile-constants';
import { gurantoruploadDocsSuccess, gurantoruploadDocsFailure } from './gurantor-upload-docs-action';

const { patchRequest } = new HttpHelper();

export function* gurantoruploadDocs({ id,concurrencyStamp, documentIds,callback }) {

  try {
    const url = gurantorprofileDetailsUrl(id);
    const headerParams = {
      'Content-Type': 'application/json',
      'x-coreplatform-concurrencystamp': concurrencyStamp,
    };

    const response = yield call(patchRequest, {
      data: {
        ...documentIds,
      },
      headers: headerParams,
      url,
    });

    if (response.error) {
      const { data } = response.error.response;

      yield put(gurantoruploadDocsFailure());
      Toastr.error(data.details[0].message, 'Failure');
    } else {
      yield put(gurantoruploadDocsSuccess());
    }
    callback();
  } catch (error) {

    throw new Error(error);

  }
}

export function* watchgurantorUploadDocs() {
  yield takeEvery(GURANTOR_UPLOAD_DOCS_REQUEST, gurantoruploadDocs);
}
