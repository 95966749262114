/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';

import { Breadcrumb } from 'react-bootstrap';

import { addPartnerFormFields } from './add-partner-constants';
import Header from '../../../components/header';
import SideBar from '../../../components/side-bar';
import SidebarIcon from '../../../components/sidebar-icon';
import Footer from '../../../components/footer';
import FormElement from '../../../components/form-elements/index';
import Button from '../../../components/form-elements/button';
import { setInitialFormValues, onChangeHandler } from '../../../utils/form-helper';
import './add-partner.css';

const AddPartner = () => {
  const [show, setShow] = useState(false);
  const [addPartnerForm, setAddPartnerForm] = useState([]);

  useEffect(() => {
    setInitialFormValues(addPartnerFormFields, setAddPartnerForm);
  }, []);

  const addClassCallBack = () => {
    setShow(!show);
  };

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Add partner</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item >Settings</Breadcrumb.Item>
                <Breadcrumb.Item href="/manage-partner">Manage Partner</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-partner-form-container">
            {addPartnerForm.map((formItem, index) => {
              return (
                <div className={`add-partner-form-item${  index === addPartnerForm.length - 1 ? " description-box" : ""}`} key={index}>
                  <FormElement
                    inputType={formItem.type}
                    lableName={formItem.label}
                    name={formItem.name}
                    placeholder={formItem.placeholder}
                    value={formItem.value}
                    options={formItem.options ? formItem.options : []}
                    onChangeHandler={(targetValue) => { onChangeHandler(addPartnerForm, formItem.name, targetValue, setAddPartnerForm) }}
                    isValid={formItem.isValid}
                    errorMsg={formItem.errorMsg}
                    isDisabled={formItem.isDisabled}
                    isTouched={formItem.isTouched}
                  />
                </div>
              )
            })}
          </div>
          <div className="add-partner-button-wrapper">
            <Button click={() => { }} isGreyedOut={true} text={'Cancel'} /><Button click={() => { }} isGreyedOut={false} text={'SUBMIT'} isMarginLeft={true} />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
      </div>
    </div>
  );
};

export default AddPartner;
