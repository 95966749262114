import {
  GET_POLICY_HOLDERS_FAILURE,
  GET_POLICY_HOLDERS_REQUEST,
  GET_POLICY_HOLDERS_SUCCESS,
} from './nucleon-constants';

const initialState = {
  error: false,
  isFetching: false,
  totalRecords: '',
  policyHolders: [],
};

const getPolicyHolders = (state = initialState, action) => {
  switch (action.type) {
    case GET_POLICY_HOLDERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        policyHolders: [],
      };
    case GET_POLICY_HOLDERS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        policyHolders: [],
      };
    case GET_POLICY_HOLDERS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        totalRecords: action.totalRecords,
        policyHolders: action.data,
      };
    default:
      return state;
  }
};

export default getPolicyHolders;
