/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { disbursmentFilters } from './disbursment-constants';
import { disbursment } from './disbursment-action';
import { disbursmentChip } from './disbursment-chip-action';

import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import SidebarIcon from '../../components/sidebar-icon';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toNumberWithComma,
} from '../../utils/formattor';

import DisbursementWeekIcon from '../../../images/icons/Disbursement1.png';
import DisbursementLastdayIcon from '../../../images/icons/Disbursement2.png';
import DisbursementThismonthIcon from '../../../images/icons/Disbursement3.png';
import DisbursementLastmonthIcon from '../../../images/icons/Disbursement4.png';
import DisbursementYearIcon from '../../../images/icons/Disbursement5.png';

import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { disbursmentExportUrl } from '../../utils/urls';

import "./style.css";

const Disbursment = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getDisbursmentData = (urlParams) => {
    disbursment(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt']
    };

    getDisbursmentData(urlParams);
    disbursmentChip(dispatch);
  }, []);

  const { disbursmentDetails, totalRecords, isFetching } = useSelector(
    (state) => state.disbursment
  );
  const { disbursmentChipData } = useSelector((state) => state.disbursmentChip);

  function dateTimeFormatter(cell) {
    return <label>{cell?moment(cell).format('DD-MM-YYYY hh:mm:ss'):'-'}</label>;
  }

  const disbursmentList =
    disbursmentDetails &&
    disbursmentDetails.map((disbursmentData, index) => {
      const id = index + 1;

      return {
        amount: disbursmentData.amount,
        tenure: disbursmentData.tenure,
        disbursmentDate: disbursmentData.createdAt,
        id,
        lenderName: disbursmentData.lenderName,
        mobileNumber: disbursmentData.mobileNumber,
        name: disbursmentData.name,
        loanNumber: disbursmentData.loanNumber,
        loanPublicId: disbursmentData.loanPublicId,
        status: disbursmentData.status,
        loanStatus: disbursmentData.loanStatus,
        userId: disbursmentData.userId,
        processingFees: disbursmentData.processingAmount,
        remarks: disbursmentData.remarks,
      };
    });

  function buttonFormatter(cell) {
    if (cell === 'closed') {
      return <label className="status-unpaid">{cell}</label>;
    }
      return <label className="label-status-paid">{cell}</label>;

  }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  function loanNumberHandle(cell, row) {
    return <Link to={`/loan/${row.loanPublicId}`}>{cell || '-'}</Link>;
  }

  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '80',
    },
    {
      columnClassName: '',
      dataField: 'loanNumber',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'tenure',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Tenure',
      width: '120',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'remarks',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Remarks',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Value',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'loanStatus',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Loan Status',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'disbursmentDate',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Disburse Date',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'lenderName',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Lender Name',
      width: '150',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Disbursement</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Report</Breadcrumb.Item>
                <Breadcrumb.Item active>Disbursments</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
          <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{disbursmentChipData &&
                        disbursmentChipData.week? toNumberWithComma(disbursmentChipData.week) :'0.00'}</h5>
                        <h3 className="heading-disbursement">Weekly Disbursed Amount</h3>
                        <p className='para-disbursement'>Total No of Amount Weekly Disbursed.
                        </p>
                        <img src={DisbursementWeekIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{disbursmentChipData &&
                        disbursmentChipData.lastDay? toNumberWithComma(disbursmentChipData.lastDay):'0.00'}</h5>
                        <h3 className="heading-disbursement">Last Day Disbursed Amount</h3>
                        <p className='para-disbursement'>Total No of Amount Last Day Disbursed.
                        </p>
                        <img src={DisbursementLastdayIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>

                   <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{disbursmentChipData &&
                        disbursmentChipData.thisMonth? toNumberWithComma(disbursmentChipData.thisMonth):'0.00'}</h5>
                        <h3 className="heading-disbursement">This Month Disbursed Amount</h3>
                        <p className='para-disbursement'>Total No of Amount This Month Disbursed.
                        </p>
                        <img src={DisbursementThismonthIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>

                      <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{disbursmentChipData &&
                         disbursmentChipData.lastMonth? toNumberWithComma(disbursmentChipData.lastMonth):'0.00'}</h5>
                        <h3 className="heading-disbursement">Last Month Disbursed Amount</h3>
                        <p className='para-disbursement'>Total No of Amount Last Month Disbursed.
                        </p>
                        <img src={DisbursementLastmonthIcon} alt='placeholder' />
                        </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className="chip">
                      <div className="chip-body">
                        <h5 className="num-disbursement">{disbursmentChipData &&
                        disbursmentChipData.calenderYear? toNumberWithComma(disbursmentChipData.calenderYear):'0.00'}</h5>
                        <h3 className="heading-disbursement">Year Disbursed Amount</h3>
                        <p className='para-disbursement'>Total No of Amount Year Disbursed.
                        </p>
                        <img src={DisbursementYearIcon}  alt='placeholder' />
                        </div>
                    </div>
                  </div>
              </div>
          {/* <div className="dashboard-disburment-card ">
            <div className="col-lg-3 col-md-6 col-sm-12 mpad">
              <ProgressBar
                percent="50"
                amount="56,296,011.00"
                text="Total Principal Disbursed INR"
                disbursement="22.2% more disbursement"
                color="blue-color"
                disbursementLabel=""
                disbursementSpan=""
                disbursementLabelText="Processing fees charged"
                disbursementSpanText="17,25,219.00"
              />
                 <div className="chip">
                      <div className="chip-body">
                        <h5 className="num">{dashboardDetailsData && dashboardDetailsData.activeLoan}</h5>
                        <h3 className="heading">Active Loan</h3>
                        <p className='para'>No of Active loans in our system</p>
                        <img src={RupeesIcon} alt='placeholder' />
                        </div>
                    </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mpad">
              <ProgressBar
                percent="75"
                amount="56,296,011.00"
                text="Total Principal Disbursed INR"
                disbursement="22.2% more disbursement"
                color="green-color"
                disbursementLabel="For average tenure days"
                disbursementSpan="170"
                disbursementLabelText=""
                disbursementSpanText=""
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mpad">
              <ProgressBar
                percent="27"
                amount="21,184,490.48"
                text="Principal Outstanding"
                disbursement="27.1% more default"
                color="red-color"
                disbursementLabel="For active loans"
                disbursementSpan="1454"
                disbursementLabelText=""
                disbursementSpanText=""
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mpad">
              <ProgressBar
                percent="27"
                amount="21,184,490.48"
                text="Principal Outstanding"
                disbursement="27.1% more default"
                color="red-color"
                disbursementLabel="For active loans"
                disbursementSpan="1454"
                disbursementLabelText=""
                disbursementSpanText=""
              />
            </div>
          </div> */}
          <div className="today-emi-sec">
            <DataTable
              tableData={disbursmentList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getDisbursmentData}
              exportUrl={disbursmentExportUrl}
              tableTitle="DISBURSEMENTS"
              pageFilters={disbursmentFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
      </div>
    </div>
  );
};

export default Disbursment;
