import {
  POLICY_DETAILS_REQUEST,
  POLICY_DETAILS_SUCCESS,
  POLICY_DETAILS_FAILURE,
} from './nucleon-profile-constants';

const policyDetailsRequest = (id) => ({
  id,
  type: POLICY_DETAILS_REQUEST,
});

export const policyDetailsSuccess = (data) => ({
  data,
  type: POLICY_DETAILS_SUCCESS,
});

export const policyDetailsFailure = () => ({
  type: POLICY_DETAILS_FAILURE,
});

export const policyDetails = async (id, dispatch) => {
  dispatch(policyDetailsRequest(id));
};
