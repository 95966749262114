import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { updateUtrUrl } from '../../utils/urls';

import { UPDATE_UTR_REQUEST } from './sent-utrs-constants';

import { updateUtrSuccess, updateUtrFailure } from './update-utr-action';

const { patchRequest } = new HttpHelper();

export function* updateUtr(action) {
  try {
    const url = updateUtrUrl(action.publicId);

    const response = yield call(patchRequest, {
      data: {
        ...action.userDetails,
      },
      url,
    });

    yield put(updateUtrSuccess());

    action.updateUtrSuccessCallback();

    Toastr.success(response.headers.message, 'Success Response');
  } catch (error) {
    yield put(updateUtrFailure());
  }
}

export function* watchUpdateUtr() {
  yield takeEvery(UPDATE_UTR_REQUEST, updateUtr);
}
