import {
  TEXT,
  NUMBER,
  DATE_RANGE_TYPE,
  SELECT,
} from '../../constants/data-table';

export const SENT_UTRS_REQUEST = 'SENT_UTRS_REQUEST';
export const SENT_UTRS_SUCCESS = 'SENT_UTRS_SUCCESS';
export const SENT_UTRS_FAILURE = 'SENT_UTRS_FAILURE';

export const UPDATE_UTR_REQUEST = 'UPDATE_UTR_REQUEST';
export const UPDATE_UTR_SUCCESS = 'UPDATE_UTR_SUCCESS';
export const UPDATE_UTR_FAILURE = 'UPDATE_UTR_FAILURE';

export const usersFilters = [
  {
    name: 'partner Loan Id',
    value: 'partnerLoanId',
    filterCondition: 'eq',
    // defaultFilterValue:['677','700'],
    placeholder: '',
    type: NUMBER,
  },
  {
    name: 'partner User Id',
    value: 'partnerUserId',
    filterCondition: 'eq',
    placeholder: '',
    type: NUMBER,
  },
  {
    name: 'Facility Type',
    value: 'facilityType',
    filterCondition: 'eq',
    options: [
      { name: 'Hospital', value: 'hospital' },
      { name: 'Pathology', value: 'pathology' },
      { name: 'Pharmacy', value: 'pharmacy' },
    ],
    type: SELECT,
  },
  {
    name: 'Account Number',
    value: 'accountNumber',
    filterCondition: 'eq',
    placeholder: '',
    type: TEXT,
  },
  {
    name: 'Status',
    value: 'status',
    filterCondition: 'eq',
    options: [
      { name: 'pending', value: 'pending' },
      { name: 'sent', value: 'sent' },
      { name: 'disbursed', value: 'disbursed' },
    ],
    type: SELECT,
  },
  {
    name: 'Bank Name',
    value: 'bankName',
    filterCondition: 'ilike',
    placeholder: 'HDFC Bank',
    type: TEXT,
  },
  {
    name: 'UTR',
    value: 'utr',
    filterCondition: 'eq',
    placeholder: '',
    type: TEXT,
  },
  {
    name: 'User Registered on',
    value: 'disbursementDate',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'disbursementDate',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'disbursementDate',
  },
  {
    name: 'User Registered on',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  },
];