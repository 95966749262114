import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { getHospitalDetailsUrl } from '../../utils/urls';
import { HOSPITAL_DETAILS_REQUEST } from './nucleon-profile-constants';
import { hospitalDetailsSuccess, hospitalDetailsFailure } from './hospital-details-action';

const { getRequest } = new HttpHelper();

export function* hospitalDetails(action) {
  try {
    const url = getHospitalDetailsUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    if(response.status === 200){
      yield put(hospitalDetailsSuccess(response.data));
    }

  } catch (error) {
    yield put(hospitalDetailsFailure());
  }
}

export function* watchHospitalDetails() {
  yield takeEvery(HOSPITAL_DETAILS_REQUEST, hospitalDetails);

}
