import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { getPolicyHoldersListUrl } from '../../utils/urls';
import { GET_POLICY_HOLDERS_REQUEST } from './nucleon-constants';
import { getPolicyHoldersFailure, getPolicyHoldersSuccess } from './get-policy-holders-action';

const { getRequest } = new HttpHelper();

export function* getPolicyHolders(action) {

  try {
    const response = yield call(getRequest, {
      url: getPolicyHoldersListUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(getPolicyHoldersSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(getPolicyHoldersFailure());
  }
}

export function* watchGetPolicyHolders() {
  yield takeEvery(GET_POLICY_HOLDERS_REQUEST, getPolicyHolders);
}
