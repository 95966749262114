import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';
import HttpHelper from '../../utils/http-helper';
import { emandateUrl } from '../../utils/urls';
import { EMANDATE_INFO_REQUEST } from './emandate-constants';
import { emandateInfoSuccess,emandateInfoFailure  } from './emandate-action';

const { postRequest } = new HttpHelper();

export function* emandatesaga(action) {
  try {
    const url = emandateUrl(action.data.emandateId);
    const response = yield call(postRequest, {
      data: {
        amount: action.data.amount
      },
      url,
    });

    if (response.error) {
      yield put(emandateInfoFailure());
      Toastr.error('Something Went Wrong', 'Failure');
    }
    if(response.status === 201 || response.status === 200){
      yield put(emandateInfoSuccess());
      Toastr.success('EMI emandate Successfully', 'Success');
    }
  } catch (error) {
    yield put(emandateInfoFailure(error));
  }
}

export function* watchEmandateNow() {
  yield takeEvery(EMANDATE_INFO_REQUEST, emandatesaga);
}
