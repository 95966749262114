import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { deletekycUrl } from '../../utils/urls';
import { DELETE_USER_KYC_REQUEST } from './profile-constants';
import { deleteKycFailure, deleteKycSuccess } from './delete-kyc-action';

const { patchRequest } = new HttpHelper();

export function* deleteKyc(action) {
  try {
    const url = deletekycUrl(action.id)
    const response = yield call(patchRequest, {
      headers: {
        'Content-Type': 'application/json',
        'x-coreplatform-concurrencystamp': action.concurrencyStamp,
      },
      url,
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(
        `${data.details[0].name} : ${data.details[0].message}`,
        'Failure'
      );
      yield put(deleteKycFailure());

    } else {
      Toastr.success(response.headers.message,'Success');
      yield put(deleteKycSuccess());
      action.deleteSuccessCallback();
    }
  } catch (error) {
    // Toastr.error(error, 'Failure Response');
  }
}

export function* watchDeleteKyc() {
  yield takeEvery(DELETE_USER_KYC_REQUEST, deleteKyc);
}
