import { HEALTH_CARE_LOAN_FAILURE, HEALTH_CARE_LOAN_SUCCESS, HEALTH_CARE_LOAN_REQUEST } from './health-care-loan-constants';

const initialState = {
  isFetching: false,
  healthCareLoanDetails: [],
  totalRecords: '',
};

const healthCareLoan = (state = initialState, action) => {
  switch (action.type) {
    case HEALTH_CARE_LOAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        healthCareLoanDetails: [],
      };
    case HEALTH_CARE_LOAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        healthCareLoanDetails: [],
      };
    case HEALTH_CARE_LOAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        healthCareLoanDetails: action.data,
        totalRecords: action.totalRecords,
      };
    default:
      return state;
  }
};

export default healthCareLoan;
