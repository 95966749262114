import { TEXT, TEXT_EDITABLE_SELECT_BOX, DATE,VERIFY_TEXT } from '../../../constants/field-value-key';

export const userDetailsKeyValueMap = [
  {
    label: 'User Type',
    key: 'employmentType',
    type: TEXT_EDITABLE_SELECT_BOX,
    options: [
      { value: 'salaried', name: 'Salaried' },
      { value: 'self-employed', name: 'Self-Employed' },
      { value: 'student', name: 'Student' },
      { value: 'retired', name: 'Retired' },
      { value: 'homemaker', name: 'Homemaker' },
      { value: 'pensioner', name: 'Pensioner' }

    ],
    name: 'employmentType',
    value: '',
  },
  {
    label: 'Salary Slab',
    key: 'minMonthlyIncome',
    key2: 'maxMonthlyIncome',
    type: TEXT_EDITABLE_SELECT_BOX,
    options: [
      { value: '10000-29999', name: '10000-29999' },
      { value: '30000-40000', name: '30000-40000' },
      { value: '40001-49999', name: '40001-49999' },
      { value: '50000-100000', name: '50000-100000' },
      { value: '100001-1000000', name: '100001-1000000' }
    ],
    name: 'minMonthlyIncome',
    name2: 'maxMonthlyIncome',
    value: ''
  },
  {
    label: 'Phone',
    key: 'mobileNumber',
    type: TEXT
  },
  {
    label: 'Primary Email',
    key: 'primaryEmail',
    type: TEXT
  },
  {
    label: 'Date of Birth',
    key: 'dob',
    type: DATE
  },
  {
    label: 'Work Email',
    key: 'workEmail',
    verifyStatusKey: 'isWorkEmailVerified',
    type: VERIFY_TEXT
  },
  {
    label: 'Qualification',
    key: 'qualification',
    type: TEXT
  },
  {
    label: 'Marital Status',
    key: 'maritalStatus',
    type: TEXT
  },
  {
    label: 'Company Name',
    key: 'companyName',
    type: TEXT
  }
]