import {
  INCREASE_VALIDITY_FAILURE,
  INCREASE_VALIDITY_REQUEST,
  INCREASE_VALIDITY_SUCCESS,
} from './profile-constants';

const initialState = {
  error: false,
  isUpdating: false,
};

const increaseValidity = (state = initialState, action) => {
  switch (action.type) {
    case INCREASE_VALIDITY_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case INCREASE_VALIDITY_FAILURE:
      return {
        ...state,
        error: true,
        isUpdating: false,
      };
    case INCREASE_VALIDITY_SUCCESS:
      return {
        ...state,
        error: false,
        isUpdating: false,
      };
    default:
      return state;
  }
};

export default increaseValidity;
