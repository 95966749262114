import {
  GURANTOR_UPLOAD_DOCS_REQUEST,
  GURANTOR_UPLOAD_DOCS_SUCCESS,
  GURANTOR_UPLOAD_DOCS_FAILURE,
} from './profile-constants';

const gurantoruploadDocsRequest = (documentIds, id ,concurrencyStamp,callback) => ({
  concurrencyStamp,
  documentIds,
  id,
  callback,
  type: GURANTOR_UPLOAD_DOCS_REQUEST,
});

export const gurantoruploadDocsSuccess = () => ({
  type: GURANTOR_UPLOAD_DOCS_SUCCESS,
});
export const gurantoruploadDocsFailure = () => ({
  type: GURANTOR_UPLOAD_DOCS_FAILURE,
});

export const gurantoruploadDocs = async (
  documentIds,
  id,
  concurrencyStamp,
  dispatch,
  callback
) => {
  dispatch(gurantoruploadDocsRequest(documentIds, id, concurrencyStamp,callback));
};
