/* eslint-disable max-lines */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { loan } from '../loan/loan-action';
import { dashboardCard } from '../dashboard/dashboard-card-action';
import { loanFilters } from '../loan/loan-constants';
// import { dashboardReportsAction } from '../dashboard/dashboard-reports-actions';

import Header from '../../components/header';
// import ProgressBar from '../../components/progress-bar';
import Footer from '../../components/footer';
import {
  toNumberWithCommaAndDecimal,
  toDateTimeFormat,
} from '../../utils/formattor';
// import UsersIcon from '../../../images/icons/users.png';
// import RupeesIcon from '../../../images/icons/rupees.png';
// import SavingsIcon from '../../../images/icons/savings.png';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { assanCreditloanExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';

const AssanCreditsLoan = () => {
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const getLoanData = (urlParams) => {
    loan( { ...urlParams,'filters[10][ilike]': 'Aasaan credits personal loan','filters[10][key]': 'partnerName' }, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };
    // eslint-disable-next-line no-restricted-globals
    const searchParam = location.search;

    if (searchParam) {
      const filterKey = new URLSearchParams(searchParam);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue')

      setDefaultFilter(loanFilters, value, filterOnValue);
    } else {
      getLoanData(urlParams);
    }
    dashboardCard(dispatch);
  }, []);

  const { loanDetails, totalRecords, isFetching } = useSelector(
    (state) => state.loan
  );

  // const { dashboardDetailsData } = useSelector((state) => state.dashboardReportsReducer);

  // const { dashboardCardDetails } = useSelector((state) => state.dashboardCard);

  const loanList =
    loanDetails &&
    loanDetails.map((loanData, index) => {
      const id = index + 1;

      return {
        ...loanData,
        id,
      };
    });

  function loanNumberHandle(cell, row) {
    return <Link to={`/loan/${row.publicId}`}>{cell || '-'}</Link>;
  }

  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function buttonFormatter(cell) {
    if (cell === 'closed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'active') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="label-status-initiated">{cell}</label>;
  }
  const dateFormatter = (cell) => {
    if (cell) {
      return toDateTimeFormat(cell);
    }
    return '-';
  };

  const daysFormatter = (cell) => {
    return <label className="status-partialpaid">{cell}</label>;
  };

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '40',
    },
    {
      columnClassName: '',
      dataField: 'number',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'startDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Date',
      width: '130',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '170',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Loan Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'emiAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Emi Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'totalPaid',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Total Paid',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'tenure',
      dataFormat: daysFormatter,
      dataSort: true,
      isKey: false,
      name: 'tenure',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'interestRate',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'ROI',
      width: '70',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'paidAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Paid Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'netAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Net Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'balanceDueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Bal. Due Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'lastPaymentDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Last Payment Date',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'partnerName',
      dataFormat: buttonFormatter,
      dataSort: false,
      isKey: false,
      name: 'Partner Name',
      width: '140',
    },
  ];

//  const { loans } = dashboardCardDetails;

  return (
    <div>
      <Header />
      <div className="common-container">
        {/* <SidebarIcon addClassCallBack={addClassCallBack} show={show} /> */}
        <div className={`common-wrapper ${show ? 'active' : ''} `} style={{ width: "100%" }}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Loans</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Aassan Credits Loan</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="loan-container">
            <DataTable
              tableData={loanList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getLoanData}
              exportUrl={assanCreditloanExportUrl}
              tableTitle="Aassan Credits Loan"
              pageFilters={loanFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
        {/* <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div> */}
      </div>
    </div>
  );
};

export default AssanCreditsLoan;
