import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';
import { SEND_GENERATED_LEADS_URL } from '../../utils/urls';
import { SEND_GENERATED_LEADS_REQUEST } from './send-email-to-lender-constants';
import { sendGeneratedLeadsSuccess, sendGeneratedLeadsFailure } from './send-generated-leads-action';

const { postRequest } = new HttpHelper();

export function* sendGeneratedLeads(action) {
  try {
    const response = yield call(postRequest, {
      url: SEND_GENERATED_LEADS_URL,
      data: { loanIds: action.data },
    });

    if (response.error) {
      const { data } = response.error.response;

      Toastr.error(data.details[0].message, 'Failure');

      yield put(sendGeneratedLeadsFailure());

    } else {
      yield put(sendGeneratedLeadsSuccess());

      Toastr.success('UTRs has been Mailed', 'Success');

      action.sendSuccessCallback();
    }

  } catch (err) {
    throw new Error(err);
  }
}

export function* watchSendGeneratedLeads() {
  yield takeEvery(SEND_GENERATED_LEADS_REQUEST, sendGeneratedLeads);
}
