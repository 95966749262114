import {
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAILURE,
} from './manage-product-constants';

const ProductRequest = (urlParams) => ({
  type: PRODUCT_REQUEST,
  urlParams,
});

export const ProductSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: PRODUCT_SUCCESS,
});
export const ProductFailure = () => ({
  type: PRODUCT_FAILURE,
});

export const getProduct = async (urlParams, dispatch) => {
  dispatch(ProductRequest(urlParams));
};
