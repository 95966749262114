import {
  DATE_RANGE_TYPE,
} from '../../constants/data-table';

export const DAILY_REPORT_REQUEST = 'DAILY_REPORT_REQUEST';
export const DAILY_REPORT_SUCCESS = 'DAILY_REPORT_SUCCESS';
export const DAILY_REPORT_FAILURE = 'DAILY_REPORT_FAILURE';

export const dailyReportFilters = [
  {
    name: 'Date Range',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  },
];
