import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { loanCallLogUrl } from '../../utils/urls';
import { LOAN_CALL_REQUEST } from './loan-call-log-constants';
import { loanCallLogSuccess, loanCallLogFailure } from './loan-call-log-action';

const { getRequest } = new HttpHelper();

export function* loanCall(action) {
  try {
    const url = loanCallLogUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    yield put(loanCallLogSuccess(response.data));
  } catch (error) {
    yield put(loanCallLogFailure());
  }
}

export function* watchLoanCall() {
  yield takeEvery(LOAN_CALL_REQUEST, loanCall);
}
