import {
  LOAN_CALL_REQUEST,
  LOAN_CALL_SUCCESS,
  LOAN_CALL_FAILURE,
} from './loan-call-log-constants';

const initialState = {
  isFetching: false,
  loanCallLogDetails: [],
};

const loanCall = (state = initialState, action) => {
  switch (action.type) {
    case LOAN_CALL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOAN_CALL_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case LOAN_CALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        loanCallLogDetails: action.data,
      };
    default:
      return state;
  }
};

export default loanCall;
