import {
  DELETE_USER_KYC_FAILURE,
  DELETE_USER_KYC_SUCCESS,
  DELETE_USER_KYC_REQUEST,
} from './profile-constants';

const deleteKycRequest = (id,concurrencyStamp,deleteSuccessCallback) => ({
  id,
  concurrencyStamp,
  deleteSuccessCallback,
  type: DELETE_USER_KYC_REQUEST,
});

export const deleteKycSuccess = () => ({
  type: DELETE_USER_KYC_SUCCESS,
});

export const deleteKycFailure = () => ({
  type: DELETE_USER_KYC_FAILURE,
});

export const deleteKyc = (
  id,
  concurrencyStamp,
  deleteSuccessCallback,
  dispatch
) => {
  dispatch(deleteKycRequest(id,concurrencyStamp,deleteSuccessCallback));
};
