import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { policyDetailsUrl } from '../../utils/urls';
import { POLICY_DETAILS_REQUEST } from './nucleon-profile-constants';
import { policyDetailsFailure, policyDetailsSuccess } from './policy-details-action';

const { getRequest } = new HttpHelper();

export function* policyDetails(action) {
  try {
    const url = policyDetailsUrl(action.id);

    const response = yield call(getRequest, {
      url,
    });

    yield put(policyDetailsSuccess(response.data));
  } catch (error) {
    yield put(policyDetailsFailure());
  }
}

export function* watchPolicyDetails() {
  yield takeEvery(POLICY_DETAILS_REQUEST, policyDetails);

}
