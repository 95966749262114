import {
  TEXT,
  NUMBER,
  NUMBER_RANGE_TYPE,
  DATE_RANGE_TYPE,
} from '../../constants/data-table';

export const LOAN_DPD_REQUEST = 'LOAN_DPD_REQUEST';
export const LOAN_DPD_SUCCESS = 'LOAN_DPD_SUCCESS';
export const LOAN_DPD_FAILURE = 'LOAN_DPD_FAILURE';

export const usersFilters = [
  {
    name: 'Name',
    value: 'name',
    filterCondition: 'ilike',
    placeholder: 'Rahul Kumar',
    type: TEXT,
  },
  {
    name: 'Mobile Number',
    value: 'mobileNumber',
    filterCondition: 'eq',
    placeholder: '9876543263',
    type: NUMBER,
  },
  {
    name: 'Bank Name',
    value: 'bankName',
    filterCondition: 'ilike',
    placeholder: 'HDFC Bank',
    type: TEXT,
  },
  {
    name: 'State name',
    value: 'stateName',
    filterCondition: 'ilike',
    placeholder: 'Haryana',
    type: TEXT,
  },
  {
    name: 'DPD',
    value: 'dpd',
    filterCondition: 'eq',
    minPlaceholder: 'min value',
    maxPlaceholder: 'max value',
    minFilterCondition: 'gte',
    maxFilterCondition: 'lte',
    type: NUMBER_RANGE_TYPE,
  },
  {
    name: 'Partner Name',
    value: 'partnerName',
    filterCondition: 'ilike',
    placeholder: 'Ganesh Leasfin',
    type: TEXT,
  },
  {
    name: 'Disbursement Date',
    value: 'disbursementDate',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'disbursementDate',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'disbursementDate',
  },
  {
    name: 'Loan Date',
    value: 'createdAt',
    filterCondition: 'eq',
    type: DATE_RANGE_TYPE,
    startDatevalue: 'createdAt',
    startDateFilterCondition: 'gte',
    endDateFilterCondition: 'lte',
    endDatevalue: 'createdAt',
  },
];
