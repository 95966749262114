import {
  SENT_UTRS_FAILURE,
  SENT_UTRS_SUCCESS,
  SENT_UTRS_REQUEST,
} from './sent-utrs-constants';

const initialState = {
  error: false,
  isFetching: false,
  totalRecords: '',
  sentUtrs: [],
};

const sentUtrs = (state = initialState, action) => {
  switch (action.type) {
    case SENT_UTRS_REQUEST:
      return {
        ...state,
        isFetching: true,
        sentUtrs: [],
      };
    case SENT_UTRS_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
        sentUtrs: [],
      };
    case SENT_UTRS_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
        totalRecords: action.totalRecords,
        sentUtrs: action.data,
      };
    default:
      return state;
  }
};

export default sentUtrs;
