import { SELECT } from '../../../constants/form-type';

export const generateLeadConstants = [
  {
    label: 'Choose Partner',
    name: 'partnerId',
    placeholder: '',
    validation: { isRequired: true, isEmail: false, isMobile: false },
    type: SELECT,
    isDisabled: false,
    options: [],
  },
];
