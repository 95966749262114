import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { userDetailsKeyValueMap } from './policy-holder-address-details-constants';
import FieldValue from '../../field-key-value';
import './policy-holder-address-details.css';

const PolicyHolderAddressDetails = ({ userAddressDetails, setViewAddressDetail  }) => {
  const [addressDetailPopup, setAddressDetailPopup] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState({});

  useEffect(() => {
    setDefaultAddress(userAddressDetails[userAddressDetails.length-1]);
  }, []);

  const addAddressDetailHandle = () => {
    setAddressDetailPopup(false);
    setViewAddressDetail(true);
  };

  // const editAddressDetailHandle = () => {
  //     setAddressDetailPopup(false);
  //     setEditAddressDetail(true);
  // };

  return (
    <div className="personal-address-details">
      <div className="personal-heading-container">
        <div className="d-flex align-items-baseline">
          <h4 className="personal-common-heading">
                        Address Details
          </h4>
          <label className="ml-3 grey-label">Default</label>
        </div>
        <MoreVertIcon
          onClick={() =>
            setAddressDetailPopup(!addressDetailPopup)
          }
          className="more-vertical-icon"
        />
      </div>
      {addressDetailPopup && (
        <div className="profile-edit-view">
          <p>
            <label onClick={addAddressDetailHandle}>
                            View All
            </label>
            {/* <label onClick={addAddressDetailHandle}>
                            Edit
                        </label> */}
          </p>
        </div>
      )}
      <div className="user-profile-address-details-wrapper">
        {userDetailsKeyValueMap.map((item, index) =>
          <FieldValue
            key={index}
            fieldName={item.label}
            fieldValue={defaultAddress && defaultAddress[item.key]}
            type={item.type}
          />
        )}
      </div>
    </div>
  );
}

export default PolicyHolderAddressDetails;