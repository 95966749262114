import React from 'react';
import { Link } from 'react-router-dom';

import {
  toDateFormat,
  // toDateTimeFormat,
  toNumberWithCommaAndDecimal,
} from '../../utils/formattor';
// payments
const LoanInformationCard = ({ loanInfoDetails, profile,disbursement }) => (
  <div className="loan-inform">
    <div className="loan-content">
      <Link to={`/users/${loanInfoDetails.userId}`} className="loan-content-name">{profile && profile.name}</Link>
      <span>{`( ${loanInfoDetails.number} )`}</span>
    </div>
    <div className="loan-tabel">
      <ul>
        <li>
          <label>Loan Status</label>
          <span>{loanInfoDetails.status}</span>
        </li>
        <li>
          <label>Loan Amount</label>
          <span>{toNumberWithCommaAndDecimal(loanInfoDetails.amount)}</span>
        </li>
        <li>
          <label>Loan Tenure</label>
          <span>{loanInfoDetails.tenure}</span>
        </li>
        <li>
          <label>Interest Rate</label>
          <span>{`${loanInfoDetails.interestRate}%`}</span>
        </li>
        <li>
          <label>Bank Name</label>
          <span>{profile && profile.bankName ? profile.bankName : '-'}</span>
        </li>
        <li>
          <label>AC Number</label>
          <span>
            {profile && profile.bankDetail ? profile.bankDetail[0].accountNumber : '-'}
          </span>
        </li>
        <li>
          <label>AC Holder Name</label>
          <span>{profile && profile.name ? profile.name : '-'}</span>
        </li>
        <li>
          <label>IFSC</label>
          <span>{profile && profile.bankDetail ? profile.bankDetail[0].ifsc : '-'}</span>
        </li>
        <li>
          <label>Last Salary Date</label>
          <span>
            {profile && profile.lastMonthSalaryDate
              ? toDateFormat(profile.lastMonthSalaryDate)
              : '-'}
          </span>
        </li>
        <li>
          <label>Bounce Charges</label>
          <span>{loanInfoDetails.bounceCharges}</span>
        </li>
      </ul>
    </div>
    <div className="loan-content">
      <span>EMANDATE</span>
    </div>
    <div className="loan-tabel">
      <ul>
        <li>
          <label>Razor pay Emandate ID</label>
          <span className="tooltip-loan" data-tooltip={loanInfoDetails.razorpayEmandateId}>
            {loanInfoDetails.razorpayEmandateId ? loanInfoDetails.razorpayEmandateId : '-'}
          </span>
        </li>
        <li>
          <label>Razor Payout ID</label>
          <span className="tooltip-loan" data-tooltip={loanInfoDetails.razorpayPayoutId}>
            {loanInfoDetails.razorpayPayoutId ? loanInfoDetails.razorpayPayoutId : '-'}
          </span>
        </li>
        <li>
          <label>AC Number</label>
          <span>
            {profile && profile.bankDetail ? profile.bankDetail[0].accountNumber : '-'}
          </span>
        </li>
        <li>
          <label>AC Holder Name</label>
          <span>{profile && profile.name ? profile.name : '-'}</span>
        </li>
      </ul>
    </div>
    <div className="loan-content">
      <span>DISBURSAL</span>
    </div>
    <div className="loan-tabel">
      <ul>
        <li>
          <label>PayOut Id</label>
          <span className="tooltip-loan"
            data-tooltip={disbursement && disbursement.publicId}>
            {disbursement && disbursement.publicId?disbursement.publicId:'-'}</span>
        </li>
        <li>
          <label>Disbursed Date</label>
          <span>{disbursement && disbursement.createdAt?toDateFormat(disbursement.createdAt):'-'}</span>
        </li>
        <li>
          <label>Loan Amount</label>
          <span>{toNumberWithCommaAndDecimal(loanInfoDetails.amount)}</span>
        </li>
        <li>
          <label>Processing Fees</label>
          <span>{toNumberWithCommaAndDecimal(loanInfoDetails.processingAmount)}</span>
        </li>
        <li>
          <label>platform Fee Amount</label>
          <span>{loanInfoDetails.platformFeeAmount ? loanInfoDetails.platformFeeAmount : '-'}</span>
        </li>
        <li>
          <label>Disbursed Amount</label>
          <span>{toNumberWithCommaAndDecimal(disbursement && disbursement.amount?(disbursement.amount):'-')}</span>
        </li>
      </ul>
    </div>
  </div>
);

export default LoanInformationCard;
