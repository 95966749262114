import {
  POLICY_HOLDER_ADDRESS_FAILURE,
  POLICY_HOLDER_ADDRESS_REQUEST,
  POLICY_HOLDER_ADDRESS_SUCCESS,
} from './nucleon-profile-constants';

const policyHolderAddressRequest = (id) => ({
  id,
  type: POLICY_HOLDER_ADDRESS_REQUEST,
});

export const policyHolderAddressSuccess = (data) => ({
  data,
  type: POLICY_HOLDER_ADDRESS_SUCCESS,
});
export const policyHolderAddressFailure = () => ({
  type: POLICY_HOLDER_ADDRESS_FAILURE,
});

export const policyHolderAddress = async (id, dispatch) => {
  dispatch(policyHolderAddressRequest(id));
};
