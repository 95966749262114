import { call, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { getHospitalListURL } from '../../utils/urls';
import { HOSPITAL_LIST_REQUEST } from './nucleon-profile-constants';

const { getRequest } = new HttpHelper();

export function* hospitalList(action) {
  try {
    const response = yield call(getRequest, {
      url: getHospitalListURL(),
    });

    action.data.hospitalListCallback(response.data);
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchHospitalList() {
  yield takeEvery(HOSPITAL_LIST_REQUEST, hospitalList);
}
