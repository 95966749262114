import {
  LOAN_DPD_FAILURE,LOAN_DPD_SUCCESS,LOAN_DPD_REQUEST
} from './loan-dpd-constants';

const loanDpdRequest = (urlParams) => ({
  type: LOAN_DPD_REQUEST,
  urlParams,
});

export const loanDpdSuccess = (data, totalRecords) => ({
  data,
  totalRecords,
  type: LOAN_DPD_SUCCESS,
});
export const loanDpdFailure = () => ({
  type: LOAN_DPD_FAILURE,
});

export const loanDpd = async (urlParams, dispatch) => {
  dispatch(loanDpdRequest(urlParams));
};
