import React from 'react';
import './button.css';

const Button = ({ text, click, isMarginLeft, isGreyedOut }) => {
  return (
    <React.Fragment>
      <button
        className={`common-black-border-btn${
          isMarginLeft ? ' common-button-margin-left' : ''
        }${isGreyedOut ? ' common-button-grey-out' : ''}`}
        onClick={click}
      >
        <span>{text}</span>
      </button>
    </React.Fragment>
  );
};

export default Button;
