// @flow
import { combineReducers } from 'redux';

// Authentication Reducers.
import signin from '../containers/sign-in/signin-reducer';
import changePassword from '../containers/change-password/change-password-reducer';
import resetPassword from '../containers/reset-password/reset-password-reducer';

import manageUsers from '../containers/manage-users/manage-users-reducer';
import managePincode from '../containers/manage-pincode/manage-pincode-reducer';
import manageProfession from '../containers/manage-profession/manage-profession-reducer';
import manageBank from '../containers/manage-bank/manage-bank-reducer';
import profileDetails from '../containers/profile/profile-details-reducer';
import addUser from '../containers/manage-users/add-user-reducer';
import addCallLogs from '../containers/profile/add-call-logs-reducer';
import callLogs from '../containers/profile/call-logs-reducer';
import editUser from '../containers/manage-users/edit-user-reducer';
import generatePassword from '../containers/manage-users/generate-password-reducer';
import editBank from '../containers/manage-bank/edit-bank-reducer';
import bankDetails from '../containers/manage-bank/bank-details-reducer';
import userAddress from '../containers/profile/user-address-reducer';
import addAddress from '../containers/profile/address-reducer';
import blockUser from '../containers/profile/block-unblock-reducer';
import userProfile from '../containers/profile/user-profile-reducer';
import editPincode from '../containers/manage-pincode/edit-pincode-reducer';
import collection from '../containers/collections/collections-reducer';
import collectionChip from '../containers/collections/collections-chip-reducer';
import defaults from '../containers/defaults/defaults-reducer';
import emi from '../containers/emi/emi-reducer';
import emiChip from '../containers/emi/emi-chip-reducer';
import loan from '../containers/loan/loan-reducer';
import leadsLoan from '../containers/leads-loan/loan-reducer';
import loanInfo from '../containers/loan-information/loan-information-reducer';
import disburseLoanNow from '../containers/loan-information/disburse-loan-reducer';
import payments from '../containers/payments/payments-reducer';
import disbursment from '../containers/disbursment/disbursment-reducer';
import disbursmentChip from '../containers/disbursment/disbursment-chip-reducer';
import dashboard from '../containers/dashboard/dashboard-reducer';
import recentActivity from '../containers/dashboard/recent-activity-reducer';
import newRegistration from '../containers/dashboard/new-registration-reducer';
import exportCsv from '../containers/dashboard/export-csv-reducer';
import dashboardCard from '../containers/dashboard/dashboard-card-reducer';
import viewFile from '../containers/dashboard/view-file-reducer';
import uploadDocs from '../containers/profile/upload-docs-reducer';
import gurantoruploadDocs from '../containers/profile/gurantor-upload-docs-reducer';
import paymentDetails from '../components/side-bar/payment-details-reducer';
import loanPayment from '../containers/loan-information/loan-payment/payment-reducer';
import managePartner from '../containers/manage-partner/manage-partner-reducer';
import viewPartner from '../containers/view-partner/view-partner-reducer';
import users from '../containers/users/users-reducer';
import addAndEditBank from '../containers/profile/bank-reducer';
import userBlock from  '../containers/dashboard/user-block-reducer';
import loanBlock from  '../containers/dashboard/loan-block-reducer';
import loanAction from '../containers/loan-information/loan-actions/loan-actions-reducer';
import paymentsLoan from '../containers/loan-information/payment-details/payments-reducer';
import emandatereducer from '../containers/loan-information/emandate-reducer';
import increaseValidity from '../containers/profile/increase-validity-reducer';
import getCallLogs from '../containers/users/get-daily-reports-reducer';
import dashboardReportsReducer from '../containers/dashboard/dashboard-reports-reducer';
import getDefaultReports from '../containers/defaults/defaults-report-reducer';
import getUserAddressLocation from '../containers/profile/user-address-location-reducer';
import getUserSimDetails from '../containers/profile/user-sim-details-reducer';
import loanCall from '../containers/loan-information/loan-call-log-reducer';
import hospitalDetailsData from '../containers/neuclon-profile/hospital-details-reducer';
import addHealthCareUser from '../containers/neucleon/add-neuclon-user/add-health-care-user-reducer';
import getPolicyHolders from '../containers/neucleon/get-policy-holders-reducer';
import policyDetails from '../containers/neuclon-profile/policy-details-reducer';
import uploadDocsPolicyHolder from '../containers/neuclon-profile/upload-docs-policy-holder-reducer';
import addPolicyHolderAddress from '../containers/neuclon-profile/add-policy-holder-address-reducer';
import policyHolderAddress from '../containers/neuclon-profile/policy-holder-address-reducer';
import addPatientLoan from '../containers/neuclon-profile/add-patient-loan-reducer';
import healthCareLoan from '../containers/health-care-loan/health-care-loan-reducer';
import healthCareLoanInfo from '../containers/health-care-loan-info/health-care-loan-info-reducer';
import sendEmailToLender from '../containers/send-email-to-lender/send-email-to-lender-reducer';
import sendGeneratedLeads from '../containers/send-email-to-lender/send-generated-leads-reducer';
import generateLoanLead from '../containers/neuclon-profile/generate-loan-lead-reducer';
import sentUtrs from '../containers/sent-utrs/sent-utrs-reducer';
import updateUtr from '../containers/sent-utrs/update-utr-reducer';
import loanDpd from '../containers/loan-dpd/loan-dpd-reducer';
import dailyReport from '../containers/daily-report/daily-report-reducer';
import deleteKyc from '../containers/profile/delete-kyc-reducer';
import getProduct from '../containers/manage-product/get-product-reducer';
import editProduct from '../containers/manage-product/edit-product-reducer';
// Root Reducer.
const reducers = combineReducers({
  addAddress,
  addCallLogs,
  addUser,
  bankDetails,
  blockUser,
  callLogs,
  changePassword,
  collection,
  collectionChip,
  dashboard,
  dashboardCard,
  defaults,
  disbursment,
  disbursmentChip,
  editBank,
  editPincode,
  editUser,
  emi,
  emiChip,
  emandatereducer,
  exportCsv,
  generatePassword,
  loan,
  leadsLoan,
  loanInfo,
  manageBank,
  managePincode,
  manageProfession,
  manageUsers,
  newRegistration,
  paymentDetails,
  payments,
  profileDetails,
  recentActivity,
  resetPassword,
  signin,
  uploadDocs,
  gurantoruploadDocs,
  userAddress,
  userProfile,
  viewFile,
  loanPayment,
  managePartner,
  viewPartner,
  users,
  disburseLoanNow,
  addAndEditBank,
  userBlock,
  loanBlock,
  loanAction,
  paymentsLoan,
  increaseValidity,
  getCallLogs,
  dashboardReportsReducer,
  getDefaultReports,
  getUserAddressLocation,
  getUserSimDetails,
  loanCall,
  hospitalDetailsData,
  addHealthCareUser,
  getPolicyHolders,
  policyDetails,
  uploadDocsPolicyHolder,
  addPolicyHolderAddress,
  policyHolderAddress,
  addPatientLoan,
  healthCareLoan,
  healthCareLoanInfo,
  sendEmailToLender,
  sendGeneratedLeads,
  generateLoanLead,
  sentUtrs,
  updateUtr,
  loanDpd,
  dailyReport,
  deleteKyc,
  getProduct,
  editProduct,
});

export default reducers;
