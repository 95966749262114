import React from 'react';
import ResponsiveTableCustom from '../../components/responsive-normal-data-table';

const LoanCallLog = ({ loanDetails  }) => {

  const tableConstant = [

    {
      name: 'Category',
      dataFormat: (cell) =>
        cell,
      dataKey: 'category'
    },
    {
      name: 'Created At',
      dataFormat: (cell) =>
        cell,
      dataKey: 'createdAt',
    },
    {
      name: 'Created By',
      dataFormat: (cell) =>
        cell,
      dataKey: 'name',
    },
    {
      name: 'Description',
      dataFormat: (cell) =>
        cell,
      dataKey: 'description',
    },

    {
      name: 'Dispositions',
      dataFormat: (cell) =>
        cell,
      dataKey: 'dispositions',
    },
  ];

  return (
    <div className="repayment-sec">
      <div className="repayment-sec-heading">
        <h2>LOAN CALL LOG</h2>
      </div>
      <div className="repayment-sec-table">
        {loanDetails && (
          <ResponsiveTableCustom
            tableHeaderData={tableConstant}
            tableData={loanDetails}
          />
        )}
      </div>
    </div>
  );
};

export default LoanCallLog;
