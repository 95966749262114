import React from 'react';
import Utils from '../../../utils/index';

const LeftProfileInfo = ({ profileData, getUserProfilePic, setFileUploadPopup,fileUploadPopup, setDeleteWithReason,
  deleteWithReason, isUserBlocked, handleReload, getUserReportUrl, increaseValidityHandler, deleteKycHandler, 
  setUpdateCrifScorePopup, setDiscountFormPopup }) => {

  const [role,setRole] = React.useState('system-admin');

  React.useEffect(() => {

    const getRole = new Utils();

    setRole(getRole.getUserRole());

  },[role]);

  return (<React.Fragment>
    <div className="profile-details-container">
      <div className="profile-image">
        {profileData && profileData.profilePicId && (
          <img
            alt="Profile Image"
            className="user-profile-image"
            src={getUserProfilePic(profileData.profilePicId)}
            crossOrigin="anonymous"
          />
        )}
      </div>
      <label className="profile-referal">
                Referal code
        <span>
                    &nbsp;
          {(profileData && profileData.referralCode) || '-'}
        </span>
      </label>
      {/* <label className="profile-referal">
                      Referal by<span>&nbsp;Siddhant</span>
                    </label> */}
    </div>
    <div className="profile-crif-score">
      <div className="profile-crif-score-container">
        <label>Crif Score</label>
        <span>
          {(profileData && profileData.crifScore) || '-'}
        </span>
      </div>
      <div className="profile-crif-score-btn-container" style={{ marginBottom: '10px' }}>
        {(role !== 'call-center-staff' && role !== 'collection-staff') && <button
          onClick={() => setUpdateCrifScorePopup(true)}
          className="black-border-btn black-border-reload"
        >
                    Update Crif score
        </button>}
        <br/>
      </div>
      <div className="profile-crif-score-btn-container" style={{ marginBottom: '10px' }}>
        {(role !== 'credit-manager' && role !== 'call-center-staff' && role !== 'collection-staff') && <button
          onClick={() => setDiscountFormPopup(true)}
          className="black-border-btn black-border-reload"
        >
                    Add Discount
        </button>}
        <br/>
      </div>
      <div className="profile-crif-score-btn-container">

        <button
          onClick={handleReload}
          className="black-border-btn black-border-reload"
        >
                    Reload
        </button>
        <button className="black-border-btn black-border-reload">
          <a href={getUserReportUrl(profileData.panNumber)} target="_blank" style={{ color: 'inherit' }}>
                        Report
          </a>
        </button>
      </div>
    </div>
    <div className="profile-upload-document">
      <h5>UPLOAD DOCUMENT</h5>
      <p>only .jpg, .png, .pdf file allowed</p>
      <div className="profile-upload-document-btn">
        <button
          onClick={() => setFileUploadPopup(!fileUploadPopup)}
          className="black-border-btn black-border-browse"
        >
                    Browse
        </button>
      </div>
    </div>
    <div className="profile-upload-document">
      {(role !== 'call-center-staff' && role !== 'collection-staff') &&
     <div className="profile-upload-document">
       <h5>INCREASE VALIDITY</h5>
       <div className="profile-upload-document-btn">
         <button
           onClick={() => increaseValidityHandler()}
           className="black-border-btn black-border-browse"
         >
                    Increase
         </button>
       </div>
     </div>
      }
    </div>
    <div className="profile-upload-document">
      {(role !== 'call-center-staff' && role !== 'collection-staff') &&
     <div className="profile-upload-document">
       <h5>DELETE USER KYC FOR RE-KYC</h5>
       <div className="profile-upload-document-btn">
         <button
           onClick={() => deleteKycHandler()}
           className="black-border-btn black-border-browse"
         >
                    Delete KYC
         </button>
       </div>
     </div>
      }
    </div>
    <button
      className="black-border-btn black-border-block"
      onClick={() => setDeleteWithReason(!deleteWithReason)}
    >
      {!isUserBlocked ? 'Block User' : 'Unblock User'}
    </button>
  </React.Fragment>)
}

export default LeftProfileInfo;