/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Breadcrumb } from 'react-bootstrap';

import { loanDpd } from './loan-dpd-action';

import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import Footer from '../../components/footer';
import SidebarIcon from '../../components/sidebar-icon';
import { usersFilters } from './loan-dpd-constants';

import { loanDpdExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { toNumberWithCommaAndDecimal, toDateTimeFormat } from '../../utils/formattor';

const LoanDpd = ({ location }) => {
  // Filter State
  const [show, setShow] = useState(false);

  const { isFetching, users, totalRecords } = useSelector((state) => state.loanDpd);

  const usersList =
    users &&
    users.map((user, index) => {
      const id = index + 1;

      return {
        ...user,
        id,
      };
    });

  const dispatch = useDispatch();

  const getUsersData = (urlParams) => {
    loanDpd(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getUsersData(urlParams);
      }
      setDefaultFilter(usersFilters, value, filterOnValue);
    } else {
      getUsersData(urlParams);
    }
  }, []);

  function loanNumberHandle(cell, row) {
    return <Link to={`/loan/${row.publicId}`}>{cell || '-'}</Link>;
  }

  function userName(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function userMobileNo(cell, row) {
    return <Link to={`/users/${row.userId}`}>{cell}</Link>;
  }

  function buttonFormatter(cell) {
    if (cell === 'closed') {
      return <label className="status-unpaid">{cell}</label>;
    } else if (cell === 'active') {
      return <label className="label-status-paid">{cell}</label>;
    }
    return <label className="label-status-initiated">{cell}</label>;
  }
  const dateFormatter = (cell) => {
    if (cell) {
      return toDateTimeFormat(cell);
    }
    return '-';
  };

  const daysFormatter = (cell) => {
    return <label className="status-partialpaid">{cell}</label>;
  };

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const addClassCallBack = () => {
    setShow(!show);
  };

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '40',
    },
    {
      columnClassName: '',
      dataField: 'number',
      dataFormat: loanNumberHandle,
      dataSort: true,
      isKey: false,
      name: 'Loan No',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'startDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Date',
      width: '130',
    },
    {
      columnClassName: '',
      dataField: 'name',
      dataFormat: userName,
      dataSort: true,
      isKey: false,
      name: 'Name',
      width: '170',
    },
    {
      columnClassName: '',
      dataField: 'mobileNumber',
      dataFormat: userMobileNo,
      dataSort: false,
      isKey: false,
      name: 'Mobile No',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Loan Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'transferAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Transfer Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'emiAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Emi Amount',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'totalPaid',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Total Paid',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'dpd',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'DPD',
      width: '80',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'tenure',
      dataFormat: daysFormatter,
      dataSort: true,
      isKey: false,
      name: 'tenure',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'interestRate',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'ROI',
      width: '70',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'paidAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Paid Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'netAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Net Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'balanceDueAmount',
      dataFormat: numberFormatter,
      dataSort: true,
      isKey: false,
      name: 'Bal. Due Amount',
      width: '100',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'lastPaymentDate',
      dataFormat: dateFormatter,
      dataSort: true,
      isKey: false,
      name: 'Last Payment Date',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'status',
      dataFormat: buttonFormatter,
      dataSort: true,
      isKey: false,
      name: 'Status',
      width: '140',
    },
    {
      columnClassName: '',
      dataField: 'partnerName',
      dataFormat: buttonFormatter,
      dataSort: false,
      isKey: false,
      name: 'Partner Name',
      width: '140',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Loan DPD</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Loan DPD</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
          </div>
          <div className="loan-container mar0 mt-2">
            <DataTable
              tableData={usersList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getUsersData}
              exportUrl={loanDpdExportUrl}
              tableTitle="LOAN DPD"
              pageFilters={usersFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
      </div>
    </div>
  );
};

export default LoanDpd;
