import { SELECT, TEXT } from '../../../constants/form-type';

export const addAddressFormConstant = [
  {
    label: 'Flat No. House No. Building No.',
    name: 'addressLine1',
    placeholder: 'B-165, First Floor',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'Socity Area Stree',
    name: 'addressLine2',
    placeholder: 'Building name',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'Address Type: ',
    name: 'addressType',
    placeholder: 'Haryana',
    validation: { isRequired: true },
    options: [
      { name: 'Current', value: 'current' },
      { name: 'Permanent', value: 'permanent' },
      { name: 'Office', value: 'office' }
    ],
    isDisabled: false,
    type: SELECT
  },
  {
    label: 'Pin Code',
    name: 'pincode',
    placeholder: '124001',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'City',
    name: 'districtName',
    placeholder: 'New Delhi',
    validation: { isRequired: true },
    isDisabled: true,
    type: TEXT
  },
  {
    label: 'State',
    name: 'stateName',
    placeholder: 'Haryana',
    validation: { isRequired: true },
    options: [],
    isDisabled: true,
    type: SELECT
  },
  {
    label: 'Country',
    name: 'country',
    placeholder: 'India',
    validation: { isRequired: true },
    options: [
      { name: 'India', value: 'India' },
    ],
    isDisabled: true,
    type: SELECT
  }
]