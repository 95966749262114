/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import DataTable from 'moneyloji-react-data-table';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Breadcrumb, Modal } from 'react-bootstrap';

import { sentUtrs } from './sent-utrs-action';
import { updateUtr } from './update-utr-action';
import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import Footer from '../../components/footer';
import UtrEditAction from '../../components/Sent-Utr/utr-edit-action';
import SidebarIcon from '../../components/sidebar-icon';
import EditUtr from '../../components/Sent-Utr/edit-utr';
import { usersFilters } from './sent-utrs-constants';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';

import { userExportUrl } from '../../utils/urls';
import { setDefaultFilter } from '../../utils/default-filter-helper';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { toNumberWithCommaAndDecimal } from '../../utils/formattor';

const SentUTRs = ({ location }) => {
  // Filter State
  const [show, setShow] = useState(false);
  const [editUtrpopup, setEditUtrpopup] = useState(false);

  const [editUtrDetails, setEditUtrDetails] = useState({
    publicId: '',
    concurrencyStamp: '',
    utr: '',
    disbursementDate: '',
    facilityType: '',
    accountHolderName: '',
    accountNumber: '',
    amount: '',
    partnerLoanId: '',
    partnerUserId: '',
  });

  const { isFetching, sentUtrs: list, totalRecords } = useSelector((state) => state.sentUtrs);

  const usersList =
  list &&
  list.map((user, index) => {
    const id = index + 1;

    return {
      ...user,
      id,
    };
  });

  const dispatch = useDispatch();

  const getSentUTRsData = (urlParams) => {
    sentUtrs(urlParams, dispatch);
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getSentUTRsData(urlParams);
      }
      setDefaultFilter(usersFilters, value, filterOnValue);
    } else {
      getSentUTRsData(urlParams);
    }
  }, []);

  const userLoanNumber = (cell, row) => {
    return <Link to={`/health-care-loan/${row.loanId}`}>{cell || '-'}</Link>;
  };

  function userPolicyHolder(cell, row) {
    return <Link to={`/policy-holder/${row.policyHolderId}`}>{cell.mobileNumber}</Link>;
  }

  function dateTimeFormatter(cell) {
    return <label>{cell?moment(cell).format('DD-MM-YYYY hh:mm:ss'):'-'}</label>;
  }

  function facilityFormatter(cell) {
    if (cell === 'hospital') {
      return <label className="label-status-paid">{cell}</label>;
    }
    if (cell === 'pathology') {
      return <label className="status-unpaid">{cell}</label>;
    }
    return <label className="status-partialpaid">{cell}</label>;
  }

  const numberFormatter = (cell) => {
    if (cell) {
      return toNumberWithCommaAndDecimal(cell);
    }
    return '-';
  };

  const addClassCallBack = () => {
    setShow(!show);
  };

  const updateUserSuccess = () => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    };

    getSentUTRsData(urlParams);
  };

  const handleUpdateUser = () => {
    setEditUtrDetails(editUtrDetails);

    const updateUserDetails = editUtrDetails;

    const { publicId, disbursementDate, utr, partnerLoanId, partnerUserId } = updateUserDetails;

    updateUtr(
      { disbursementDate, utr, partnerLoanId, partnerUserId },
      publicId,
      updateUserSuccess,
      dispatch
    );
    setEditUtrpopup(false);
  };

  function actionIcon(onClick, cell) {
    const handleEditShowPopup = () => {
      setEditUtrDetails({
        publicId: cell.publicId,
        concurrencyStamp: cell.concurrencyStamp,
        utr: cell.utr,
        amount: cell.amount,
        disbursementDate: cell.disbursementDate,
        facilityType: cell.facilityType,
        accountHolderName: cell.accountHolderName,
        accountNumber: cell.accountNumber,
        partnerLoanId: cell.partnerLoanId,
        partnerUserId: cell.partnerUserId,
      });

      setEditUtrpopup(true);
    };

    return (
      <UtrEditAction
        handleEditShowPopup={() => handleEditShowPopup(onClick)}
      />
    );
  }

  const tableHeaderData = [
    {
      columnClassName: '',
      dataField: 'action',
      dataFormat: actionIcon,
      dataSort: false,
      isKey: false,
      name: 'Actions',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'id',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: true,
      name: '#',
      width: '30',
    },
    {
      columnClassName: '',
      dataField: 'number',
      dataFormat: userLoanNumber,
      dataSort: false,
      isKey: false,
      name: 'Number',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'policyHolder',
      dataFormat: userPolicyHolder,
      dataSort: false,
      isKey: false,
      name: 'policy Holder',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'partnerLoanId',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: false,
      name: 'Partner Loan Id',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'partnerUserId',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: false,
      name: 'Partner User Id',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'accountHolderName',
      dataFormat: (cell) => cell,
      dataSort: true,
      isKey: false,
      name: 'Account Holder Name',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'facilityType',
      dataFormat: facilityFormatter,
      dataSort: true,
      isKey: false,
      name: 'Facility Type',
      width: '180',
    },
    {
      columnClassName: '',
      dataField: 'accountNumber',
      dataFormat: (cell) => cell,
      dataSort: false,
      isKey: false,
      name: 'Account Number',
      width: '100',
    },
    {
      columnClassName: '',
      dataField: 'amount',
      dataFormat: numberFormatter,
      dataSort: false,
      isKey: false,
      name: 'Amount',
      width: '70',
    },
    {
      columnClassName: '',
      dataField: 'balanceDueAmount',
      dataFormat: numberFormatter,
      dataSort: false,
      isKey: false,
      name: 'Balance Due Amount',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'utr',
      dataFormat: (cell)=>cell,
      dataSort: false,
      isKey: false,
      name: 'UTR',
      width: '150',
    },
    {
      columnClassName: '',
      dataField: 'disbursementDate',
      dataFormat: dateTimeFormatter,
      dataSort: false,
      isKey: false,
      name: 'Disbursement Date',
      width: '150',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'createdAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Created At',
      width: '120',
    },
    {
      columnClassName: 'col-grey',
      dataField: 'updatedAt',
      dataFormat: dateTimeFormatter,
      dataSort: true,
      isKey: false,
      name: 'Updated At',
      width: '120',
    },
  ];

  return (
    <div>
      <Header />
      <div className="common-container">
        <SidebarIcon addClassCallBack={addClassCallBack} show={show} />
        <div className={`common-wrapper ${show ? 'active' : ''} `}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>Sent UTRs</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Sent UTRs</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="loan-container mar0 mt-2">
            <DataTable
              tableData={usersList}
              tableHeaderData={tableHeaderData}
              totalRecords={totalRecords}
              isFetching={isFetching}
              getTableData={getSentUTRsData}
              exportUrl={userExportUrl}
              tableTitle="UTRs"
              pageFilters={usersFilters}
              defaultSortKey={'createdAt'}
              defaultSortDirection={'desc'}
            />
          </div>
        </div>
        <Footer show={show} />
        <div className={`common-side-bar ${show ? 'active' : ''} `}>
          <SideBar addClassCallBack={addClassCallBack} show={show} />
        </div>
        {/* Edit user Modal */}
        <Modal
          show={editUtrpopup}
          onHide={() => setEditUtrpopup(false)}
          animation={false}
          className="edit-reason-popup"
        >
          <div className="common-image">
            <div className="common-img" onClick={() => setEditUtrpopup(false)}>
              <img alt="close-icon-gray" src={CloseIconWhite} />
            </div>
          </div>
          <Modal.Body>
            <EditUtr
              closeEditUserPopup={() => setEditUtrpopup(false)}
              editUserDetails={editUtrDetails}
              setEditUserDetails={setEditUtrDetails}
              handleUpdateUser={handleUpdateUser}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default SentUTRs;
