import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { addAddressUrl, getPincodeURL } from '../../utils/urls';
import { ADD_ADDRESS_REQUEST, EDIT_ADDRESS_REQUEST, GET_ADDRESS_DETAILS_WITH_PINCODE } from './profile-constants';
import { addAddressSuccess, addAddressFailure,getAddressDetailsWithPincodeSuccess } from './address-action';

const { postRequest, patchRequest, getRequest } = new HttpHelper();

export function* addAddress(action) {
  try {
    const url = addAddressUrl(action.id);

    const response = yield call(postRequest, {
      data: action.addressDetails,
      url,
    });

    yield put(addAddressSuccess(response.data));
    action.callback();
  } catch (error) {
    yield put(addAddressFailure());
  }
}

export function* editAddress(action) {
  try {
    const url = addAddressUrl(action.id);

    const response = yield call(patchRequest, {
      data: action.addressDetails,
      url,
    });

    yield put(addAddressSuccess(response.data));
    action.callback();
  } catch (error) {
    yield put(addAddressFailure());
  }
}
export function* getAddressDetailsWithPincode(action) {
  try {
    const url = getPincodeURL(action.pincode);
    const response = yield call(getRequest, {
      url
    });

    yield put(getAddressDetailsWithPincodeSuccess(response.data));
  } catch (error) {
    yield put(addAddressFailure());
  }
}

export function* watchAddAddress() {
  yield takeEvery(ADD_ADDRESS_REQUEST, addAddress);
  yield takeEvery(EDIT_ADDRESS_REQUEST, editAddress);
  yield takeEvery(GET_ADDRESS_DETAILS_WITH_PINCODE, getAddressDetailsWithPincode);

}
