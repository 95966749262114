import {
  SEND_GENERATED_LEADS_FAILURE,
  SEND_GENERATED_LEADS_REQUEST,
  SEND_GENERATED_LEADS_SUCCESS,
} from './send-email-to-lender-constants';

const initialState = {
  error: false,
  success: false,
  isloading: true,
};

const sendGeneratedLeads = (state = initialState, action) => {
  switch (action.type) {
    case SEND_GENERATED_LEADS_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case SEND_GENERATED_LEADS_FAILURE:
      return {
        ...state,
        error: true,
        isloading: false,
      };
    case SEND_GENERATED_LEADS_SUCCESS:
      return {
        error: false,
        isFetching: false,
        success: true,
      };
    default:
      return state;
  }
};

export default sendGeneratedLeads;