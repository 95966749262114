import {
  UPLOAD_DOCS_POLICY_HOLDER_REQUEST,
  UPLOAD_DOCS_POLICY_HOLDER_SUCCESS,
  UPLOAD_DOCS_POLICY_HOLDER_FAILURE,
} from './nucleon-profile-constants';

const uploadDocsPolicyHolderRequest = (documentIds, id, concurrencyStamp,callback) => ({
  concurrencyStamp,
  documentIds,
  id,
  callback,
  type: UPLOAD_DOCS_POLICY_HOLDER_REQUEST,
});

export const uploadDocsPolicyHolderSuccess = () => ({
  type: UPLOAD_DOCS_POLICY_HOLDER_SUCCESS,
});
export const uploadDocsPolicyHolderFailure = () => ({
  type: UPLOAD_DOCS_POLICY_HOLDER_FAILURE,
});

export const uploadDocsPolicyHolder = async (
  documentIds,
  id,
  concurrencyStamp,
  dispatch,
  callback
) => {
  dispatch(uploadDocsPolicyHolderRequest(documentIds, id, concurrencyStamp,callback));
};
