import React from 'react';
import MultiSelect from '@khanacademy/react-multi-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import './form-element.css';
import ArrowIconDownGray from '../../../images/icons/arrowIcondownGray.png';
import { toNumberWithCommaAndDecimal } from '../../utils/formattor';
import {
  DATE,
  DATERANGE,
  MULTISELECT,
  NUMBER,
  NUMBERRANGE,
  TEXT,
  TEXTAREA,
  SELECT,
  CONSTANT,
  CONSTANT_RUPEE,
  FILE,
  SWITCH,
} from '../../constants/form-type';

const FormElement = ({
  inputType,
  lableName,
  name,
  placeholder,
  value,
  onChangeHandler,
  options,
  className,
  isValid,
  errorMsg,
  isTouched,
  isDisabled,
  minPlaceholder,
  maxPlaceholder,
  filterChangeHandler,
}) => {
  // To return the Form element based on the type.
  const getFormInputElement = (formElementInputType) => {
    switch (formElementInputType) {
      case CONSTANT:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <div className="form-element-constant">{value}</div>
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case CONSTANT_RUPEE:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <div className="form-element-constant">
              {`₹ ${toNumberWithCommaAndDecimal(value)}`}
            </div>
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case TEXT:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <Form.Control
              type="text"
              className="form-element-input-box"
              name={name}
              placeholder={placeholder}
              value={value}
              disabled={isDisabled}
              style={{ fontSize: "1.5rem" }}
              onChange={(e) => onChangeHandler(e.target.value)}
            />
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case NUMBER:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <Form.Control
              type="number"
              name={name}
              className="form-element-input-box"
              placeholder={placeholder}
              value={value}
              disabled={isDisabled}
              onChange={(e) => onChangeHandler(e.target.value)}
              style={{ fontSize: "1.5rem" }}
            />
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case SWITCH:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <Form.Check
              type="switch"
              id={name}
              label="Check this switch"
              disabled={isDisabled}
              onChange={(e) => onChangeHandler(e.target.value)}
            />
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case FILE:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <div className="mb-3">
              <Form.File id="formcheck-api-custom" custom>
                <Form.File.Input onChange={(e) => onChangeHandler(e)} />
                <Form.File.Label
                  className="form-element-input-box-file"
                  data-browse="BROWSE"
                >
                  {placeholder}
                </Form.File.Label>
              </Form.File>
            </div>
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case DATE:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <br></br>
            <DatePicker
              selected={new Date(value)}
              onChange={(date) => onChangeHandler(date)}
              dateFormat="dd/MMM/yyyy"
              className="form-element-input-box"
              wrapperClassName="w-full"
              popperPlacement="bottom-start"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: '5px, 0px',
                },
              }}
              showPopperArrow={false}
            ></DatePicker>
            <br></br>
          </Form.Group>
        );
      case TEXTAREA:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <Form.Control
              as="textarea"
              rows={6}
              name={name}
              disabled={isDisabled}
              className="form-element-text-area"
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChangeHandler(e.target.value)}
              style={{ fontSize: "1.5rem" }}
            />
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case SELECT:
        return (
          <Form.Group className={className}>
            {lableName && (
              <label className="form-element-lable">{lableName}</label>
            )}
            <Form.Control
              as="select"
              className="form-element-input-box form-select-custom"
              name={name}
              onChange={(e) => onChangeHandler(e.target.value)}
              value={value}
              style={{ fontSize: "1.5rem" }}
              disabled={isDisabled}
            >
              <option className="form-select-option">Select</option>
              {options &&
                options.map((option, id) => (
                  <option
                    className="form-select-option"
                    key={id}
                    value={option.value}
                  >
                    {option.name}
                  </option>
                ))}
            </Form.Control>
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case MULTISELECT:
        return (
          <Form.Group className={className}>
            <label className="form-element-lable">{lableName}</label>
            <MultiSelect
              options={options}
              selected={value}
              onSelectedChanged={(selectedValues) =>
                onChangeHandler(selectedValues)
              }
              disableSearch={true}
              overrideStrings={{
                selectAll: 'All',
                selectSomeItems: 'Select',
              }}
            />
            <div className="down-arrow-img">
              <img alt="forget-password" src={ArrowIconDownGray} />
            </div>
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </Form.Group>
        );
      case NUMBERRANGE:
        return (
          <div className={`${'number-range-container'} ${className}`}>
            <label className="form-element-lable">{lableName}</label>
            <Form.Control
              type="number"
              placeholder={minPlaceholder}
              name={name}
              value={value[0]}
              disabled={isDisabled}
              className="form-element-input-box"
              onChange={(e) =>
                filterChangeHandler({ min: e.target.value, max: value[1] })
              }
            />
            <Form.Control
              type="number"
              placeholder={maxPlaceholder}
              name={name}
              className="form-element-input-box"
              value={value[1]}
              disabled={isDisabled}
              onChange={(e) =>
                filterChangeHandler({ min: value[0], max: e.target.value })
              }
            />
            {!isValid && errorMsg && isTouched && (
              <span className="error-msg-common">{errorMsg}</span>
            )}
          </div>
        );
      case DATERANGE:
        return (
          <DateRangePicker
            startDate={value[0]}
            endDate={value[1]}
            onApply={(event, picker) => onChangeHandler(picker)}
            locale={{ format: 'DD/MMM/YYYY' }}
          >
            <Form.Control
              type="text"
              value={`${value[0].format('DD-MMM-YYYY')} - ${value[1].format(
                'DD-MMM-YYYY'
              )}`}
              className="form-element-input-box"
              name={name}
              readOnly
            />
            <div className="down-arrow-img">
              <img alt="forget-password" src={ArrowIconDownGray} />
            </div>
          </DateRangePicker>
        );
      default:
        return null;
    }
  };

  return <React.Fragment>{getFormInputElement(inputType)}</React.Fragment>;
};

export default FormElement;
