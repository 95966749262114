import { call, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { GET_PARTNER_LIST_URL } from '../../utils/urls';
import { GET_PARTNER_REQUEST } from './loan-constants';

const { getRequest } = new HttpHelper();

export function* partnerList(action) {
  try {
    const response = yield call(getRequest, {
      url: GET_PARTNER_LIST_URL,
    });

    action.data.partnerListCallback(response.data);
  } catch (err) {
    throw new Error(err);
  }
}

export function* watchpartnerList() {
  yield takeEvery(GET_PARTNER_REQUEST, partnerList);
}
