import {
  ADD_POLICY_HOLDER_ADDRESS_FAILURE,
  ADD_POLICY_HOLDER_ADDRESS_REQUEST,
  ADD_POLICY_HOLDER_ADDRESS_SUCCESS,
  EDIT_POLICY_HOLDER_ADDRESS_REQUEST,
} from './nucleon-profile-constants';

const addPolicyHolderAddressRequest = (id, addressDetails, callback) => ({
  id,
  addressDetails,
  callback,
  type: ADD_POLICY_HOLDER_ADDRESS_REQUEST,
});

const editPolicyHolderAddressRequest = (id, addressDetails, callback) => ({
  id,
  addressDetails,
  callback,
  type: EDIT_POLICY_HOLDER_ADDRESS_REQUEST,
});

export const addPolicyHolderAddressSuccess = (data) => ({
  data,
  type: ADD_POLICY_HOLDER_ADDRESS_SUCCESS,
});
export const addPolicyHolderAddressFailure = () => ({
  type: ADD_POLICY_HOLDER_ADDRESS_FAILURE,
});

export const addPolicyHolderAddress = (id, addressDetails, dispatch, callback) => {
  dispatch(addPolicyHolderAddressRequest(id, addressDetails, callback));
};

export const editPolicyHolderAddress = (id, addressDetails, dispatch, callback) => {
  dispatch(editPolicyHolderAddressRequest(id, addressDetails, callback));
};
