import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { dailyReportUrl } from '../../utils/urls';
import { DAILY_REPORT_REQUEST } from './daily-report-constant';
import { dailyReportSuccess, dailyReportFailure } from './daily-report-action';

const { getRequest } = new HttpHelper();

export function* dailyReport(action) {
  try {
    const response = yield call(getRequest, {
      url: dailyReportUrl(action.urlParams),
    });
    // const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(dailyReportSuccess(response.data, 1));
  } catch (error) {
    yield put(dailyReportFailure());
  }
}

export function* watchDailyReport() {
  yield takeEvery(DAILY_REPORT_REQUEST, dailyReport);
}
