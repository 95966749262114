import {
  DELETE_USER_KYC_FAILURE,
  DELETE_USER_KYC_SUCCESS,
  DELETE_USER_KYC_REQUEST,
} from './profile-constants';

const initialState = {
  error: false,
  isUpdating: false,
};

const deleteKyc = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_USER_KYC_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case DELETE_USER_KYC_FAILURE:
      return {
        ...state,
        error: true,
        isUpdating: false,
      };
    case DELETE_USER_KYC_SUCCESS:
      return {
        ...state,
        error: false,
        isUpdating: false,
      };
    default:
      return state;
  }
};

export default deleteKyc;
