import { call, put, takeEvery } from 'redux-saga/effects';
import * as Toastr from 'toastr';

import HttpHelper from '../../utils/http-helper';

import { editManageProductUrl } from '../../utils/urls';

import { EDIT_PRODUCT_REQUEST } from './manage-product-constants';

import { editProductSuccess, editProductFailure } from './edit-product-action';

const { patchRequest } = new HttpHelper();

export function* editProduct(action) {
  try {
    const url = editManageProductUrl(action.productSettingId);

    const headerParams = {
      'x-coreplatform-concurrencystamp': action.concurrencyStamp,
    };

    const response = yield call(patchRequest, {
      data: {
        priority: Number(action.userDetails.priority),
      },
      headers: headerParams,
      url,
    });

    if (response.error) {
      const { data } = response.error.response;

      yield put(editProductFailure());

      Toastr.error(
        `${data.details[0].name} : ${data.details[0].message}`,
        'Failure'
      );
    }else {
      yield put(editProductSuccess());

      action.userSuccessCallback();

      Toastr.success(response.headers.message, 'Success Response');
    }
  } catch (error) {
    // Toastr.error('Change Password Failed', 'Failure Response');
  }
}

export function* watchEditProduct() {
  yield takeEvery(EDIT_PRODUCT_REQUEST, editProduct);
}
