import {
  INCREASE_VALIDITY_REQUEST,
  INCREASE_VALIDITY_SUCCESS,
  INCREASE_VALIDITY_FAILURE,
} from './profile-constants';

const increaseValidityRequest = (id,increaseSuccessCallback) => ({
  id,
  increaseSuccessCallback,
  type: INCREASE_VALIDITY_REQUEST,
});

export const increaseValiditySuccess = () => ({
  type: INCREASE_VALIDITY_SUCCESS,
});

export const increaseValidityFailure = () => ({
  type: INCREASE_VALIDITY_FAILURE,
});

export const increaseValidity = (
  id,
  increaseSuccessCallback,
  dispatch
) => {
  dispatch(increaseValidityRequest(id,increaseSuccessCallback));
};
