import {
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAILURE,
} from './profile-constants';

const profileUpdateRequest = (id, concurrencyStamp, values, callback) => ({
  id,
  concurrencyStamp,
  values,
  callback,
  type: USER_PROFILE_UPDATE_REQUEST,
});

export const profileUpdateSuccess = (data) => ({
  data,
  type: USER_PROFILE_UPDATE_SUCCESS,
});
export const profileUpdateFailure = () => ({
  type: USER_PROFILE_UPDATE_FAILURE,
});

export const userProfileUpdate = async (
  values,
  id,
  concurrencyStamp,
  callback,
  dispatch
) => {
  dispatch(profileUpdateRequest(id, concurrencyStamp, values, callback));
};
