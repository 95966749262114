export const getStateListOption =(statelist)=>{
  const result = [];

  statelist.map((state)=>{
    const obj = {
      name: state.name,
      value: state.name
    };

    result.push(obj);
    return false;
  });
  return result;
}

export const getBankListOption =(banklist)=>{
  const result = [];

  banklist.map((state)=>{
    const obj = {
      name: state.name,
      value: state.publicId
    };

    result.push(obj);
    return false;
  });
  return result;
}

export const getHospitalListOption =(hospitallist)=>{
  const result = [];

  hospitallist.map((state)=>{
    const obj = {
      name: state.name,
      value: state.publicId,
      code: state.code,
      accountNumber: state.accountNumber,
      ifsc: state.ifscCode
    };

    result.push(obj);
    return false;
  });
  return result;
}

export const getPharmacyListOption =(pharmacylist)=>{
  const result = [];

  pharmacylist.map((state)=>{
    const obj = {
      name: state.name,
      value: state.publicId,
      code: state.code,
      accountNumber: state.accountNumber,
      ifsc: state.ifscCode
    };

    result.push(obj);
    return false;
  });
  return result;
}

export const getPathologyListOption =(pathologylist)=>{
  const result = [];

  pathologylist.map((state)=>{
    const obj = {
      name: state.name,
      value: state.publicId,
      code: state.code,
      accountNumber: state.accountNumber,
      ifsc: state.ifscCode
    };

    result.push(obj);
    return false;
  });
  return result;
}

export const getPartnerListOption =(partnerList)=>{
  const result = [];

  partnerList.map((state)=>{
    const obj = {
      name: state.name,
      value: state.publicId,
    };

    result.push(obj);
    return false;
  });
  return result;
}