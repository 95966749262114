import { GET_PARTNER_REQUEST } from './loan-constants';

const patnerListRequest = (data) => ({
  data,
  type: GET_PARTNER_REQUEST,
});

// eslint-disable-next-line import/prefer-default-export
export const partnerList = (dispatch, partnerListCallback) => {
  dispatch(patnerListRequest({ partnerListCallback }));
};
