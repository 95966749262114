import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';

import Header from '../../components/header';
import SideBar from '../../components/side-bar';
import PolicyHolderAddressDetails from '../../components/neuclon-profile/policy-holder-address-details';
import ShowPolicyHolderAddressDetails from '../../components/Policy-Holder-Address/policy-holder-address-details';
import AddPolicyHolderAddress from '../../components/Policy-Holder-Address/add-policy-holder-address';
import FileUploadNeuclon from '../../components/neuclon-profile/file-upload';
import ProfileDetailsTab from '../../components/neuclon-profile/profile-details-tab';
import GenerateLoanLead from '../../components/neuclon-profile/generate-lead';
import { address } from '../../constants';
import { stateList } from '../profile/state-list-action';
import { getStateListOption } from '../profile/common';
import {
  getCityStateCountryByPincode,
} from '../profile/address-action';
import {
  addPolicyHolderAddress,
  editPolicyHolderAddress
} from './add-policy-holder-address-action'
import Footer from '../../components/footer';
import PageLoader from '../../components/Loaders/page-loader';
import LeftProfileInfo from '../../components/neuclon-profile/left-profile-info';
import UserBasicInfo from '../../components/neuclon-profile/user-basic-info';
import ErrorAlertBox from '../../components/Profile/error-alert-box';
import AddLoan from '../../components/neuclon-profile/add-loan';
import { policyDetails } from './policy-details-action';
import { hospitalDetails } from './hospital-details-action';
import { uploadDocsPolicyHolder } from './upload-docs-policy-holder-action';
import { fileUploadPolicyHolderDocs } from './file-upload-policy-holder-docs-action';

const NeuclonProfile = ({ match }) => {
  // File Upload
  const [fileUploadPopup, setFileUploadPopup] = useState(false);
  // Add Loan
  const [addLoanPopUP, setAddLoan] = useState(false);

  const [generateLoanLead, setGenerateLoanLead] = useState(false);

  // Error Handle
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // Side Bar
  const [show, setShow] = useState(false);
  // Show/hide the Add Call Logs
  const [publicId, setPublicId] = useState();
  const [loanId, setLoanid] = useState();

  const [addressCrud] = useState(address);
  //  Store the State data when Add/Edit/View Address is called
  const [stateData, setStateData] = useState([]);
  // View, Add, Edit Address
  const [viewAddressDetail, setViewAddressDetail] = useState(false);
  const [addAddressPopUp, setAddAddressPopUp] = useState(false);

  const dispatch = useDispatch();

  const { hospitalData } = useSelector((state) => state.hospitalDetailsData);
  const { isFetching, policyData } = useSelector((state) => state.policyDetails);
  const { pincodeDetails } = useSelector((state) => state.addAddress);

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const userId = path[path.length - 1];

    if (userId) {
      policyDetails(userId, dispatch);
    } else if (match.params.id) {
      policyDetails(userId, dispatch);
    }
  }, []);

  useEffect(() => {
    if(publicId){
      hospitalDetails(publicId,dispatch)
    }
  }, [publicId]);

  // Calling the GET Bank Api to get the bank list

  //  Function to reload the data
  const handleReload = () => {
    const path = window.location.pathname.split('/');
    const userId = path[path.length - 1];

    if (userId) {
      policyDetails(userId, dispatch);
    } else if (match.params.id) {
      policyDetails(userId, dispatch);
    }
  };

  // update the state list in the form
  const updateStateList = (statesList) => {
    setStateData(getStateListOption(statesList));
  };

  // Calling the GetState Api to get the state list
  useEffect(() => {
    if (stateData.length === 0 && (viewAddressDetail || addAddressPopUp))
      stateList(dispatch, updateStateList);
  }, [viewAddressDetail, addAddressPopUp]);

  //  close view address pop up
  const closeAddressDetailsPopup = () => {
    setViewAddressDetail(false);
  };
  // Function to get the data of the newly added addresss
  const addAddressHandler = (addAddressDetails) => {
    addPolicyHolderAddress(match.params.id, addAddressDetails, dispatch, handleReload);
  };
  // Function to get the data of the edited added addresss
  const editAddressHandler = (id, editaddressDetails) => {
    editPolicyHolderAddress(id, editaddressDetails, dispatch, handleReload);
  };
  // Open Add Address in View Address
  const addAddressPopupShow = () => setAddAddressPopUp(true);
  // Close view/edit popup and open add address pop up
  const closeAndOpenAddAddressPopup = () => {
    setViewAddressDetail(false);
    addAddressPopupShow();
  };
  const getAddressDetailsWithPincode = (pincode) => {
    getCityStateCountryByPincode(pincode, dispatch);
  };
  // Function to update the user profile data and fetch new data once update success.
  const updateProfileDetails = (payload) => {
    uploadDocsPolicyHolder(
      payload,
      match.params.id,
      policyData.concurrencyStamp,
      dispatch,
      handleReload
    );
  };

  //  To toggle side bar
  const addClassCallBack = () => {
    setShow(!show);
  };

  // To Upload file document related to the user
  const handleUploadFile = (uploadingFileKeyValue) => {
    setFileUploadPopup(!fileUploadPopup);
    updateProfileDetails({ ...uploadingFileKeyValue });
  };

  return (
    <div>
      {isFetching ? (
        <PageLoader />
      ) : (
        <Fragment>
          <Header />
          <div className="common-container">
            <div className={`common-wrapper ${show ? 'active' : ''} `} >
              <div className="col-md-12 mpad">
                <div className="common-heading">
                  <h1>Profile</h1>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Nucleon Profile</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div className="row">
                  <div className="mpad profile-details col-md-12 col-lg-3 ">
                    <LeftProfileInfo
                      setFileUploadPopup={setFileUploadPopup}
                      fileUploadPopup={fileUploadPopup}
                    />
                  </div>
                  <div className="mpad personal-details col-md-12 col-lg-9">
                    <ErrorAlertBox
                      show={showErrorMessage}
                      errorMsg={
                        'Error message text goes here. Error message text goes here.'
                      }
                      close={() => setShowErrorMessage(false)}
                      errorHeading={'ERROR MESSAGE HEADING'}
                    />
                    <UserBasicInfo profileData={policyData} />
                    <PolicyHolderAddressDetails
                      userAddressDetails={policyData.address || []}
                      setViewAddressDetail={setViewAddressDetail}
                    />
                    {/* <CheckLoanEligibleForm setEligibility={setEligibility} /> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <ProfileDetailsTab
                      identityDocument={policyData.identityDocument || []}
                      loans={policyData.loans || []}
                      setAddLoan= {setAddLoan}
                      setGenerateLoanLead= {setGenerateLoanLead}
                      setLoanid={setLoanid}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Footer show={show} />
            <div className={`common-side-bar ${show ? 'active' : ''} `}>
              <SideBar addClassCallBack={addClassCallBack} show={show} />
            </div>
          </div>
        </Fragment>
      )}

      {/* File Upload Modal */}
      {/* <FileUpload
        fileUploadPopup={fileUploadPopup}
        setFileUploadPopup={setFileUploadPopup}
        closeUploadDocumentPopup={() => setFileUploadPopup(!fileUploadPopup)}
        handleUploadFile={handleUploadFile}
        uploadFile={uploadFile}
        useDispatch={useDispatch}
      /> */}
      <FileUploadNeuclon
        fileUploadPopup={fileUploadPopup}
        setFileUploadPopup={setFileUploadPopup}
        closeUploadDocumentPopup={() => setFileUploadPopup(!fileUploadPopup)}
        handleUploadFile={handleUploadFile}
        uploadFile={fileUploadPolicyHolderDocs}
        dispatch={dispatch}
      />

      <AddLoan
        addLoan={addLoanPopUP}
        setAddLoan={setAddLoan}
        closeaddLoanPopup={() => setAddLoan(false)}
        setPublicId={setPublicId}
        policyHolderId={match.params.id}
        handleReload={handleReload}
        hospitalDetailsData={hospitalData || []}
      />
      <GenerateLoanLead
        policyHolderId={match.params.id}
        loanId={loanId}
        generateLead={generateLoanLead}
        setGenrateLoan={setGenerateLoanLead}
        closeGenerateLeadPopup={() => setGenerateLoanLead(false)}
      />
      {/* Add Address Details Modal */}
      <AddPolicyHolderAddress
        closeNewAddressPopup={() => setAddAddressPopUp(false)}
        addAddress={addAddressPopUp}
        stateData={stateData}
        pincodeDetails={pincodeDetails}
        getAddressDetailsWithPincode={getAddressDetailsWithPincode}
        setAddAddress={setAddAddressPopUp}
        addAddressHandler={addAddressHandler}
      />
      <ShowPolicyHolderAddressDetails
        closeAddressDetailsPopup={closeAddressDetailsPopup}
        closeAndOpenAddAddressPopup={closeAndOpenAddAddressPopup}
        address={policyData.address || []}
        addAddress={addressCrud}
        stateData={stateData}
        pincodeDetails={pincodeDetails}
        getAddressDetailsWithPincode={getAddressDetailsWithPincode}
        editAddressHandler={editAddressHandler}
        viewAddressDetail={viewAddressDetail}
        setViewAddressDetail={setViewAddressDetail}
      />
    </div>
  );
};

export default NeuclonProfile;
