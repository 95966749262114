import {
  UPDATE_UTR_REQUEST,
  UPDATE_UTR_SUCCESS,
  UPDATE_UTR_FAILURE,
} from './sent-utrs-constants';

const updateUtrRequest = (
  userDetails,
  publicId,
  updateUtrSuccessCallback
) => ({
  publicId,
  type: UPDATE_UTR_REQUEST,
  userDetails,
  updateUtrSuccessCallback,
});

export const updateUtrSuccess = () => ({
  type: UPDATE_UTR_SUCCESS,
});

export const updateUtrFailure = () => ({
  type: UPDATE_UTR_FAILURE,
});

export const updateUtr = (
  userDetails,
  publicId,
  updateUtrSuccessCallback,
  dispatch
) => {
  dispatch(
    updateUtrRequest(
      userDetails,
      publicId,
      updateUtrSuccessCallback
    )
  );
};
