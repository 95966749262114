import { SELECT, TEXT,NUMBER } from '../../../constants/form-type';

export const editBankStatementFormConstant = [
  {
    label: 'Account Holder Name',
    name: 'accountHolderName',
    placeholder: 'Account holder\'s name',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  // {
  //     label: 'Account Type',
  //     name: 'accountType',
  //     placeholder: 'Account Type',
  //     validation: { isRequired: true },
  //     options: [
  //         { name: 'Saving', value: 'saving' },
  //         { name: 'Current', value: 'current' },
  //     ],
  //     isDisabled:false,
  //     type: SELECT
  // },
  {
    label: 'accountNumber',
    name: 'accountNumber',
    placeholder: 'Account Number',
    validation: { isRequired: true },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'IFSC Code',
    name: 'ifsc',
    placeholder: 'IFSC code for your branch',
    validation: { isRequired: false, minLength: 11, maxLength: 11 },
    isDisabled: false,
    type: TEXT
  },
  {
    label: 'Bank',
    name: 'bankId',
    placeholder: 'Select your bank',
    validation: { isRequired: true },
    options: [],
    isDisabled: false,
    type: SELECT
  },
  {
    label: 'Average Salary',
    name: 'averageSalary',
    placeholder: 'Enter the average salary',
    validation: { isRequired: false },
    isDisabled: false,
    type: NUMBER
  },
  {
    label: 'Last Loan',
    name: 'lastMonthLoan',
    placeholder: 'Enter the last month loan',
    validation: { isRequired: false },
    isDisabled: false,
    type: NUMBER
  },
  {
    label: 'Enter the monthly capacity',
    name: 'monthlyCapacity',
    placeholder: 'Enter the monthly capacity',
    validation: { isRequired: false },
    isDisabled: false,
    type: NUMBER
  },
  {
    label: 'Enter the Foir capacity',
    name: 'foir',
    placeholder: 'Enter the foir',
    validation: { isRequired: false },
    isDisabled: false,
    type: NUMBER
  },
  {
    label: 'Status',
    name: 'status',
    placeholder: 'Select status',
    validation: { isRequired: true },
    options: [{ name: 'valid', value: 'valid' },{ name: 'invalid',value: 'invalid' }],
    isDisabled: false,
    type: SELECT
  },
]