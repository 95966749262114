import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { manageProductUrl } from '../../utils/urls';
import { PRODUCT_REQUEST } from './manage-product-constants';
import { ProductSuccess, ProductFailure } from './get-product-action';

const { getRequest } = new HttpHelper();

export function* getProduct(action) {

  try {
    const response = yield call(getRequest, {
      url: manageProductUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(ProductSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(ProductFailure());
  }
}

export function* watchGetProduct() {
  yield takeEvery(PRODUCT_REQUEST, getProduct);
}
