import {
  UPLOAD_DOCS_POLICY_HOLDER_FAILURE,
  UPLOAD_DOCS_POLICY_HOLDER_SUCCESS,
  UPLOAD_DOCS_POLICY_HOLDER_REQUEST,
} from './nucleon-profile-constants';

const initialState = {
  error: false,
  isFetching: false,
};

const uploadDocsPolicyHolder = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_DOCS_POLICY_HOLDER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPLOAD_DOCS_POLICY_HOLDER_FAILURE:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case UPLOAD_DOCS_POLICY_HOLDER_SUCCESS:
      return {
        ...state,
        error: false,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default uploadDocsPolicyHolder;
