import {
  SEND_GENERATED_LEADS_SUCCESS,
  SEND_GENERATED_LEADS_REQUEST,
  SEND_GENERATED_LEADS_FAILURE,
} from './send-email-to-lender-constants';

const sendGeneratedLeadsRequest = (data,sendSuccessCallback) => ({
  data,
  sendSuccessCallback,
  type: SEND_GENERATED_LEADS_REQUEST,
});

export const sendGeneratedLeadsSuccess = () => ({
  type: SEND_GENERATED_LEADS_SUCCESS,
});
export const sendGeneratedLeadsFailure = () => ({
  type: SEND_GENERATED_LEADS_FAILURE,
});

export const sendgeneratedLeads = async (data,sendSuccessCallback,dispatch) => {
  dispatch(sendGeneratedLeadsRequest(data,sendSuccessCallback));
};
