import React from 'react';

const LeftProfileInfo = ({ setFileUploadPopup,fileUploadPopup }) => {

  return (<React.Fragment>
    <div className="profile-upload-document">
      <h5>UPLOAD DOCUMENT</h5>
      <p>only .jpg, .png, .pdf file allowed</p>
      <div className="profile-upload-document-btn">
        <button
          onClick={() => setFileUploadPopup(!fileUploadPopup)}
          className="black-border-btn black-border-browse"
        >
                    Browse
        </button>
      </div>
    </div>
  </React.Fragment>)
}

export default LeftProfileInfo;