import { call, put, takeEvery } from 'redux-saga/effects';

import HttpHelper from '../../utils/http-helper';
import { LoanDpdUrl } from '../../utils/urls';
import { LOAN_DPD_REQUEST } from './loan-dpd-constants';
import { loanDpdSuccess, loanDpdFailure } from './loan-dpd-action';

const { getRequest } = new HttpHelper();

export function* loanDpdA(action) {

  try {
    const response = yield call(getRequest, {
      url: LoanDpdUrl(action.urlParams),
    });

    const totalRecords = response.headers['x-coreplatform-total-records'];

    yield put(loanDpdSuccess(response.data, totalRecords));
  } catch (error) {
    yield put(loanDpdFailure());
  }
}

export function* watchLoanDpd() {
  yield takeEvery(LOAN_DPD_REQUEST, loanDpdA);
}
