/* eslint-disable no-unused-vars */
import React from 'react';
import { Button } from 'react-bootstrap';
import {
  statusAndVerifiedMarker,
  viewOrDownloadPolicyFile,
  addLinkToFieldPolicyLoan,
} from '../../../utils/table-data-helper';

export const getprofileTabDetailsConst = {
  IDENTITY_CHECK: {
    tableName: 'IDENTITY CHECK',
    tableHeaderData: [
      {
        name: 'Document Type',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentType',
      },
      {
        name: 'DownLoad Front Pic',
        dataFormat: viewOrDownloadPolicyFile,
        dataKey: 'documentFrontId',
      },
      {
        name: 'Download Back Pic',
        dataFormat: viewOrDownloadPolicyFile,
        dataKey: 'documentBackId',
      },
      {
        name: 'Document Number',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'documentNumber',
      },
    ],
  },
  LOAN_INFO: {
    tableName: 'LOAN INFO',
    tableHeaderData: [
      {
        name: 'Loan Number',
        dataFormat: addLinkToFieldPolicyLoan,
        dataKey: 'number',
      },
      {
        name: 'Loan Amount',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'amount',
      },
      {
        name: 'Status',
        dataFormat: statusAndVerifiedMarker,
        dataKey: 'status',
      },
      {
        name: 'Generate Lead',
        dataFormat: (cell, tableRowData = null) => cell,
        dataKey: 'generatedLead',
      },
    ],
  },
};
