import { TEXTAREA, DATE, TEXT } from '../../../constants/form-type';

export const disburseLoanRemarkFormFields = [
  {
    label: 'Disbursement Date',
    name: 'disbursementDate',
    placeholder: 'Please enter Disbursement Date',
    validation: { isRequired: true },
    type: DATE,
  },
  {
    label: 'UTR',
    name: 'utr',
    placeholder: 'Please enter utr',
    validation: { isRequired: false },
    type: TEXT,
  },
  {
    label: 'Remarks',
    name: 'remarks',
    placeholder: 'Please enter your remark',
    validation: { isRequired: true },
    type: TEXTAREA,
  },
];
