import {
  ADD_PATIENT_LOAN_FALIURE,
  ADD_PATIENT_LOAN_SUCCESS,
  ADD_PATIENT_LOAN_REQUEST,
} from './nucleon-profile-constants';

const initialState = {
  error: false,
  success: false,
  isloading: true,
};

const addPatientLoan = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PATIENT_LOAN_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case ADD_PATIENT_LOAN_FALIURE:
      return {
        ...state,
        error: true,
        isloading: false,
      };
    case ADD_PATIENT_LOAN_SUCCESS:
      return {
        error: false,
        isFetching: false,
        success: true,
      };
    default:
      return state;
  }
};

export default addPatientLoan;