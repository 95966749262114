/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
// import DataTable from 'moneyloji-react-data-table';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Breadcrumb, Modal } from 'react-bootstrap';
import * as toastr from 'toastr';
import Button from '../../components/form-elements/button';
import { sendEmailToLender } from './send-email-to-lender-action';
import { sendgeneratedLeads } from './send-generated-leads-action';
// import ExpandedComponent from '../../components/Send-email-to-lender';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { usersFilters } from './send-email-to-lender-constants';
import TableLoader  from '../../components/Loaders/table-loader';
import { setDefaultFilter } from '../../utils/default-filter-helper';
import {
  toDateFormat,
  toNumberWithCommaAndDecimal,
} from '../../utils/formattor';
import ExpandedComponent from '../../components/Send-email-to-lender';
import { DEFAULT_PAGE_SIZE, CURRENT_DEFAULT_PAGE } from '../../constants';
import { FilterIconBlue } from '../../../images/icons/filterIconBlue';
import CloseIconWhite from '../../../images/icons/closeIconWhite.svg';
import Filter from '../../components/filter';
import Show from '../../../images/icons/show.svg';
import './send-email-to-lender.css'

export default function SendEmailToLender({ location }){
  // Filter State
  const [show, setShow] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [number, setNumber] = useState('');
  const [partnerLoanId, setPartnerLoanId] = useState('');
  const [partnerUserId, setPartnerUserId] = useState('');
  const [startDate, setStartDate] = useState(moment().subtract(29, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [dueDate, setDueDate] = useState(false);
  const [editUtrpopup, setEditUtrpopup] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [tableData, setTableData] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [filterParams, setFilterParams] = useState([]);
  const [addFiled, setAddFiled] = useState([usersFilters[0].name]);
  const [filterPopup, setFilterPopup] = useState(false);
  const { isFetching, loanList, totalRecords } = useSelector((state) => state.sendEmailToLender);
  // const { data } = useSelector((state) => state.getCallLogs);

  const usersList =
  loanList &&
  loanList.map((user, index) => {
    const id = index + 1;

    return {
      ...user,
      id,
    };
  });

  const dispatch = useDispatch();

  const getSendEmailToLenderData = (urlParams) => {
    sendEmailToLender(urlParams, dispatch);
  };

  const updateUserSuccess = () => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
    };

    getSendEmailToLenderData(urlParams);
  };

  const filterChangeHandler = (filterName, filterValue) => {
    if (filterName === 'Number') {
      setNumber(filterValue);
    } else if (filterName === 'partner Loan Id') {
      setPartnerLoanId(filterValue);
    } else if (filterName === 'partner User Id') {
      setPartnerUserId(filterValue);
    } else if (filterName === 'Loan Created on') {
      setStartDate(filterValue.startDate);
      setEndDate(filterValue.endDate);
    }
  };

  const getFilterState = (filterName) => {
    if (filterName === 'Number') {
      return number;
    } else if (filterName === 'partner Loan Id') {
      return partnerLoanId;
    } else if (filterName === 'partner User Id') {
      return partnerUserId;
    } else if (filterName === 'Loan Created on') {
      return [startDate, endDate];
    }
  };

  const toggleFilterPopup = () => {
    setFilterPopup(true);
    setApplyFilter(false);
  };

  const clearFilterValues = () => {
    setNumber('');
    setPartnerLoanId('');
    setPartnerUserId('');
    setStartDate(moment().subtract(29, 'days'));
    setEndDate(moment());
  };

  const closeFilterCallBack = () => {
    setFilterPopup(false);
    if (number || partnerLoanId || partnerUserId || dueDate) {
      setApplyFilter(true);
    }
  };

  const handleApplyFilter = () => {
    closeFilterCallBack();

    let urlParams;
    const arrValues = [];
    const dateChanged =
    startDate.format('DD-MMM-YYYY') !==
      moment().subtract(29, 'days').format('DD-MMM-YYYY') ||
    endDate.format('DD-MMM-YYYY') !== moment().format('DD-MMM-YYYY');

    if (dateChanged) {
      setDueDate(true);
    }

    if (
      number || partnerLoanId || partnerUserId ||
      dateChanged
    ) {
      if (number) {
        urlParams = {
          'filters[0][eq]': number,
          'filters[0][key]': 'number',
        };
        arrValues.push({
          'filters[0][eq]': number,
          'filters[0][key]': 'number',
          name: 'Number',
        });
        setFilterParams(arrValues);
      }
      if (partnerLoanId) {
        urlParams = {
          ...urlParams,
          'filters[1][eq]': partnerLoanId,
          'filters[1][key]': 'partnerLoanId',
        };
        arrValues.push({
          'filters[1][eq]': partnerLoanId,
          'filters[1][key]': 'partnerLoanId',
          name: 'partner Loan Id',
        });
        setFilterParams(arrValues);
      }
      if (partnerUserId) {
        urlParams = {
          ...urlParams,
          'filters[2][eq]': partnerUserId,
          'filters[2][key]': 'partnerUserId',
        };
        arrValues.push({
          'filters[2][eq]': partnerUserId,
          'filters[2][key]': 'partnerUserId',
          name: 'partner User Id',
        });
        setFilterParams(arrValues);
      }
      if(dateChanged) {
        if (startDate.format('DD-MMM-YYYY') === endDate.format('DD-MMM-YYYY')) {
          urlParams = {
            ...urlParams,
            'filters[3][eq]': startDate.format('DD-MMM-YYYY'),
            'filters[3][key]': 'createdAt',
          };
          arrValues.push({
            'filters[3][eq]': startDate.format('DD-MMM-YYYY'),
            'filters[3][key]': 'createdAt',
            name: 'Loan Created on',
          });
          setFilterParams(arrValues);
        }
        else{
          urlParams = {
            ...urlParams,
            'filters[3][gte]': startDate.format('DD-MMM-YYYY'),
            'filters[3][key]': 'createdAt',
            'filters[4][lte]': endDate.format('DD-MMM-YYYY'),
            'filters[4][key]': 'createdAt',
          };
          arrValues.push({
            'filters[3][lte]': startDate.format('DD-MMM-YYYY'),
            'filters[3][key]': 'createdAt',
            'filters[4][lte]': endDate.format('DD-MMM-YYYY'),
            'filters[4][key]': 'createdAt',
            name: 'Loan Created on',
          });
          setFilterParams(arrValues);
        }
      }
      getSendEmailToLenderData({
        ...urlParams,
        pageNumber: CURRENT_DEFAULT_PAGE,
        pageSize: perPage,
        'sorting[0][direction]': 'desc',
        'sorting[0][key]': 'createdAt'
      },dispatch);
      setApplyFilter(true);
    }
  };

  const onChangePage = (page) => {
    const urlParams = {
      pageNumber: page,
      pageSize: perPage,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getSendEmailToLenderData(urlParams);
      }
      setDefaultFilter(usersFilters, value, filterOnValue);
    } else {
      getSendEmailToLenderData(urlParams);
    }
  }

  const handlePerRowsChange = (newPerPage, page) => {
    const urlParams = {
      pageNumber: page,
      pageSize: newPerPage,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getSendEmailToLenderData(urlParams);
        setPerPage(newPerPage);
      }
      setDefaultFilter(usersFilters, value, filterOnValue);
    } else {
      getSendEmailToLenderData(urlParams);
      setPerPage(newPerPage);
    }
  };

  useEffect(() => {
    const urlParams = {
      pageNumber: CURRENT_DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      'sorting[0][direction]': ['DESC'],
      'sorting[0][key]': ['createdAt'],
    };

    if (location.search) {
      const filterKey = new URLSearchParams(location.search);
      const value = filterKey.get('value');
      const filterOnValue = filterKey.get('filterOnValue');
      const sortValue = filterKey.get('sortBy');

      if(sortValue){
        urlParams['sorting[0][key]'] = sortValue;
        getSendEmailToLenderData(urlParams);
      }
      setDefaultFilter(usersFilters, value, filterOnValue);
    } else {
      getSendEmailToLenderData(urlParams);
    }
  }, []);

  function loanNumberHandle(cell, row) {
    return <Link to={`/health-care-loan/${row.publicId}`}>{cell || '-'}</Link>;
  }

  function emailStatusFormatter(cell) {
    if (cell) {
      return <label className="label-status-paid">True</label>;
    }
    return <label className="status-unpaid">False</label>;
  }

  const dateFormatter = (cell) => {
    if (cell) {
      return toDateFormat(cell);
    }
    return '-';
  };

  // function valueFormatter(cell) {
  //   if (cell === 'true') {
  //     return <label className="label-status-paid">True</label>;
  //   }
  //   return <label className="status-unpaid">False</label>;
  // }

  const columns = [
    {
      name: 'preview',
      selector: (row) => {return(<div
        className=""
        onClick={() => { setEditUtrpopup(true); setTableData(row);}}
      >
        <img alt="show" src={Show} />
      </div>)},
      width: '100px'
    },
    {
      name: 'number',
      selector: (row) => loanNumberHandle(row.number,row),
      sortable: true,
    },
    {
      name: 'amount',
      selector: (row) => toNumberWithCommaAndDecimal(row.amount),
      sortable: true,
    },
    {
      name: 'balanceDueAmount',
      selector: (row) => toNumberWithCommaAndDecimal(row.balanceDueAmount),
      sortable: true,
    },
    {
      name: 'doa',
      selector: (row) => row.doa,
      sortable: true,
    },
    {
      name: 'hospitalDueAmount',
      selector: (row) => toNumberWithCommaAndDecimal(row.hospitalDueAmount),
      sortable: true,
      width: '150px'
    },
    {
      name: 'pathologyDueAmount',
      selector: (row) => toNumberWithCommaAndDecimal(row.pathologyDueAmount),
      sortable: true,
      width: '150px'
    },
    {
      name: 'pharmacyDueAmount',
      selector: (row) => toNumberWithCommaAndDecimal(row.pharmacyDueAmount),
      sortable: true,
      width: '150px'
    },
    {
      name: 'isEmailSent',
      selector: (row) => emailStatusFormatter(row.isEmailSent),
      sortable: true,
    },
    {
      name: 'status',
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: 'createdAt',
      selector: (row) => dateFormatter(row.createdAt),
      sortable: true,
    },
  ];

  const onSelectedRowsChange = (state) => {
    const loanIDS = state && state.selectedRows.map((element) => (element.publicId));

    setSelectedData(loanIDS);
  };

  const sendEmail = () => {
    if(selectedData && selectedData.length > 0){
      sendgeneratedLeads(selectedData,updateUserSuccess,dispatch);
    }else{
      toastr.error('Please Select and Mail to send','Error');
    }
  }

  return (
    <div>
      <Header />
      <div className="common-container">
        <div className={`common-wrapper ${show ? 'active' : ''} `} style={{ width: '100%' }}>
          <div className="col-md-12 mpad">
            <div className="common-heading">
              <h1>GENERATED-LEADS</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>generated-leads</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className='row'>
            <div className="common-table-btn-section-users">
              <div className="common-table-filter-users" style={{ float: 'right' }} onClick={toggleFilterPopup}>
                <FilterIconBlue />
              </div>
              <div className="" style={{ float: 'right' }}>
                <Button click={()=> sendEmail()} text={"SEND EMAIL"}/>
              </div>
              {filterPopup ? (
                <div className="common-table-filter-popup-users">
                  <Filter
                    filterOptions={usersFilters}
                    handleApplyFilter={handleApplyFilter}
                    closeFilterCallBack={closeFilterCallBack}
                    clearFilterValues={clearFilterValues}
                    getFilterState={getFilterState}
                    filterChangeHandler={filterChangeHandler}
                    addFiled={addFiled}
                    setAddFiled={setAddFiled}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="loan-container mar0 mt-2">
            {loanList.length > 0 && <DataTable
              data={usersList}
              columns={columns}
              paginationTotalRows={Number(totalRecords)}
              onChangePage={onChangePage}
              fixedHeader
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isFetching}
              getTableData={getSendEmailToLenderData}
              pagination
              progressComponent={<TableLoader />}
              // expandableRows
              title=""
              striped
              onSelectedRowsChange={onSelectedRowsChange}
              paginationRowsPerPageOptions={[10,
                20,
                30,
                40,
                50,
                100,
                500,]}
              selectableRows
              paginationServer
              selectableRowsHighlight
              // expandableRowsComponent={loanList.length > 0 && <ExpandedComponent/>}
            />
            }
          </div>
        </div>
        <Footer show={show} />
      </div>
      <Modal
        show={editUtrpopup}
        onHide={() => setEditUtrpopup(false)}
        animation={false}
        className="edit-reason-popup1"
      >
        <div className="common-image">
          <div className="common-img" onClick={() => setEditUtrpopup(false)}>
            <img alt="close-icon-gray" src={CloseIconWhite} />
          </div>
        </div>
        <Modal.Body>
          <ExpandedComponent
            data={tableData || []}
            // editUserDetails={editUtrDetails}
            // setEditUserDetails={setEditUtrDetails}
            // handleUpdateUser={handleUpdateUser}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
