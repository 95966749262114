import { TEXT } from '../../../constants/field-value-key';

export const preApprovedAmountDetails = [
  {
    label: "Min Amount",
    key: "minAmount",
    type: TEXT,
  },
  {
    label: "Max Amount",
    key: "maxAmount",
    type: TEXT,
  },
  {
    label: "Status",
    key: "status",
    type: TEXT,
  },
  {
    label: "Inquiries In Last Six Months",
    key: "inquiriesInLastSixMonths",
    type: TEXT,
  },
  {
    label: "Length Of Credit History Year",
    key: "lengthOfCreditHistoryYear",
    type: TEXT,
  },
  {
    label: "New Accounts In Last Six Months",
    key: "newAccountsInLastSixMonths",
    type: TEXT,
  },
  {
    label: "New Delinq Account In Last Six Months",
    key: "newDelinqAccountInLastSixMonths",
    type: TEXT,
  },
  {
    label: "Primary Number Of Accounts",
    key: "primaryNumberOfAccounts",
    type: TEXT,
  },
  {
    label: "Primary Active Number Of Accounts",
    key: "primaryNumberOfAccounts",
    type: TEXT,
  },
  {
    label: "Primary Overdue Number Of Accounts",
    key: "primaryOverdueNumberOfAccounts",
    type: TEXT,
  },
]