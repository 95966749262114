import { HEALTH_CARE_LENDER_LIST_REQUEST } from './nucleon-profile-constants';

const healthCareLenderListRequest = (data) => ({
  data,
  type: HEALTH_CARE_LENDER_LIST_REQUEST,
});

// eslint-disable-next-line import/prefer-default-export
export const healthCareLenderList = (dispatch, healthCareLenderListCallback) => {
  dispatch(healthCareLenderListRequest({ healthCareLenderListCallback }));
};